/**
 * @module Sagas/User
 * @desc User
 */

import { all, put, call, takeLatest, select } from 'redux-saga/effects'

import { ActionTypes, APIEndpoints } from 'redux/constants/index'
import { request } from 'services/client'
import { setCookie, getCookie, deleteCookie } from 'services/cookie'
/**
 * Signup
 */
export function * signup (action) {
  try {
    const { requestObj } = action.payload
    const response = yield call(request, APIEndpoints.SIGNUP, {
      method: 'POST',
      payload: { ...requestObj }
    })
    const minutes = parseInt(response.refresh_token_validity)
    const expiry = new Date().getTime() + minutes * 60 * 1000 - 1 * 60 * 1000
    yield setCookie('user_id', response.id, { minutes })
    yield setCookie('shop_id', response.shop ? response.shop.id : undefined, { minutes })
    yield setCookie('shop', JSON.stringify(response), { minutes })
    yield setCookie('auth_token', response.access_token, { minutes })
    yield setCookie('refresh_token', response.refresh_token, { minutes })
    yield setCookie('refresh_token_expiry', expiry, { minutes })
    yield setCookie('access_token_expiry', new Date().getTime() + response.access_token_validity * 60 * 1000 - 1 * 60 * 1000, { minutes })
    yield put({
      type: ActionTypes.USER_SIGNUP_SUCCESS,
      payload: response
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.USER_SIGNUP_FAILURE,
      payload: err
    })
  }
}

/**
 * Login
 */
export function * login (action) {
  try {
    const { email, password } = action.payload
    const authParams = yield select(state => state.user.authParams)
    const response = yield call(request, APIEndpoints.LOGIN, {
      method: 'POST',
      payload: { email, password, ...authParams }
    })
    const minutes = parseInt(response.refresh_token_validity)
    const expiry = new Date().getTime() + minutes * 60 * 1000 - 1 * 60 * 1000
    yield setCookie('user_id', response.id, { minutes })
    yield setCookie('shop_id', response.shop ? response.shop.id : undefined, { minutes })
    yield setCookie('shop', JSON.stringify(response), { minutes })
    yield setCookie('auth_token', response.access_token, { minutes })
    yield setCookie('refresh_token', response.refresh_token, { minutes })
    yield setCookie('refresh_token_expiry', expiry, { minutes })
    // yield setCookie('access_token_expiry', new Date().getTime() + 3 * 60 * 1000 - 1 * 60 * 1000, { minutes })
    yield setCookie('access_token_expiry', new Date().getTime() + response.access_token_validity * 60 * 1000 - 1 * 60 * 1000, { minutes })
    yield put({
      type: ActionTypes.USER_LOGIN_SUCCESS,
      payload: response
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.USER_LOGIN_FAILURE,
      payload: err.error
    })
  }
}

export function * forgot (action) {
  try {
    const { email } = action.payload
    const response = yield call(request, APIEndpoints.FORGOT_PASSWORD, {
      method: 'POST',
      payload: { email }
    })
    yield put({
      type: ActionTypes.FORGOT_PASSWORD_SUCCESS,
      payload: response
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.FORGOT_PASSWORD_FAILURE,
      payload: err.error
    })
  }
}

export function * resetPassword (action) {
  try {
    const { email, otp, password } = action.payload
    const response = yield call(request, APIEndpoints.RESET_PASSWORD, {
      method: 'POST',
      payload: {
        new_password: password,
        otp,
        email
      }
    })
    yield put({
      type: ActionTypes.RESET_PASSWORD_SUCCESS,
      payload: response
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.RESET_PASSWORD_FAILURE,
      payload: err.error
    })
  }
}

/**
 * Logout
 */
export function * logout () {
  try {
    const refreshToken = getCookie('refresh_token')
    if (refreshToken) {
      yield call(request, APIEndpoints.LOGOUT, {
        method: 'POST',
        payload: {
          refresh_token: refreshToken
        }
      })
    }
    yield deleteCookie('user_id')
    yield deleteCookie('shop_id')
    yield deleteCookie('shop')
    yield deleteCookie('auth_token')
    yield deleteCookie('refresh_token')
    yield deleteCookie('refresh_token_expiry')
    yield deleteCookie('access_token_expiry')
    yield put({
      type: ActionTypes.USER_LOGOUT_SUCCESS
    })
  } catch (err) {
    /* istanbul ignore next */
    yield deleteCookie('user_id')
    yield deleteCookie('shop_id')
    yield deleteCookie('shop')
    yield deleteCookie('auth_token')
    yield deleteCookie('refresh_token')
    yield deleteCookie('refresh_token_expiry')
    yield deleteCookie('access_token_expiry')
    yield put({
      type: ActionTypes.USER_LOGOUT_FAILURE,
      payload: err
    })
  }
}

/**
 * refresh token
 */
export function * refreshToken () {
  try {
    const refreshTokenExpirty = parseInt(getCookie('refresh_token_expiry') || new Date().getTime() - 1)
    const accessTokenExpirty = parseInt(getCookie('access_token_expiry') || new Date().getTime() - 1)
    const now = new Date().getTime()
    const refreshToken = getCookie('refresh_token')
    if (!refreshToken) {
      // stop loading and state false
      yield put({
        type: ActionTypes.REFRESH_TOKEN_SUCCESS
      })
    } else if (accessTokenExpirty > now) {
      // set user as logged in
      const shopDetails = getCookie('shop')
      yield put({
        type: ActionTypes.USER_LOGIN_SUCCESS,
        payload: JSON.parse(shopDetails)
      })
      yield put({
        type: ActionTypes.REFRESH_TOKEN_SUCCESS
      })
    } else if (accessTokenExpirty < now && refreshTokenExpirty > now) {
      const response = yield call(request, APIEndpoints.REFRESH_TOKEN, {
        method: 'POST',
        payload: {
          refresh_token: refreshToken,
          user_id: getCookie('user_id'),
          headers: { 'Authorization': `Bearer ${refreshToken}` }
        }
      })
      // const minutes = 5
      const minutes = parseInt(response.refresh_token_validity)
      const expiry = now + minutes * 60 * 1000 - 1 * 60 * 1000
      yield setCookie('auth_token', response.access_token, { minutes })
      yield setCookie('user_id', getCookie('user_id'), { minutes })
      yield setCookie('shop_id', getCookie('shop_id'), { minutes })
      yield setCookie('shop', getCookie('shop'), { minutes })
      yield setCookie('refresh_token', response.refresh_token, { minutes })
      yield setCookie('refresh_token_expiry', expiry, { minutes })
      // yield setCookie('access_token_expiry', now + 3 * 60 * 1000 - 1 * 60 * 1000, { minutes })
      yield setCookie('access_token_expiry', now + response.access_token_validity * 60 * 1000 - 1 * 60 * 1000, { minutes })
      const shopDetails = getCookie('shop')
      yield put({
        type: ActionTypes.USER_LOGIN_SUCCESS,
        payload: JSON.parse(shopDetails)
      })
      yield put({
        type: ActionTypes.REFRESH_TOKEN_SUCCESS
      })
    } else {
      yield deleteCookie('user_id')
      yield deleteCookie('shop_id')
      yield deleteCookie('shop')
      yield deleteCookie('auth_token')
      yield deleteCookie('refresh_token')
      yield deleteCookie('refresh_token_expiry')
      yield deleteCookie('access_token_expiry')
      yield put({
        type: ActionTypes.REFRESH_TOKEN_ERROR
      })
    }
  } catch (err) {
    /* istanbul ignore next */
    yield deleteCookie('user_id')
    yield deleteCookie('shop_id')
    yield deleteCookie('shop')
    yield deleteCookie('auth_token')
    yield deleteCookie('refresh_token')
    yield deleteCookie('refresh_token_expiry')
    yield deleteCookie('access_token_expiry')
    yield put({
      type: ActionTypes.USER_LOGOUT_SUCCESS
    })
  }
}

/**
 * User Sagas
 */
export default function * root () {
  yield all([
    takeLatest(ActionTypes.USER_LOGIN, login),
    takeLatest(ActionTypes.USER_LOGOUT, logout),
    takeLatest(ActionTypes.REFRESH_TOKEN, refreshToken),
    takeLatest(ActionTypes.USER_SIGNUP, signup),
    takeLatest(ActionTypes.FORGOT_PASSWORD, forgot),
    takeLatest(ActionTypes.RESET_PASSWORD, resetPassword)
  ])
}
