/**
 * @module Sagas/User
 * @desc User
 */

import { all, put, call, takeLatest } from 'redux-saga/effects'

import { ActionTypes, APIEndpoints } from 'redux/constants/index'
import { request } from 'services/client'
import { getCookie } from 'services/cookie'

/**
 * Signup
 */
export function * getDeliveryPartner (action) {
  try {
    const shopId = getCookie('shop_id')
    const response = yield call(request, APIEndpoints.GET_DELIVERY_PARTNERS(shopId), {
      method: 'GET'
    })
    yield put({
      type: ActionTypes.GET_DELIVERY_PARTNERS_SUCCESS,
      payload: response.volunteer
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_DELIVERY_PARTNERS_FAILURE,
      payload: err
    })
  }
}

export function * creatDeliveryPartner ({ payload }) {
  try {
    const shopId = getCookie('shop_id')
    const response = yield call(request, APIEndpoints.CREATE_DELIVERY_PARTNER(shopId), {
      method: 'POST',
      payload: { phone_with_code: `+91${payload.phone}` }
    })
    if (response.status) {
      yield put({
        type: ActionTypes.CREATE_DELIVERY_PARTNER_SUCCESS,
        payload: response
      })
    } else {
      yield put({
        type: ActionTypes.CREATE_DELIVERY_PARTNER_FAILURE,
        payload: response
      })
    }
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.CREATE_DELIVERY_PARTNER_FAILURE,
      payload: err
    })
  }
}

export function * deleteDeliveryPartner ({ payload }) {
  try {
    const shopId = getCookie('shop_id')
    const response = yield call(request, APIEndpoints.DELETE_DELIVERY_PARTNER(shopId), {
      method: 'DELETE',
      payload: { selected_user_id: payload.id }
    })
    yield put({
      type: ActionTypes.DELETE_DELIVERY_PARTNER_SUCCESS,
      payload: response
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.DELETE_DELIVERY_PARTNER_FAILURE,
      payload: err
    })
  }
}

/**
 * User Sagas
 */
export default function * root () {
  yield all([
    takeLatest(ActionTypes.GET_DELIVERY_PARTNERS, getDeliveryPartner),
    takeLatest(ActionTypes.CREATE_DELIVERY_PARTNER, creatDeliveryPartner),
    takeLatest(ActionTypes.DELETE_DELIVERY_PARTNER, deleteDeliveryPartner)

  ])
}
