import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import treeChanges from 'tree-changes'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import _ from 'lodash'

import {
  CircularProgress,
  Grid,
  Typography,
  withStyles,
  Button
} from '@material-ui/core'

import { STATUS } from 'redux/constants/index'
import { Dashboard as DashboardLayout } from 'layouts'
import { productGetList, showAlert, changeStockStatus } from 'redux/actions/index'
import { ProductCard } from './components'
import styles from './style'
import EditProduct from './components/EditProduct'
import CreateProduct from './components/CreateProduct'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { SearchInput } from 'components'
import Select from 'components/Select'

const itemsPerPage = 30
const statusList = [{
  id: 'all',
  value: 'All'
}, {
  id: 'to_reorder',
  value: 'Below Reorder'
}, {
  id: 'not_stock',
  value: 'Out of Stock'
}, {
  id: 'in_stock',
  value: 'In Stock'
}
]

class ProductList extends Component {
  signal = true;

  state = {
    isLoading: false,
    error: null,
    showCreate: false,
    showCreateFirst: false,
    page: 0,
    status: 'all'
  };

  componentDidMount () {
    this.signal = true
    this.setState({ isLoading: true })
    this.props.dispatch(productGetList(0, itemsPerPage))
  }

  componentWillReceiveProps = newProps => {
    const { changedTo } = treeChanges(this.props, newProps)

    /* istanbul ignore else */
    if (changedTo('products.status', STATUS.READY)) {
      this.setState({ isLoading: false })
    } else if (changedTo('products.status', STATUS.ERROR)) {
      this.props.dispatch(showAlert('Product list fetch failed. Something went wrong!', { variant: 'error' }))
      this.setState({ isLoading: false })
    }
    if (changedTo('delete.status', STATUS.READY)) {
      this.props.dispatch(showAlert('Deleted Product Successfully', { variant: 'success' }))
    } else if (changedTo('delete.status', STATUS.ERROR)) {
      this.props.dispatch(showAlert('Failed to delete product. Something went wrong!', { variant: 'error' }))
    }
  }

  componentWillUnmount () {
    this.signal = false
  }

  onCreateProduct = details => {
    this.setState({ showCreate: false })
  }

  showCreateProduct = () => {
    this.setState({ showCreateFirst: true })
  }

  onCancelCreate = () => {
    this.setState({ showCreate: false })
  }

  onSelectProduct = (data) => {
    let selectedProduct = _.cloneDeep(data)
    if (data && data.id) {
      selectedProduct.prodId = selectedProduct.id
      delete selectedProduct.id
    } else {
      selectedProduct = { name: data }
    }
    this.setState({ showCreateFirst: false, showCreate: true, selectedProduct })
  }

  onCancelCreateFirst = () => {
    this.setState({ showCreateFirst: false })
  }

  onChangeSearchString = event => {
    const { value } = event.target
    this.setState({ searchString: value })
  }

  onClickSearch = () => {
    const { searchString, status } = this.state
    this.setState({ page: 0, isLoading: true })
    const filter = status !== 'all' ? status : null
    this.props.dispatch(productGetList(0, itemsPerPage, searchString, filter))
  }

  onStatusChange = request => {
    this.props.dispatch(changeStockStatus(request))
  }

  refreshPage = () => {
    const { isLoading, page, searchString, status } = this.state
    const filter = status !== 'all' ? status : null
    const nextIndex = (page + 1) * itemsPerPage
    if (!isLoading) {
      this.setState({ isLoading: true }, () => {
        this.props.dispatch(productGetList(0, nextIndex, searchString, filter))
      })
    }
  }

  fetchNextPage = () => {
    const { products } = this.props
    const { isLoading, page, searchString, status } = this.state
    const nextIndex = (page + 1) * itemsPerPage
    const filter = status !== 'all' ? status : null
    if (!isLoading && !products.reachedEnd) {
      this.setState({ page: page + 1, isLoading: true }, () => {
        this.props.dispatch(productGetList(nextIndex, itemsPerPage, searchString, filter))
      })
    }
  }

  handleFieldChange = (field, value) => {
    this.setState({ [field]: value }, this.onClickSearch)
  }

  keyPressed = event => {
    if (event.key === 'Enter') {
      this.onClickSearch()
    }
  }

  renderProducts () {
    const { classes, products, statusChange } = this.props
    if (products.status === STATUS.RUNNING && products.data.length === 0) {
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress />
        </div>
      )
    }

    if (products.data.length === 0) {
      return (
        <Typography variant='h6'>There are no products. Add products by using the Add Button(Top Right)</Typography>
      )
    }
    return (
      <div className={classes.container} >
        { products.status === STATUS.RUNNING ? <div className={classes.progressWrapper}>
          <CircularProgress />
        </div> : null }
        <PerfectScrollbar onYReachEnd={this.fetchNextPage}>
          <Grid
            container
            className={classes.gridContainer}
            spacing={3}
          >

            {products.data.map(product => (
              <Grid
                item
                key={product.id}
                lg={4}
                md={6}
                xs={12}
              >
                <Link to='#'>
                  <ProductCard product={_.cloneDeep(product)} onStatusChange={this.onStatusChange} isLoading={statusChange.data.itemId === product.id && statusChange.status === STATUS.RUNNING} />
                </Link>
              </Grid>
            ))}
          </Grid>
        </PerfectScrollbar>
      </div>

    )
  }

  render () {
    const { classes } = this.props
    const { searchString, showCreateFirst, selectedProduct, showCreate, status } = this.state
    return (
      <DashboardLayout title='Products'>
        <div className={classes.root}>
          {/* <SearchToolbar
            showCreate
            showCreateProduct={this.showCreateProduct}
            searchString={searchString}
            onChangeSearchString={this.onChangeSearchString}
            onClickSearch={this.onClickSearch}
            onClickRefreshButton={this.refreshPage}
          /> */}
          <div className={classes.filterContainer} >
            <SearchInput
              className={classes.searchInput}
              placeholder='Search Order'
              value={searchString}
              onKeyPress={this.keyPressed}
              onChange={this.onChangeSearchString}
            />
            <Select placeholder='Status' options={statusList} valueParam='value' keyParam='id' selectedValue={status} handleChange={(event) => this.handleFieldChange('status', event.target.value)} />
            <Button className={classes.searchButton} color='primary' size='small' variant='outlined' onClick={this.onClickSearch} >
              Search
            </Button>
            <span className={classes.spacer} />
            <Button color='primary' className={classes.firstButton} size='small' variant='outlined' onClick={this.refreshPage} >
              Refresh
            </Button>
            <Button color='primary' size='small' variant='outlined' onClick={this.showCreateProduct} >
              Add
            </Button>
          </div>
          <div className={classes.content}>{this.renderProducts()}</div>
          <CreateProduct isOpen={showCreateFirst} onCancel={this.onCancelCreateFirst} onSubmit={this.onSelectProduct} />
          <EditProduct default={selectedProduct} isOpen={showCreate} onCancel={this.onCancelCreate} onSubmit={this.onCreateProduct} />
        </div>
      </DashboardLayout>
    )
  }
}

ProductList.propTypes = {
  classes: PropTypes.object.isRequired
}

function mapStateToProps (state) {
  return {
    products: state.products.list,
    statusChange: state.products.statusChange,
    delete: state.products.delete
  }
}

export default compose(
  withStyles(styles)
)(connect(mapStateToProps)(ProductList))
