/**
 * @module Sagas/App
 * @desc App
 */
import { all, put, call, takeLatest } from 'redux-saga/effects'

import { ActionTypes, APIEndpoints } from 'redux/constants/index'
import { request } from 'services/client'
import { getCookie } from 'services/cookie'
/**
 * Switch Menu
 *
 * @param {Object} action
 *
 */

export function * getAllCategories ({ payload }) {
  try {
    const shopId = getCookie('shop_id')
    const response = yield call(request, APIEndpoints.GET_ALL_CATEGORIES(shopId), {
      method: 'GET'
    })
    yield put({
      type: ActionTypes.GET_ALL_CATEGORIES_SUCCESS,
      payload: response.item_categories
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_ALL_CATEGORIES_FAILURE,
      payload: err.message
    })
  }
}

export function * listCategories ({ payload }) {
  const { level, parent } = payload
  const successType = level === 1 ? ActionTypes.LEVEL1_LIST_CATEGORIES_SUCCESS : level === 2 ? ActionTypes.LEVEL2_LIST_CATEGORIES_SUCCESS : ActionTypes.LEVEL3_LIST_CATEGORIES_SUCCESS
  const failureType = level === 1 ? ActionTypes.LEVEL1_LIST_CATEGORIES_FAILURE : level === 2 ? ActionTypes.LEVEL2_LIST_CATEGORIES_FAILURE : ActionTypes.LEVEL3_LIST_CATEGORIES_FAILURE
  try {
    const shopId = getCookie('shop_id')
    const response = yield call(request, APIEndpoints.CATEGORY_LIST(shopId, level, parent), {
      method: 'GET'
    })
    yield put({
      type: successType,
      payload: response.item_categories
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: failureType,
      payload: err.message
    })
  }
}

export function * createCategory ({ payload }) {
  try {
    const { apiRequest } = payload
    const shopId = getCookie('shop_id')
    const response = yield call(request, APIEndpoints.CREATE_CATEGORY(shopId), {
      method: 'POST',
      payload: apiRequest
    })
    yield put({
      type: ActionTypes.CREATE_CATEGORY_SUCCESS,
      payload: response.item_category
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.CREATE_CATEGORY_FAILURE,
      payload: err.message
    })
  }
}

export function * editCategory ({ payload }) {
  try {
    const { apiRequest, id } = payload
    const shopId = getCookie('shop_id')
    const response = yield call(request, APIEndpoints.EDIT_CATEGORY(shopId, id), {
      method: 'POST',
      payload: apiRequest
    })
    yield put({
      type: ActionTypes.EDIT_CATEGORY_SUCCESS,
      payload: response.item_category
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.EDIT_CATEGORY_FAILURE,
      payload: err.message
    })
  }
}

export function * deleteCategory ({ payload }) {
  try {
    const { id } = payload
    const shopId = getCookie('shop_id')
    const response = yield call(request, APIEndpoints.DELETE_CATEGORY(shopId, id), {
      method: 'DELETE',
      payload: {}
    })
    yield put({
      type: ActionTypes.DELETE_CATEGORY_SUCCESS,
      payload: response.item_category
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.DELETE_CATEGORY_FAILURE,
      payload: err.message
    })
  }
}

/**
 * App Sagas
 */
export default function * root () {
  yield all([
    takeLatest(ActionTypes.LEVEL1_LIST_CATEGORIES, listCategories),
    takeLatest(ActionTypes.LEVEL2_LIST_CATEGORIES, listCategories),
    takeLatest(ActionTypes.LEVEL3_LIST_CATEGORIES, listCategories),
    takeLatest(ActionTypes.CREATE_CATEGORY, createCategory),
    takeLatest(ActionTypes.EDIT_CATEGORY, editCategory),
    takeLatest(ActionTypes.DELETE_CATEGORY, deleteCategory),
    takeLatest(ActionTypes.GET_ALL_CATEGORIES, getAllCategories)
  ])
}
