import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles, Typography } from '@material-ui/core'
import Modal from 'views/Modal'

const styles = theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 100
  },
  textField: {
    margin: 0,
    width: 'calc( 100% - 80px)',
    marginLeft: 20
  },
  error: {
    color: 'red'
  },
  code: {
    width: 60,
    margin: 0
  }
})

class ReservationConfirmation extends Component {
  render () {
    const { classes, onCancel, isOpen, type, onSubmit } = this.props
    const title = type === 'checkout' ? 'Checkout Reservation' : 'Checkin Reservation'
    const message = type === 'checkout' ? 'Do you want to checkout the user?' : 'Do you want check in the user?'
    const child = <div className={classes.container}>
      <Typography className={classes.warning} variant='p'>{message}</Typography>
    </div>
    return (
      <Modal isOpen={isOpen} title={title} onCancel={onCancel} okText={'Confirm'} onSubmit={onSubmit} child={child} />
    )
  }
}

ReservationConfirmation.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ReservationConfirmation)
