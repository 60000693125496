export default theme => ({
  root: {
    padding: '15px 20px',
    borderColor: '#0767DB'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'space-between',
    justifyContent: 'space-between',
    height: 112
  },

  acceptStatus: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  statusTitle: {
    lineHeight: '38px'
  }
})
