import React, { Component } from 'react'
import PropTypes from 'prop-types'
import compose from 'recompose/compose'
import treeChanges from 'tree-changes'
import { connect } from 'react-redux'
import { withStyles, TextField } from '@material-ui/core'
import { STATUS } from 'redux/constants'
import Modal from 'views/Modal'
import { showAlert, editCategory, createCategory } from 'redux/actions/index'

// Component styles
const styles = theme => ({
  wrapper: {

  },
  textField: {
    width: '100%',
    '&:nth-child(2)': {
      marginTop: 20
    }
  }
})

class AddCategory extends Component {
  constructor (props) {
    super(props)
    this.state = {
      name: '',
      rank: 5
    }
  }

    componentWillReceiveProps = newProps => {
      const { changed, changedTo } = treeChanges(this.props, newProps)
      if (changed('categoryId')) {
        this.setState({ name: newProps.name, rank: newProps.rank })
      }
      if (changedTo('category.create.status', STATUS.READY)) {
        this.setState({ name: '' })
        this.props.onSubmit()
      } else if (changedTo('category.edit.status', STATUS.READY)) {
        this.setState({ name: '' })
        this.props.onSubmit()
      } else if (changedTo('category.create.status', STATUS.ERROR)) {
        this.setState({ name: '' })
        this.props.onSubmit()
        this.props.dispatch(showAlert(newProps.category.create.message, { variant: 'error' }))
      } else if (changedTo('category.edit.status', STATUS.ERROR)) {
        this.setState({ name: '' })
        this.props.onSubmit()
        this.props.dispatch(showAlert(newProps.category.edit.message, { variant: 'error' }))
      }
    }

    handleChange = (event) => {
      const { value } = event.target
      this.setState({ name: value })
    }

    handleChangeRank = (event) => {
      const { value } = event.target
      this.setState({ rank: value })
    }

    onSubmit = () => {
      const { categoryId } = this.props
      if (categoryId) {
        this.onEditCategory()
      } else {
        this.onCreateNewCategory()
      }
    }

    onEditCategory = () => {
      const { categoryId } = this.props
      const { name, rank } = this.state
      if (!name) {
        this.props.dispatch(showAlert('Name field should not be empty', { variant: 'error' }))
        return
      }
      const request = {
        item_category: {
          name,
          rank: rank ? parseInt(rank) : 0,
          image: '',
          icon: ''
        }
      }
      this.props.dispatch(editCategory(request, categoryId))
    }

    onCreateNewCategory = () => {
      const { parent, level } = this.props
      const { name, rank } = this.state
      if (!name) {
        this.props.dispatch(showAlert('Name field should not be empty', { variant: 'error' }))
        return
      }
      const request = {
        item_category: {
          name,
          rank: rank ? parseInt(rank) : 0,
          parent_id: parent ? parent.id : null,
          level: level
        }
      }
      this.props.dispatch(createCategory(request))
    }

    render () {
      const { classes, onCancel, isOpen, parent, categoryId } = this.props
      const { name, rank } = this.state
      const child = <div className={classes.container}>
        <TextField
          className={classes.textField}
          label='Category Name'
          margin='dense'
          required
          onChange={this.handleChange}
          value={name}
          variant='outlined'
        />
        <TextField
          className={classes.textField}
          label='Rank'
          margin='dense'
          type='number'
          required
          onChange={this.handleChangeRank}
          value={rank}
          variant='outlined'
        />
        {parent ? <TextField
          className={classes.textField}
          label='Parent'
          margin='dense'
          value={parent.name}
          disabled
          variant='outlined'
        /> : null}
      </div>

      return (
        <Modal isOpen={isOpen} title={categoryId ? 'EDIT CATEGORY' : 'ADD NEW CATEGORY'} onCancel={onCancel} okText={'Save'} onSubmit={this.onSubmit} child={child} width={600} />
      )
    }
}

AddCategory.propTypes = {
  classes: PropTypes.object.isRequired
}

function mapStateToProps (state) {
  return {
    category: state.category
  }
}

export default compose(
  withStyles(styles)
)(connect(mapStateToProps)(AddCategory))
