import React, { Component } from 'react'
import PropTypes from 'prop-types'
import compose from 'recompose/compose'
import treeChanges from 'tree-changes'
import { connect } from 'react-redux'
import { withStyles, Typography, Button, CircularProgress } from '@material-ui/core'
import { AddCategory, CategoryListCard, DeleteCategoryConfirmation } from '..'
import { listFirstLevelCategories, listSecondLevelCategories, listThirdLevelCategories, clearLevelData, deleteCategory, showAlert } from 'redux/actions/index'
import { STATUS } from 'redux/constants'

// Component styles
const styles = theme => ({
  wrapper: {
    height: 'calc( 100vh - 104px )',
    minWidth: 356,
    width: 'calc( 100% - 120px )',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: theme.shadows[2],
    borderRadius: 4,
    marginRight: 30,
    background: '#fff'
  },
  header: {
    height: 60,
    padding: 20,
    boxShadow: '1px 2px 8px -5px #888888',
    position: 'relative'
  },
  heading: {
    width: 'calc( 100% - 80px )'
  },
  button: {
    position: 'absolute',
    top: 15,
    right: 20
  },
  list: {
    height: 'calc( 100% - 60px )',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto'
  },
  none: {
    marginTop: 20,
    width: '100%',
    textAlign: 'center'
  },
  loading: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: 40
  }
})

class CategoryList extends Component {
  state = {
    showAddNew: false
  }

  componentDidMount = () => {
    if (this.props.level === 1) {
      this.props.dispatch(listFirstLevelCategories(1))
    }
  }

  componentWillReceiveProps = newProps => {
    const { level, parent } = newProps
    const { changed } = treeChanges(this.props, newProps)

    /* istanbul ignore else */
    if (changed('parent.id')) {
      this.setState({ isLoading: false })
      if (parent && parent.id) {
        if (level === 2) {
          this.props.dispatch(listSecondLevelCategories(level, parent.id))
        } else {
          this.props.dispatch(listThirdLevelCategories(level, parent.id))
        }
      } else {
        this.props.dispatch(clearLevelData(level))
      }
    }
    const data = `level${level}`
    const list = `category.${data}.data`
    if (changed(list) && level !== 3 && newProps.category[data].data && newProps.category[data].data.length) {
      this.props.setSelectedCategory(level, newProps.category[data].data[0])
    }
    if (changed('category.delete.status') && newProps.category.delete.status === STATUS.READY) {
      this.hideDeleteConfirmation()
      this.props.dispatch(showAlert('Deleted Category successfully', { variant: 'success' }))
    }
  }

  onEdit = (e, item) => {
    e.stopPropagation()
    this.setState({ showAddNew: true, selectedEdit: item })
  }

  onDelete = (e, item) => {
    e.stopPropagation()
    this.setState({ showDelete: true, deletingCategory: item })
  }

  hideDeleteConfirmation = () => {
    this.setState({ showDelete: false, deletingCategory: null })
  }

  deleteCategory = () => {
    this.props.dispatch(deleteCategory(this.state.deletingCategory.id, this.state.deletingCategory.level))
  }

  showAddNew = () => {
    this.setState({ showAddNew: true, selectedEdit: null })
  }

  onSubmit = () => {
    this.setState({ showAddNew: false, selectedEdit: null })
  }

  render () {
    const { classes, heading, category, level, selected, setSelectedCategory, parent } = this.props
    const { showAddNew, selectedEdit, showDelete } = this.state
    let paretnData; let parentList; let isButtonDisabled = false
    if (level - 1) {
      paretnData = `level${level - 1}`
      parentList = category[paretnData]
      isButtonDisabled = level - 1 && (!parentList || (!parentList.data.length && parentList.status === STATUS.READY))
    }
    const data = `level${level}`
    const list = category[data]
    const isEmpty = !list.data.length && (list.status === STATUS.READY || (parentList && !parentList.data.length && parentList.status === STATUS.READY))
    const isLoading = list.status === STATUS.RUNNING || (parentList && parentList.status === STATUS.RUNNING)

    return (
      <div className={classes.wrapper}>
        <div className={classes.header} >
          <Typography className={classes.heading} variant='h4' >{heading}</Typography>
          <Button className={classes.button} disabled={isButtonDisabled} color='primary' size='small' variant='outlined' onClick={this.showAddNew} > Add New </Button>
        </div>
        { isLoading ? <div className={classes.loading}> <CircularProgress /> </div> : null }
        { !isLoading ? <div className={classes.list} >
          { list.data.map(item => <CategoryListCard key={item.id} isSelected={selected === item.id} item={item} onEdit={(e) => this.onEdit(e, item)} onClick={() => setSelectedCategory(level, item)} onDelete={(e) => this.onDelete(e, item)} />) }
          { isEmpty ? <Typography className={classes.none} variant='h6' >No Categories found in this level</Typography> : null}
        </div> : null }
        <AddCategory isOpen={showAddNew} level={level} onCancel={this.onSubmit} onSubmit={this.onSubmit} parent={parent} name={selectedEdit ? selectedEdit.name : null} rank={selectedEdit ? selectedEdit.rank : null} categoryId={selectedEdit ? selectedEdit.id : null} />
        <DeleteCategoryConfirmation isOpen={showDelete} onCancel={this.hideDeleteConfirmation} onSubmit={this.deleteCategory} />
      </div>
    )
  }
}

CategoryList.propTypes = {
  classes: PropTypes.object.isRequired
}

function mapStateToProps (state) {
  return {
    category: state.category
  }
}

export default compose(
  withStyles(styles)
)(connect(mapStateToProps)(CategoryList))
