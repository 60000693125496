import React, { Component } from 'react'

// Externals
import PropTypes from 'prop-types'

// Material helpers
import { withStyles } from '@material-ui/core'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'

// Component styles
import styles from './style'
import { getResizedImage } from 'services/utility'

class CommonProductCard extends Component {
  render () {
    const { classes, product, onSelectProduct, isSelected } = this.props
    console.log('isSelected', isSelected)
    return (
      <Card>
        <CardActionArea className={`${classes.area} ${isSelected ? classes.selected : ''}`} onClick={() => onSelectProduct(product.id)}>
          <CardMedia
            className={classes.image}
            component='img'
            alt={product.name}
            height='120'
            image={product.image_url ? getResizedImage(product.image_url, 200, 200) : ''}
            title={product.name}
            onError={(e) => { console.log(e.target.src = 'https://res.cloudinary.com/hgjbwh1ki/image/upload/c_pad,h_256,q_75,w_256/v1585995757/assets/default-image1_xo2ksz.png') }}
          />
          <CardContent className={classes.details}>
            <Typography className={classes.name} gutterBottom variant='h5' component='h2'>
              {product.name}
            </Typography>
            <Typography className={classes.manu} variant='body2' color='textSecondary' component='p'>
              {product.manufacturer}
            </Typography>
            {product.price
              ? <Typography className={classes.price} variant='body2' color='textSecondary' component='p'>
              &#8377;{product.price}
                { product.actual_price ? <Typography className={classes.oldPrice} variant='body2' color='textSecondary' component='strike'>&#8377;{product.actual_price}</Typography> : null }
              </Typography>
              : null }
          </CardContent>
        </CardActionArea>
      </Card>
    )
  }
}

CommonProductCard.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired
}

export default withStyles(styles)(CommonProductCard)
