import React, { Component } from 'react'
import { Grid, Typography, withStyles, Button } from '@material-ui/core'
import { PriceCard, FaqCard } from './components/index'
import style from './style'

class Pricing extends Component {
  render () {
    const { classes } = this.props
    const basicPlanFeatures = ['Direct payment collection using UPI', 'Option to add unto 500 products', 'Live delivery agent tracking', 'Rate and review orders', 'Inventory management', 'Report generation', 'Time slot booking', 'Up to 4 promotional notifications to customers every month']
    const enterpriseFeatures = ['All the features of basic plan', 'Direct payment collection using payment gateway *', 'Option to add multiple delivery agents', 'Customer support', 'Free promotional materials', 'Up to 2 emails to customers every 2 weeks for promotion', 'Up to 3 notifications to customers every 2 weeks for promotion', 'Customer insights']
    const premiumFeatures = ['All the features of basic plan', '₹500 for each additional 2000 orders', 'Premium customer support', 'Sales insights', 'Custom feature development']
    return (
      <div>
        <Grid container className={classes.container}>
          <Grid xs='12' justify='center'>
            <Typography variant='h2' className={classes.heading}>Pricing</Typography>
            <img src='/images/rectangle-icon.svg' alt='' className={classes.rectangleIcon} />
            <Typography variant='subtitle1' className={classes.description}>Lets find the right plan for you</Typography>
          </Grid>
          <Grid container justify='space-around' className={classes.priceCardContainer}>
            <Grid gutturBottom>
              <PriceCard
                planType='Getting Started'
                planTitle='Basic'
                planPrice='₹200'
                planDescription='for up-to 500 orders / month'
                features={basicPlanFeatures}
              />
            </Grid>
            <Grid gutturBottom>
              <PriceCard
                planType='Recommended'
                planTitle='Enterprise'
                planPrice='₹500'
                planDescription='for up-to 2000 orders / month'
                features={enterpriseFeatures}
              />
            </Grid>
            <Grid gutturBottom>
              <PriceCard
                planType='Recommended'
                planTitle='Premium'
                planPrice='₹5000'
                planDescription='for up-to 15000 orders / month'
                features={premiumFeatures}
              />
            </Grid>
          </Grid>
          <Grid xs='12' justify='center'>
            <Typography variant='h2' className={classes.customisationHeading}>Customised Application</Typography>
            <img src='/images/rectangle-icon-green.svg' alt='' className={classes.rectangleIcon} />
            <Typography variant='subtitle1' className={classes.customisationDescription}>Merchants are free to use the direct links to their shops. Merchants can also redirect custom</Typography>
          </Grid>
          <Grid>
            <Typography className={classes.customisationPrice}>
              <ui>
                <li>Android App : 25,000</li>
                <li>iOS App : 25,000</li>
                <li>Web App : 25,000</li>
                <li>Android + Web App : 35,000</li>
                <li>Android + iOS + Web App : 50,000</li>
              </ui>
            </Typography>
          </Grid>
          <Grid container justify='center' className={classes.customerSupport}>
            <Grid className={classes.customerCareIcon} justify='center'>
              <img src='/images/customer-care-icon.svg' alt='customer-care' width='35px' />
            </Grid>
            <Grid xs={12} justify='center' >
              <Typography className={classes.customerSupportHeading}>TALK WITH OUR EXPERTS</Typography>
            </Grid>
            <Grid xs={12} justify='center' >
              <Typography className={classes.customerSupportSubTitle}>Not sure what’s the best plan for you?</Typography>
            </Grid>
            <Grid justify='center'>
              <Button href='mailto:info@shopsapp.net' variant='outlined' size='large' className={classes.button}>Contact Us</Button>
            </Grid>
          </Grid>
          <Grid
            container
            direction='row'
            justify='center'
            alignItems='center'
            className={classes.faqContainer}
          >
            <div className={classes.faqCard}>
              <FaqCard />
            </div>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default withStyles(style)(Pricing)
