import { handleActions } from 'redux-actions'
import immutable from 'immutability-helper'
import { parseError } from 'services/client'
import _ from 'lodash'

import { STATUS, ActionTypes } from 'redux/constants/index'

export const userState = {
  isAuthenticated: false,
  status: STATUS.IDLE,
  logoutStatus: STATUS.IDLE,
  refreshStatus: STATUS.RUNNING,
  authParams: {},
  userInfo: {},
  isUserPhoneVerified: false,
  message: '',
  forgot: {
    data: {},
    status: STATUS.IDLE,
    message: ''
  },
  reset: {
    data: {},
    status: STATUS.IDLE,
    message: ''
  }
}

export default {
  user: handleActions(
    {
      [ActionTypes.USER_LOGIN]: (state, { payload }) =>
        immutable(state, {
          userInfo: { $set: payload },
          requestedUser: { $set: payload },
          status: { $set: STATUS.RUNNING }
        }),
      [ActionTypes.USER_LOGIN_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          isAuthenticated: { $set: !!payload.shop },
          userInfo: { $set: payload },
          status: { $set: STATUS.READY }
        }),
      [ActionTypes.USER_LOGIN_FAILURE]: (state, { payload }) =>
        immutable(state, {
          isAuthenticated: { $set: false },
          message: { $set: parseError(payload) },
          status: { $set: STATUS.ERROR }
        }),
      [ActionTypes.USER_SIGNUP]: (state, { payload }) =>
        immutable(state, {
          userInfo: { $set: payload },
          requestedUser: { $set: payload.requestObj },
          signupStatus: { $set: STATUS.RUNNING }
        }),
      [ActionTypes.USER_SIGNUP_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          userInfo: { $set: payload },
          signupStatus: { $set: STATUS.READY }
        }),

      [ActionTypes.SET_USER_INFO]: (state, { payload }) => {
        let { userInfo } = _.cloneDeep(state)
        userInfo.shop = payload.shop
        return immutable(state, {
          userInfo: { $set: userInfo }
        })
      },

      [ActionTypes.USER_SIGNUP_FAILURE]: (state, { payload }) =>
        immutable(state, {
          isAuthenticated: { $set: false },
          message: { $set: parseError(payload.message) },
          signupStatus: { $set: STATUS.ERROR }
        }),
      [ActionTypes.USER_LOGOUT]: state =>
        immutable(state, {
          logoutStatus: { $set: STATUS.RUNNING }
        }),
      [ActionTypes.USER_LOGOUT_SUCCESS]: state =>
        immutable(state, {
          isAuthenticated: { $set: false },
          userInfo: { $set: {} },
          status: { $set: STATUS.IDLE },
          logoutStatus: { $set: STATUS.READY },
          forgot: {
            status: { $set: STATUS.IDLE },
            data: { $set: {} }
          },
          reset: {
            status: { $set: STATUS.IDLE },
            data: { $set: {} }
          }
        }),
      [ActionTypes.USER_LOGOUT_FAILURE]: (state, { payload }) =>
        immutable(state, {
          isAuthenticated: { $set: false },
          userInfo: { $set: {} },
          status: { $set: STATUS.IDLE },
          logoutStatus: { $set: STATUS.ERROR }
        }),
      [ActionTypes.REFRESH_TOKEN]: state =>
        immutable(state, {
          refreshStatus: { $set: STATUS.RUNNING }
        }),
      [ActionTypes.REFRESH_TOKEN_SUCCESS]: state =>
        immutable(state, {
          refreshStatus: { $set: STATUS.READY }
        }),
      [ActionTypes.REFRESH_TOKEN_ERROR]: state =>
        immutable(state, {
          refreshStatus: { $set: STATUS.ERROR }
        }),

      [ActionTypes.SET_AUTHENTICATED]: state =>
        immutable(state, {
          isAuthenticated: { $set: true }
        }),

      [ActionTypes.SET_SHOP_LOGO]: (state, { payload }) => {
        const userInfo = _.cloneDeep(state.userInfo)
        userInfo.shop.image_url = payload
        return immutable(state, {
          userInfo: { $set: userInfo }
        })
      },

      [ActionTypes.CHANGE_ACCEPT_STATUS_SUCCESS]: (state, { payload }) => {
        let { userInfo } = _.cloneDeep(state)
        userInfo.shop.is_accepting_orders = payload.is_accepting_orders
        return immutable(state, {
          userInfo: { $set: userInfo }
        })
      },

      [ActionTypes.FORGOT_PASSWORD]: (state, { payload }) =>
        immutable(state, {
          forgot: {
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.FORGOT_PASSWORD_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          forgot: {
            data: { $set: payload },
            status: { $set: STATUS.READY }
          }
        }),
      [ActionTypes.FORGOT_PASSWORD_FAILURE]: (state, { payload }) =>
        immutable(state, {
          forgot: {
            status: { $set: STATUS.ERROR },
            message: { $set: payload }
          }
        }),

      [ActionTypes.CLEAR_FORGOT]: (state, { payload }) => {
        return immutable(state, {
          forgot: {
            status: { $set: STATUS.IDLE }
          },
          reset: {
            status: { $set: STATUS.IDLE },
            data: { $set: {} }
          }
        })
      },

      [ActionTypes.RESET_PASSWORD]: (state, { payload }) =>
        immutable(state, {
          reset: {
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.RESET_PASSWORD_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          reset: {
            data: { $set: payload },
            status: { $set: STATUS.READY }
          }
        }),
      [ActionTypes.RESET_PASSWORD_FAILURE]: (state, { payload }) =>
        immutable(state, {
          reset: {
            status: { $set: STATUS.ERROR },
            message: { $set: payload }
          }
        })

    },
    userState
  )
}
