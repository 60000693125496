// @flow
/**
 * @module Actions/User
 * @desc User Actions
 */
import { createActions } from 'redux-actions'

import { ActionTypes } from 'redux/constants/index'

export const { getGeoLocation, getShopCategories, getShopTypes, addShopDetails, addShopAddress } = createActions({
  [ActionTypes.GET_GEO_LOCATION]: location => ({ location }),
  [ActionTypes.GET_SHOP_CATEGORIES]: () => ({ }),
  [ActionTypes.GET_SHOP_TYPES]: () => ({ }),
  [ActionTypes.ADD_SHOP_DETAILS]: (requestObj) => ({ requestObj }),
  [ActionTypes.ADD_SHOP_ADDRESS]: (requestObj, isAdd) => ({ requestObj, isAdd })
})
