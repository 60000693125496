/**
 * @module Sagas/User
 * @desc User
 */

import { all, put, call, takeLatest } from 'redux-saga/effects'

import { ActionTypes, APIEndpoints } from 'redux/constants/index'
import { request } from 'services/client'
import { getCookie } from 'services/cookie'

/**
 * Signup
 */
export function * getAccountStatus (action) {
  try {
    const shopId = getCookie('shop_id')
    const response = yield call(request, APIEndpoints.GET_ACCOUNT_STATUS(shopId), {
      method: 'GET'
    })
    yield put({
      type: ActionTypes.GET_ACCOUNT_STATUS_SUCCESS,
      payload: response
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_ACCOUNT_STATUS_FAILURE,
      payload: err
    })
  }
}

/**
 * User Sagas
 */
export default function * root () {
  yield all([
    takeLatest(ActionTypes.GET_ACCOUNT_STATUS, getAccountStatus)
  ])
}
