import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles, TextField } from '@material-ui/core'
import Modal from 'views/Modal'
import treeChanges from 'tree-changes'

const styles = theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  fullWidth: {
    width: '100%',
    marginTop: 30,
    marginLeft: 0
  },
  error: {
    color: 'red'
  }
})

class AddPartner extends Component {
    state = {
      name: ''
    }

    componentWillReceiveProps = nextProps => {
      const { changed } = treeChanges(this.props, nextProps)
      if (changed('partner')) {
        if (nextProps.partner) {
          this.setState({ name: nextProps.partner.name || '' })
        } else {
          this.setState({ name: '' })
        }
      }
    }

    handleChange = (field, e) => {
      const { value } = e.target
      this.setState({ [field]: value })
    }

    onSubmit = () => {
      const { name } = this.state
      const { onSubmit, partner } = this.props
      this.setState({ name: '' })
      onSubmit(name, partner ? partner.id : undefined)
    }

    render () {
      const { classes, onCancel, isOpen, partner } = this.props
      const { name } = this.state
      const child = <React.Fragment>
        <div className={classes.container}>
          <TextField
            className={classes.fullWidth}
            label={'Station Name'}
            margin='dense'
            onChange={(e) => this.handleChange('name', e)}
            value={name}
            variant='outlined'
          />
        </div>
      </React.Fragment>
      return (
        <Modal isOpen={isOpen} title={partner ? 'Edit Station' : 'Add Station'} onCancel={onCancel} okText={'ADD'} onSubmit={this.onSubmit} child={child} />
      )
    }
}

AddPartner.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired
}

export default withStyles(styles)(AddPartner)
