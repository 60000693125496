import React, { Component } from 'react'
import { Card, CardContent, Grid, Typography, withStyles } from '@material-ui/core'

import style from './style'

class PriceCard extends Component {
  render () {
    const { classes } = this.props

    return (
      <div>
        <Card className={classes.priceCard}>
          <CardContent>
            <Typography className={classes.planType}>
              {this.props.planType}
            </Typography>
            <Grid container>
              <Grid xs='10'>
                <Typography className={classes.planTitle}>
                  {this.props.planTitle}
                </Typography>
              </Grid>
              <Grid xs='2'>
                <div className={classes.icon} />
              </Grid>
            </Grid>
            <Typography className={classes.planPrice}>
              {this.props.planPrice}
            </Typography>
            <Typography className={classes.planDescription} >
              {this.props.planDescription}
            </Typography>
            <Typography className={classes.planFeatures} >
              <ul>
                {this.props.features.map(feature => {
                  return (
                    <li>{feature}</li>
                  )
                })}
              </ul>
            </Typography>
          </CardContent>
        </Card>
      </div >
    )
  }
}

export default withStyles(style)(PriceCard)
