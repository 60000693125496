import React, { Component } from 'react'

import PropTypes from 'prop-types'
import { withStyles, Tabs, Tab, Grid } from '@material-ui/core'
import { Dashboard as DashboardLayout } from 'layouts'
import compose from 'recompose/compose'
import treeChanges from 'tree-changes'
import { connect } from 'react-redux'

import style from './style'
import { ActiveReservations, Reservations } from './components'
import { getReservationCount } from 'redux/actions'
import { STATUS } from 'redux/constants'

// Component styles

class Reservation extends Component {
    state = {
      stage: 0
    }

    componentDidMount = () => {
      this.props.dispatch(getReservationCount())
    }

    componentWillReceiveProps = newProps => {
      const { changedTo } = treeChanges(this.props, newProps)
      if (changedTo('count.status', STATUS.READY)) {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(this.getUserCount, 5 * 60 * 1000)
      }
    }

    componentWillUnmount = () => {
      clearTimeout(this.timeout)
    }

    handleChange = (event, stage) => {
      this.setState({ stage })
    }

    getUserCount = () => {
      this.props.dispatch(getReservationCount())
    }

    render () {
      const { classes, count } = this.props
      const { stage } = this.state

      return (
        <DashboardLayout title='Reservations'>
          <div className={classes.root}>
            <Grid
              container
              spacing={4}
              className={classes.container}
            >
              <Tabs
                value={stage}
                className={classes.tabs}
                onChange={this.handleChange}
                indicatorColor='primary'
                textColor='primary'
                aria-label='icon tabs example'
              >
                <Tab className={classes.tab} label='Reservations' aria-label='user' />
                <Tab className={classes.tab} label='Active Reservations' aria-label='shop' />
              </Tabs>
              <div className={classes.count}>
                {`${count.data} Inside`}
              </div>
              { stage === 0 ? <Reservations /> : null }
              { stage === 1 ? <ActiveReservations /> : null }
            </Grid>
          </div>
        </DashboardLayout>
      )
    }
}

Reservation.propTypes = {
  classes: PropTypes.object.isRequired
}

function mapStateToProps (state) {
  return {
    count: state.reservations.count
  }
}

export default compose(
  withStyles(style)
)(connect(mapStateToProps)(Reservation))
