import React, { Component } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

// Views
import PrivateRoute from './views/PrivateRoute'
import PublicRoute from './views/PublicRoute'

import Home from './views/Home/'
import Dashboard from './views/Dashboard'
import Products from './views/Products'
import Orders from './views/Orders'
import SignIn from './views/SignIn'
import SignUp from './views/SignUp'
import Categories from './views/Categories'
import Settings from './views/Settings'
import NotFound from './views/NotFound'
import DeliveryPartners from 'views/DeliveryPartners'
import Cadets from 'views/Cadets'
import Stations from 'views/Stations'
import Forgot from 'views/Forgot'
import Locations from 'views/Locations'
import RegisterMember from 'views/RegisterMember'
import Reservations from 'views/Reservations'
import ReservationDetails from 'views/ReservationDetails'
import TermsAndCondition from 'views/Support/TermsAndCondition'
import PrivacyAndPolicy from 'views/Support/PrivacyAndPolicy'
import Pricing from 'views/Pricing'

const states = [
  '/dashboard',
  '/products',
  '/orders',
  '/categories',
  '/police',
  '/settings',
  '/stations',
  '/order-locations',
  '/reservations',
  '/home'
]

class Routes extends Component {
  constructor (props) {
    super(props)
    const loadedRoute = window.location.pathname
    const index = states.indexOf(loadedRoute)
    this.state = {
      defaultPage: index > -1 ? `${loadedRoute}${window.location.search}` : '/dashboard'
    }
  }

  render () {
    const { user } = this.props
    const { defaultPage } = this.state
    return (
      <Switch>
        <Redirect
          exact
          from='/'
          to='/dashboard'
        />
        <PublicRoute
          isAuthenticated={user.isAuthenticated}
          to={defaultPage}
          path='/sign-in'
          exact
          component={SignIn}
        />
        <PublicRoute
          isAuthenticated={user.isAuthenticated}
          to={defaultPage}
          path='/forgot'
          exact
          component={Forgot}
        />
        <PublicRoute
          isAuthenticated={user.isAuthenticated}
          to={defaultPage}
          path='/sign-up'
          exact
          component={SignUp}
        />
        <PublicRoute
          path='/home'
          exact
          component={Home}
        />
        <PublicRoute
          path='/privacy-policy'
          exact
          component={PrivacyAndPolicy}
        />
        <PublicRoute
          path='/terms-and-conditions'
          exact
          component={TermsAndCondition}
        />
        <PublicRoute
          path='/pricing'
          exact
          component={Pricing}
        />
        <PrivateRoute
          isAuthenticated={user.isAuthenticated}
          path='/dashboard'
          to='/home'
          exact
          component={Dashboard}
        />
        <PrivateRoute
          isAuthenticated={user.isAuthenticated}
          path='/products'
          to='/home'
          exact
          component={Products}
        />
        <PrivateRoute
          isAuthenticated={user.isAuthenticated}
          path='/orders'
          to='/home'
          exact
          component={Orders}
        />
        <PrivateRoute
          isAuthenticated={user.isAuthenticated}
          path='/categories'
          to='/home'
          exact
          component={Categories}
        />
        <PrivateRoute
          isAuthenticated={user.isAuthenticated}
          path='/police'
          to='/home'
          exact
          component={Cadets}
        />
        <PrivateRoute
          isAuthenticated={user.isAuthenticated}
          path='/stations'
          to='/home'
          exact
          component={Stations}
        />
        <PrivateRoute
          isAuthenticated={user.isAuthenticated}
          path='/delivery-partner'
          to='/home'
          exact
          component={DeliveryPartners}
        />
        <PrivateRoute
          isAuthenticated={user.isAuthenticated}
          path='/order-locations'
          to='/home'
          exact
          component={Locations}
        />
        <PrivateRoute
          isAuthenticated={user.isAuthenticated}
          path='/settings'
          to='/home'
          exact
          component={Settings}
        />
        <PrivateRoute
          isAuthenticated={user.isAuthenticated}
          path='/reservations'
          to='/home'
          exact
          component={Reservations}
        />

        <Route
          component={RegisterMember}
          exact
          path='/register-member'
        />
        <Route
          component={ReservationDetails}
          exact
          path='/reservation'
        />
        <Route
          component={NotFound}
          exact
          path='/not-found'
        />
        <Redirect to='/not-found' />
      </Switch>
    )
  }
}

/* istanbul ignore next */
function mapStateToProps (state) {
  return {
    user: state.user
  }
}

export default connect(mapStateToProps)(Routes)
