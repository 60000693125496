import React, { Component } from 'react'
import PropTypes from 'prop-types'
import 'react-google-places-autocomplete/dist/assets/index.css'

import { GoogleMap, withGoogleMap, withScriptjs } from 'react-google-maps'

class MapContainer extends Component {
  render () {
    const { getUpdatedCenter, latlng } = this.props
    return (<GoogleMap
      defaultZoom={15}
      ref={mapRef => (this.ref = mapRef)}
      defaultCenter={{ lat: parseFloat(latlng.lat), lng: parseFloat(latlng.lng) }}
      options={{ draggable: true }}
      onDragEnd={() => getUpdatedCenter(this.ref.getCenter())}
    />)
  }
}

MapContainer.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withScriptjs(withGoogleMap(MapContainer))
