import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles'
import { Grid, Typography } from '@material-ui/core'

const style = theme => ({
  contactNumbersContainer: {
    marginTop: '30px',
    padding: '0 20px 0',
    display: 'flex',
    margin: '20px',
    [theme.breakpoints.down('xs')]: {
      padding: '0px 5px 0px'
    }
  },
  contactCard: {
    margin: '30px',
    flex: '15%',
    textAlign: 'left',
    [theme.breakpoints.down('xs')]: {
      flex: '40%',
      margin: '20px'
    }
  },
  cardHeading: {
    fontSize: '15px',
    fontWeight: '500',
    color: 'white',
    textTransform: 'uppercase'
  },
  cardNumber: {
    fontSize: '14px',
    fontWeight: '400',
    color: 'white'
  }
})

class ContactNumbers extends Component {
  render () {
    const { classes } = this.props

    const contactDetails = [
      {
        name: 'SCPC THIRUVANANTHAPURAM',
        phone: '9497901277'
      },
      {
        name: 'SCPC KOLLAM',
        phone: '4742749000'
      },
      {
        name: 'CPC ADOOR',
        phone: '216371'
      },
      {
        name: 'PATHANAMTHITTA  SCPC HQ',
        phone: '9497908400'
      },
      {
        name: 'SCPC ALAPPUZHA',
        phone: '04772237160'
      },
      {
        name: 'SCPC KOTTAYAM',
        phone: '9497961424'
      },
      {
        name: 'SCPC IDUKKI',
        phone: '9496443208'
      },
      {
        name: 'CPC KOCHI',
        phone: '8281564312'
      },
      {
        name: 'POLICE CANTEEN PERUMBAVOOR',
        phone: '9497962368'
      },
      {
        name: 'SCPC THRISSUR KEPA',
        phone: '04872334247'
      },
      {
        name: 'SCPC PALAKKAD',
        phone: '4912509811'
      },
      {
        name: 'SCPC MALAPPURAM',
        phone: '8547871496'
      },
      {
        name: 'SCPC KOZHIKODE',
        phone: '9497923431'
      },
      {
        name: 'SCPC WAYANAD',
        phone: '04936202508'
      },
      {
        name: 'SCPC VATAKARA',
        phone: '9446823115'
      },
      {
        name: 'CPC KANNUR',
        phone: '04972700819'
      },
      {
        name: 'SCPC KASARAGOD',
        phone: '04994257080'
      }
    ]
    console.log(contactDetails)

    return (
      <Grid container
        justify='center'
        className={classes.contactNumbersContainer}
      >
        {contactDetails.map(cpc => {
          return (
            <Grid className={classes.contactCard}>
              <Typography className={classes.cardHeading}> {cpc.name} </Typography>
              <Typography className={classes.cardNumber}> {cpc.phone} </Typography>
            </Grid>
          )
        })}
      </Grid>
    )
  }
}

export default withStyles(style)(ContactNumbers)
