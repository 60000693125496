import React, { Component } from 'react'

// Externals
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import compose from 'recompose/compose'

// Material helpers
import { withStyles } from '@material-ui/core'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Switch from '@material-ui/core/Switch'

// Component styles
import styles from './style'
import ProductDetails from '../ProductDetails'
import EditProduct from '../EditProduct'
import { DeleteConfirmation } from '..'
import { getResizedImage } from 'services/utility'
import { deleteProduct } from 'redux/actions'

class ProductCard extends Component {
  state = {
    showDetails: false
  }

  onSelectProductDetails = () => {
    this.setState({ showDetails: true })
  }

  onCloseDetails = () => {
    this.setState({ showDetails: false })
  }

  onDeleteProduct = () => {
    this.setState({ showDelete: true })
  }

  hideDeleteProduct = () => {
    this.setState({ showDelete: false })
  }

  onEditProduct = () => {
    this.setState({ showEdit: true })
  }

  onCloseEdit = () => {
    this.setState({ showEdit: false })
  }

  onUpdateProduct = (details) => {

  }

  changeStockStatus = (event) => {
    const { checked } = event.target
    const { product, onStatusChange } = this.props
    const request = {
      stock_status: checked,
      stock_count: 1,
      itemId: product.id
    }
    onStatusChange(request)
  }

  confirmDelete = () => {
    const { product, dispatch } = this.props
    dispatch(deleteProduct(product.id))
  }

  render () {
    const { classes, product, isLoading } = this.props
    const { showDetails, showEdit, showDelete } = this.state
    const stockStatus = product.in_stock
    return (
      <Card>
        <CardActionArea className={classes.area} onClick={this.onSelectProductDetails}>
          <CardMedia
            className={classes.image}
            component='img'
            alt={product.name}
            height='120'
            image={product.image_url ? getResizedImage(product.image_url, 200, 200) : ''}
            title={product.name}
          />
          <CardContent className={classes.details}>
            <Typography className={classes.name} gutterBottom variant='h5' component='h2'>
              {product.name}
            </Typography>
            <Typography className={classes.manu} variant='body2' color='textSecondary' component='p'>
              {product.manufacturer}
            </Typography>
            <Typography className={classes.price} variant='body2' color='textSecondary' component='p'>
              &#8377;{product.price}
              { product.actual_price ? <Typography className={classes.oldPrice} variant='body2' color='textSecondary' component='strike'>&#8377;{product.actual_price}</Typography> : null }
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions className={classes.footer}>
          <Typography className={classes.stock} variant='body2' color='textSecondary' component='p'>
            STOCK
            <Switch
              className={classes.switch}
              checked={stockStatus}
              onChange={this.changeStockStatus}
              value='checkedA'
              disabled={isLoading}
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </Typography>
          <Button className={classes.editButton} size='small' color='primary' variant='outlined' onClick={this.onEditProduct}>
            Edit
          </Button>
          <Button className={classes.deleteButton} size='small' color='secondary' variant='outlined' onClick={this.onDeleteProduct}>
            Delete
          </Button>
        </CardActions>
        <DeleteConfirmation isOpen={showDelete} product={product} onCancel={this.hideDeleteProduct} onSubmit={this.confirmDelete} />
        <ProductDetails isOpen={showDetails} onCancel={this.onCloseDetails} id={product ? product.id : null} onSubmit={this.onCloseDetails} />
        <EditProduct isOpen={showEdit} onCancel={this.onCloseEdit} id={product ? product.id : null} onSubmit={this.onUpdateProduct} />
      </Card>
    )
  }
}

ProductCard.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired
}

function mapStateToProps (state) {
  return {
    delete: state.products.delete
  }
}

export default compose(
  withStyles(styles)
)(connect(mapStateToProps)(ProductCard))
