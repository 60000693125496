import React, { Component } from 'react'

import PropTypes from 'prop-types'
import { Grid, withStyles, Button } from '@material-ui/core'
import compose from 'recompose/compose'
import { withRouter } from 'react-router-dom'
import { showAlert } from 'redux/actions'
import { getCookie } from 'services/cookie'
import { connect } from 'react-redux'

import style from './style'
import ContactDetailsModal from 'views/Common/ContactDetailsModal'

class ShortCuts extends Component {
  state = {
    showContact: false
  }
  goToDelivery = () => {
    this.props.history.push('/settings?selected=delivery')
  }

  goToAddress = () => {
    this.props.history.push('/settings')
  }

  goToProduct = () => {
    this.props.history.push('/products')
  }

  goToCategory = () => {
    this.props.history.push('/categories')
  }

  getSharableLink = () => {
    const shopId = getCookie('shop_id')
    const link = `https://shopsapp.org/dl?sid=${shopId}`
    let textField = document.createElement('textarea')
    textField.innerText = link
    document.body.appendChild(textField)
    textField.select()
    let success = document.execCommand('copy')
    textField.remove()
    success = success || document.execCommand('copy', false, link)
    if (success) {
      this.props.dispatch(showAlert('Copied shop link to clipboard!', { variant: 'success' }))
    } else {
      this.props.dispatch(showAlert('Failed to copy shop link to clipboard!', { variant: 'error' }))
    }
  }

  copyContact = (type) => {
    let link = 'info@shopsapp.net'
    if (type === 'phone') {
      link = '+918089311548'
    }
    let textField = document.createElement('textarea')
    textField.innerText = link
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
    // document.execCommand('copy', false, link)
    this.props.dispatch(showAlert(`Copied ${type === 'phone' ? 'Phone Number' : 'Email Address'} Successfully`, { variant: 'success' }))
  }

  openContact = () => {
    this.setState({ showContact: true })
  }

  closeContact = () => {
    this.setState({ showContact: false })
  }

  render () {
    const { classes, status } = this.props

    return (
      <React.Fragment>
        <Grid
          item
          lg={3}
          md={4}
          xl={3}
          xs={6}
          className={classes.buttonContainer}
        >
          <Button className={classes.button} color='primary' size='large' variant='contained' onClick={this.getSharableLink} >Get shop link</Button>
        </Grid>
        <Grid
          item
          lg={3}
          md={4}
          xl={3}
          xs={6}
          className={classes.buttonContainer}
        >
          <Button className={classes.button} color='secondary' size='large' variant='contained' onClick={this.openContact} >Contact ShopsApp</Button>
        </Grid>
        <Grid
          item
          lg={3}
          md={4}
          xl={3}
          xs={6}
          className={classes.buttonContainer}
        >
          <Button className={classes.button} color='primary' size='large' variant='contained' onClick={this.goToAddress} > Edit Address </Button>
        </Grid>
        <Grid
          item
          lg={3}
          md={4}
          xl={3}
          xs={6}
          className={classes.buttonContainer}
        >
          <Button className={classes.button} color='secondary' size='large' variant='contained' onClick={this.goToCategory} >{ 'Add Categories' }</Button>
        </Grid>
        <Grid
          item
          lg={3}
          md={4}
          xl={3}
          xs={6}
          className={classes.buttonContainer}
        >
          <Button className={classes.button} color='secondary' size='large' variant='contained' onClick={this.goToProduct} >{ status && !status.first_product ? 'Add Products' : 'Add More Products'}</Button>
        </Grid>
        <Grid
          item
          lg={3}
          md={4}
          xl={3}
          xs={6}
          className={classes.buttonContainer}
        >
          <Button className={classes.button} color='primary' size='large' variant='contained' onClick={this.goToDelivery} >{ 'Edit Delivery Methods' }</Button>
        </Grid>

        <ContactDetailsModal isOpen={this.state.showContact} onCancel={this.closeContact} copyContact={this.copyContact} />
      </React.Fragment>

    )
  }
}

ShortCuts.propTypes = {
  classes: PropTypes.object.isRequired
}

function mapStateToProps (state) {
  return {
  }
}

export default compose(
  withStyles(style), withRouter
)(connect(mapStateToProps)(ShortCuts))
