export default theme => ({
  root: {
    padding: theme.spacing.unit * 4,
    paddingBottom: 0
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative'
  },
  item: {
    height: '100%'
  },
  progressWrapper: {
    width: '100%',
    top: 500,
    left: 0,
    right: 0,
    paddingBottom: '24px',
    display: 'flex',
    justifyContent: 'center'
  }
})
