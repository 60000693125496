import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles, Typography, Card } from '@material-ui/core'
import compose from 'recompose/compose'
import { connect } from 'react-redux'

const styles = theme => ({
  root: {

  },
  cardContainer: {
    cursor: 'pointer',
    padding: 20,
    display: 'flex',
    justifyContent: 'space-between'
  },
  selected: {
    background: '#fbd5d5'
    // boxShadow: '0px 2px 1px -1px rgba(255,130,164,0.6), 0px 1px 1px 0px rgba(255,130,164,0.6), 0px 1px 3px 0px rgba(255,130,164,0.6)'
  },
  details: {
    display: 'flex',
    width: 'calc( 100% - 92px)'
  },
  info: {
    width: '100%',
    height: 82
  },
  dateContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column'
  },
  bookingHeading: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 10
  },
  bookingTime: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    '& p:last-child': {
      fontWeight: 'bold'
    }
  },
  imageContainer: {
    border: '1px solid gray',
    borderRadius: 4,
    '& img': {
      height: 82,
      width: 82,
      borderRadius: 4
    }
  }

})

class ShopListCell extends Component {
  render () {
    const { classes, shop, onSelectShop, selectedShops } = this.props
    const isSelected = selectedShops.indexOf(shop.id) > -1
    return (
      <Card >
        <div className={`${classes.root} ${isSelected ? classes.selected : ''}`} >
          <div className={classes.cardContainer} onClick={() => onSelectShop(shop.id)}>
            <div className={classes.imageContainer} >
              <img src={shop.logo_url || shop.image_url} alt='logo url' />
            </div>
            <div className={classes.details}>
              <div className={classes.info}>
                <Typography className={classes.bookingHeading} variant='h4'>{shop.name || shop.business_name}</Typography>
                <div className={classes.dateContainer} >
                  <div className={classes.bookingTime}>
                    <Typography variant='body1' > Locatlity </Typography>
                    <Typography variant='body1' >{shop.locality}</Typography>
                  </div>
                  <div className={classes.bookingTime}>
                    <Typography variant='body1' > Customer Care Number </Typography>
                    <Typography variant='body1' >{shop.customer_care || '-'}</Typography>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </Card>
    )
  }
}

ShopListCell.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
}

function mapStateToProps (state) {
  return {

  }
}

export default compose(
  withStyles(styles)
)(connect(mapStateToProps)(ShopListCell))
