import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Grid, withStyles, Button } from '@material-ui/core'
import { UserProfile, UserDetails } from '..'
import ChangePasswordModal from 'views/Common/ChangePasswordModal'

const styles = theme => ({
  root: {
    paddingTop: theme.spacing.unit * 4
  },
  button: {
    marginTop: 20
  }
})

class Account extends Component {
  state = { tabIndex: 0 };

  showChangePassword = () => {
    this.setState({ showChangePassword: true })
  }

  closeChangePassword = () => {
    this.setState({ showChangePassword: false })
  }

  render () {
    const { classes } = this.props
    const { showChangePassword } = this.state
    return (
      <div className={classes.root}>
        <Grid
          container
          spacing={4}
        >
          <Grid
            item
            lg={4}
            md={6}
            xl={4}
            xs={12}
          >
            <UserProfile />
            <Button className={classes.button} color='primary' size='large' variant='contained' onClick={this.showChangePassword} >Change Password</Button>
          </Grid>
          <Grid
            item
            lg={8}
            md={6}
            xl={8}
            xs={12}
          >
            <UserDetails />
          </Grid>
        </Grid>
        <ChangePasswordModal isOpen={showChangePassword} onCancel={this.closeChangePassword} onSubmit={this.closeChangePassword} />
      </div>
    )
  }
}

Account.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(Account)
