import React, { Component } from 'react'

// Externals
import PropTypes from 'prop-types'

// Material helpers
import { withStyles, Switch, Button, CircularProgress, Grid } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { connect } from 'react-redux'

// Component styles
import Modal from 'views/Modal'
import styles from './style'
import { getCookie } from 'services/cookie'
import EditProduct from '../EditProduct'
import { getResizedImage } from 'services/utility'
import { productGetDetails, changeStockStatus, showAlert } from 'redux/actions/index'
import treeChanges from 'tree-changes'
import { STATUS } from 'redux/constants'

class ProductDetails extends Component {
  constructor (props) {
    super(props)
    this.state = {
      showEdit: false,
      selectedImage: 0
    }
    if (props.id && props.isOpen) {
      props.dispatch(productGetDetails(props.id))
    }
  }

  componentWillReceiveProps = newProps => {
    const { changed, changedTo } = treeChanges(this.props, newProps)
    if (newProps.id && changed('isOpen') && newProps.isOpen) {
      newProps.dispatch(productGetDetails(newProps.id))
    } else if (changedTo('productDetails.status') === STATUS.READY) {
      const product = newProps.productDetails.data
      const index = (product.images || []).findIndex(item => item.url === product.image_url)
      this.setState({ selectedImage: index })
    }
  }

  onEditProduct = () => {
    this.setState({ showEdit: true })
  }

  hideEdit = () => {
    this.setState({ showEdit: false })
  }

  setSelectedImage = index => {
    if (this.state.selectedImage !== index) {
      this.setState({ selectedImage: index })
    }
  }

  onCopyShareableLink = () => {
    const { id } = this.props
    const shopId = getCookie('shop_id')
    const link = `https://shopsapp.org/dl?sid=${shopId}&pid=${id}`
    if (navigator.clipboard) {
      navigator.clipboard.writeText(link).then(() => {
        this.props.dispatch(showAlert('Copied product link to clipboard!', { variant: 'success' }))
      }, function () {
        this.props.dispatch(showAlert('Failed to product shop link to clipboard!', { variant: 'error' }))
      })
    } else {
      let textField = document.createElement('input')
      textField.innerText = link
      document.body.appendChild(textField)
      textField.select()
      let success = document.execCommand('copy')
      textField.remove()
      success = success || document.execCommand('copy', false, link)
      if (success) {
        this.props.dispatch(showAlert('Copied product link to clipboard!', { variant: 'success' }))
      } else {
        this.props.dispatch(showAlert('Failed to copy product link to clipboard!', { variant: 'error' }))
      }
    }
  }

  changeStockStatus = (event) => {
    const { checked } = event.target
    const { productDetails } = this.props
    const request = {
      stock_status: checked,
      stock_count: 1,
      itemId: productDetails.data.id
    }
    this.props.dispatch(changeStockStatus(request))
  }

  weightConverter = (value) => {
    if (value === 0 || !value) {
      return '-'
    } else if (value < 1000) {
      return `${value}grams`
    } else if (value >= 1000) {
      return `${value / 1000}kg`
    }
  }

  render () {
    const { classes, onCancel, isOpen, productDetails } = this.props
    const { showEdit, selectedImage } = this.state
    const product = productDetails.data
    const valueBold = [classes.value, classes.valueBold]
    let child, selectedImageUrl
    if (selectedImage > -1 && selectedImage < (product.images || []).length) {
      selectedImageUrl = getResizedImage(product.images[selectedImage].url, 300, 300)
    }

    const footer = <div className={classes.footer} >
      <div className={classes.stock} >
        <Typography className={classes.stockHeading} variant='body2' color='textSecondary' component='p'>STOCK</Typography>
        <Switch
          className={classes.switch}
          checked={product.in_stock}
          onChange={this.changeStockStatus}
          value='checkedA'
          // disabled={isLoading}
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
        <Button className={classes.copyButton} size='small' color='primary' variant='contained' onClick={this.onCopyShareableLink}>
          Copy Shareable Link
        </Button>
      </div>
      <div className={classes.buttonContainer} >
        <Button className={classes.editButton} size='small' color='primary' variant='contained' onClick={this.onEditProduct}>
          Edit
        </Button>
        <Button className={classes.editButton} size='small' color='primary' variant='outlined' onClick={onCancel}>
          Close
        </Button>
      </div>
    </div>

    if (!productDetails || productDetails.status === STATUS.RUNNING) {
      child = <div className={classes.container}>
        <div className={classes.progressWrapper}>
          <CircularProgress />
        </div>
      </div>
    } else {
      child = <div className={classes.container}>
        {(product.images || []).length ? <div className={classes.imageContainer}>
          <img className={classes.image} src={selectedImageUrl} alt={product.name} />
          <div className={classes.list}>
            {product.images.length > 1 ? (
              <div className={classes.imageListContainer}>
                {(product.images || []).map((item, index) => {
                  const resizedImage = getResizedImage(item.url || item.imagePreviewUrl, 150, 150)
                  return <div className={`${classes.miniImage} ${index === selectedImage ? classes.selected : ''}`} style={{ backgroundImage: `url(${resizedImage})` }} onClick={() => this.setSelectedImage(index)} />
                })}
              </div>
            ) : null}

          </div>
        </div> : null}
        <Grid className={classes.details} container spacing={2}>
          <Grid lg={6} md={6} sm={12} item>
            <div className={classes.detailsCell} >
              <Typography className={classes.key} variant='body2' color='textSecondary' component='p'>Name: </Typography>
              <Typography className={valueBold} variant='body2' color='textSecondary' component='p'>{product.name || '-'}</Typography>
            </div>
            <div className={classes.detailsCell} >
              <Typography className={classes.key} variant='body2' color='textSecondary' component='p'>Manufacturer:</Typography>
              <Typography className={classes.value} variant='body2' color='textSecondary' component='p'>{product.manufacturer || '-'}</Typography>
            </div>
            <div className={classes.detailsCell} >
              <Typography className={classes.key} variant='body2' color='textSecondary' component='p'>Model:</Typography>
              <Typography className={classes.value} variant='body2' color='textSecondary' component='p'>{product.model || '-'}</Typography>
            </div>
            <div className={classes.detailsCell} >
              <Typography className={classes.key} variant='body2' color='textSecondary' component='p'>Desciption:</Typography>
              <Typography className={classes.value} variant='body2' color='textSecondary' component='p'>{product.details || '-'}</Typography>
            </div>
            <div className={classes.detailsCell} >
              <Typography className={classes.key} variant='body2' color='textSecondary' component='p'>Weight:</Typography>
              <Typography className={classes.value} variant='body2' color='textSecondary' component='p'>{this.weightConverter(product.weight)}</Typography>
            </div>
          </Grid>
          <Grid lg={6} md={6} sm={12} item>
            <div className={classes.detailsCell} >
              <Typography className={classes.key} variant='body2' color='textSecondary' component='p'>Item Code(SKU):</Typography>
              <Typography className={classes.value} variant='body2' color='textSecondary' component='p'>{product.item_code || '-'}</Typography>
            </div>
            <div className={classes.detailsCell} >
              <Typography className={classes.key} variant='body2' color='textSecondary' component='p'>Rank:</Typography>
              <Typography className={classes.value} variant='body2' color='textSecondary' component='p'>{product.rank || '-'}</Typography>
            </div>
            <div className={classes.detailsCell} >
              <Typography className={classes.key} variant='body2' color='textSecondary' component='p'>Actual Price:</Typography>
              {product.actual_price ? <Typography className={classes.value} variant='body2' color='textSecondary' component='p'>&#8377;{product.actual_price}</Typography> : <Typography className={classes.value} variant='body2' color='textSecondary' component='p'>-</Typography>}
            </div>
            <div className={classes.detailsCell} >
              <Typography className={classes.key} variant='body2' color='textSecondary' component='p'>Price:</Typography>
              {product.price ? <Typography className={valueBold} variant='body2' color='textSecondary' component='p'>&#8377;{product.price}</Typography> : <Typography className={classes.value} variant='body2' color='textSecondary' component='p'>-</Typography>}
            </div>
            {product.manage_stock ? <div className={classes.detailsCell} >
              <Typography className={classes.key} variant='body2' color='textSecondary' component='p'>Stock Unit:</Typography>
              {product.actual_price ? <Typography className={classes.value} variant='body2' color='textSecondary' component='p'>{product.stock_value}</Typography> : <Typography className={classes.value} variant='body2' color='textSecondary' component='p'>-</Typography>}
            </div> : null}
            {product.manage_stock ? <div className={classes.detailsCell} >
              <Typography className={classes.key} variant='body2' color='textSecondary' component='p'>Reorder Point:</Typography>
              {product.reorder_point ? <Typography className={classes.value} variant='body2' color='textSecondary' component='p'>{product.reorder_point}</Typography> : <Typography className={classes.value} variant='body2' color='textSecondary' component='p'>-</Typography>}
            </div> : null}
          </Grid>
        </Grid>
      </div>
    }

    return (
      <React.Fragment >
        <EditProduct isOpen={showEdit} onCancel={this.hideEdit} id={product ? product.id : null} onSubmit={this.hideEdit} />
        <Modal isOpen={isOpen} title={'PRODUCT DETAILS'} child={child} footer={footer} width={950} />
      </React.Fragment>

    )
  }
}

ProductDetails.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired
}

function mapStateToProps (state) {
  return {
    productDetails: state.products.details
  }
}

export default withStyles(styles)(connect(mapStateToProps)(ProductDetails))
