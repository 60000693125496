import React, { Component } from 'react'

import PropTypes from 'prop-types'
import { withStyles, Tabs, Tab } from '@material-ui/core'
import { Dashboard as DashboardLayout } from 'layouts'

import style from './style'
import { ActiveOrders, CompletedOrders } from './components'
// Component styles

class Orders extends Component {
    state = {
      stage: 0
    }

    handleChange = (event, stage) => {
      this.setState({ stage })
    }

    render () {
      const { classes } = this.props
      const { stage } = this.state

      return (
        <DashboardLayout title='Orders'>
          <div className={classes.root}>

            <Tabs
              value={stage}
              className={classes.tabs}
              onChange={this.handleChange}
              indicatorColor='primary'
              textColor='primary'
              aria-label='icon tabs example'
            >
              <Tab className={classes.tab} label='Active Orders' aria-label='shop' />
              <Tab className={classes.tab} label='Completed Orders' aria-label='user' />
            </Tabs>
            { stage === 0 ? <ActiveOrders /> : null }
            { stage === 1 ? <CompletedOrders /> : null }
          </div>
        </DashboardLayout>
      )
    }
}

Orders.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(style)(Orders)
