export default theme => ({
  priceCard: {
    borderRadius: '16px',
    width: '320px',
    marginBottom: '10px',
    boxShadow: '0 10px 6px -6px #777'
  },
  planType: {
    color: '#0B6430',
    fontSize: '14px',
    fontFamily: 'Poppins',
    fontWeight: '500'
  },
  planTitle: {
    color: '#707070',
    fontSize: '22px',
    fontFamily: 'Poppins',
    paddingTop: '10px'
  },
  icon: {
    width: '33px',
    height: '33px',
    borderRadius: '50px',
    background: 'linear-gradient(to bottom right, #39BE6F, #548A6A)'
  },
  planPrice: {
    fontSize: '24px',
    fontFamily: 'Montserrat',
    paddingTop: '10px'
  },
  planDescription: {
    fontSize: '15px',
    fontFamily: 'Poppins',
    paddingTop: '5px',
    color: '#707070'
  },
  planFeatures: {
    fontSize: '13px',
    fontFamily: 'Poppins',
    padding: '15px 15px 0',
    color: '#707070',
    lineHeight: '25px'
  }
})
