
export default theme => ({
  card: {
    width: '100%'
  },
  area: {
    padding: 20,
    display: 'flex',
    alignItems: 'flex-start',
    border: '2px solid white'
  },
  selected: {
    border: '2px solid red'
  },
  image: {
    width: 120
  },
  details: {
    width: 'calc( 100% - 120px)',
    paddingTop: 0,
    paddingBottom: 0,
    position: 'relative',
    height: 120
  },
  name: {
    maxHeight: 40,
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  manu: {
    maxHeight: 40,
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  price: {
    position: 'absolute',
    bottom: 0,
    left: 16,
    fontWeight: 'bold',
    fontSize: 17
  },
  oldPrice: {
    color: 'gray',
    marginLeft: 10,
    fontWeight: 500
  }
})
