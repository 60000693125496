// @flow
/**
 * @module Actions/User
 * @desc User Actions
 */
import { createActions } from 'redux-actions'

import { ActionTypes } from 'redux/constants/index'

export const { getActiveReservations, getReservations, checkoutReservation, checkinReservation, getReservationCount, getReservationDetails } = createActions({
  [ActionTypes.GET_ACTIVE_RESERVATIONS]: (skip, limit, status) => ({ skip, limit, status }),
  [ActionTypes.GET_RESERVATIONS]: (date, skip, limit, status) => ({ date, skip, limit, status }),
  [ActionTypes.CHECKOUT_RESERVATION]: (id) => ({ id }),
  [ActionTypes.CHECKIN_RESERVATION]: (id) => ({ id }),
  [ActionTypes.GET_RESERVATION_COUNT]: () => ({ }),
  [ActionTypes.GET_RESERVATION_DETAILS]: (shopId, id) => ({ shopId, id })
})
