export default theme => ({
  form: {
    paddingLeft: '100px',
    paddingRight: '100px',
    paddingBottom: '0px',
    flexBasis: '700px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing.unit * 2,
      paddingRight: theme.spacing.unit * 2
    }
  },
  title: {
    marginTop: theme.spacing.unit * 3
  },
  subtitle: {
    marginTop: theme.spacing.unit * 0.5,
    fontWeight: 'bold'
  },
  fields: {
    marginTop: theme.spacing.unit * 2,
    '& .google-places-autocomplete': {
      height: 40,
      '& input': {
        height: '100%',
        width: '100%'
      }
    }
  },
  textField: {
    width: '100%',
    '& + & ': {
      marginTop: theme.spacing.unit * 2
    }
  },
  progress: {
    display: 'block',
    marginTop: theme.spacing.unit * 2,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  signUpButton: {
    marginTop: 40,
    width: '100%'
  },
  fieldError: {
    color: theme.palette.danger.main,
    marginBottom: theme.spacing.unit * 2,
    marginTop: theme.spacing.unit
  },
  submitError: {
    color: theme.palette.danger.main,
    alignText: 'center',
    marginBottom: theme.spacing.unit,
    marginTop: theme.spacing.unit * 2
  },
  locationButton: {
    marginTop: theme.spacing.unit * 2
  },
  clearLocationButton: {
    marginTop: 20,
    marginBottom: 20
  }
})
