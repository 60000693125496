export default theme => ({
  root: {
    paddingBottom: 20
  },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing.unit
  },
  spacer: {
    flexGrow: 1
  },
  searchInput: {
    marginRight: theme.spacing.unit
  },
  firstButton: {
    marginRight: 20
  },
  count: {
    width: 220,
    paddingRight: 20,
    display: 'flex',
    alignItems: 'flex-end',
    '& span': {
      width: 220,
      textAlign: 'right',
      fontWeight: 'bold',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
    }
  }
})
