import React, { Component } from 'react'

// Externals
import classNames from 'classnames'
import PropTypes from 'prop-types'
import PerfectScrollbar from 'react-perfect-scrollbar'
import compose from 'recompose/compose'
import treeChanges from 'tree-changes'
import { connect } from 'react-redux'
import moment from 'moment'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  withStyles,
  Button,
  CircularProgress,
  Typography
} from '@material-ui/core'

import { getActiveReservations, checkoutReservation, showAlert } from 'redux/actions'
import { Portlet, PortletContent } from 'components'
import styles from './style'
import { ReservationConfirmation } from '..'
import { STATUS } from 'redux/constants'
import SearchToolbar from 'views/Common/SearchToolbar'

const itemsPerPage = 20

class ActiveReservations extends Component {
  state = {
    page: 0
  }

  componentDidMount = () => {
    const { page, searchString } = this.state
    this.props.dispatch(getActiveReservations(page, itemsPerPage, searchString))
  }

  componentWillReceiveProps = newProps => {
    const { changedTo } = treeChanges(this.props, newProps)
    if (changedTo('reservations.status', STATUS.READY)) {
      this.setState({ isLoading: false })
    }
    if (changedTo('checkout.status', STATUS.READY)) {
      const successMsg = 'Successfully marked as checked out'
      this.props.dispatch(showAlert(successMsg, { variant: 'success' }))
      this.setState({ showConfirmation: false, selectedReservation: null })
    } else if (changedTo('checkout.status', STATUS.ERROR)) {
      this.props.dispatch(showAlert(newProps.checkout.message, { variant: 'error' }))
    }
  }

  showCheckoutConfirmation = (user) => {
    this.setState({ showConfirmation: true, selectedReservation: user })
  }

  hideCheckoutConfirmation = () => {
    this.setState({ showConfirmation: false, selectedReservation: null })
  }

  confirmCheckout = () => {
    this.props.dispatch(checkoutReservation(this.state.selectedReservation.id))
  }

  onChangeSearchString = event => {
    const { value } = event.target
    this.setState({ searchString: value })
  }

  onClickSearch = () => {
    const { page, searchString } = this.state
    this.setState({ isLoading: true })
    this.props.dispatch(getActiveReservations(page, itemsPerPage, searchString))
  }

  refreshPage = () => {
    const { isLoading, page, searchString } = this.state
    const nextIndex = (page + 1) * itemsPerPage
    if (!isLoading) {
      this.setState({ isLoading: true }, () => {
        this.props.dispatch(getActiveReservations(0, nextIndex, searchString))
      })
    }
  }

  onReachedBottom = () => {
    const { reservations } = this.props
    const { isLoading, page, searchString } = this.state
    const nextIndex = (page + 1) * itemsPerPage
    if (!isLoading && !reservations.reachedEnd) {
      this.setState({ page: page + 1, isLoading: true }, () => {
        this.props.dispatch(getActiveReservations(nextIndex, itemsPerPage, searchString))
      })
    }
  }

  getReservationList = () => {
    const { reservations, classes, className } = this.props
    const { showConfirmation, selectedReservation } = this.state
    const rootClassName = classNames(classes.root, className)

    if (reservations.status === STATUS.RUNNING && reservations.data.length === 0) {
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress />
        </div>
      )
    }

    if (reservations.data.length === 0) {
      return (
        <Typography variant='h6'>No active reservations found.</Typography>
      )
    }
    return (
      <React.Fragment>
        {reservations.status === STATUS.RUNNING ? <div className={classes.progressWrapper}>
          <CircularProgress />
        </div> : null}
        <Portlet className={rootClassName}>
          <PortletContent noPadding>
            <PerfectScrollbar onYReachEnd={this.onReachedBottom}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align='left'>Code</TableCell>
                    <TableCell align='left'>Name</TableCell>
                    <TableCell align='left'>Memeber ID</TableCell>
                    <TableCell align='left'>Time Inside</TableCell>
                    <TableCell align='left'>Date</TableCell>
                    <TableCell align='left'>Slot</TableCell>
                    <TableCell align='left' />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reservations.data
                    .map(reservation => (
                      <TableRow
                        className={classes.tableRow}
                        hover
                        key={reservation.id}
                      >
                        <TableCell className={classes.tableCell}>
                          {reservation.code}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {reservation.name}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {reservation.member_id}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <Timer time={reservation.checkedin_ts} />
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {moment(reservation.start_ts).format('DD/MM/YYYY')}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {`${moment(reservation.start_ts).format('hh:mm A')} - ${moment(reservation.end_ts).format('hh:mm A')}`}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <Button color='primary' size='small' variant='contained' onClick={() => this.showCheckoutConfirmation(reservation)} > Checkout </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </PerfectScrollbar>
          </PortletContent>
          <ReservationConfirmation isOpen={showConfirmation} type='checkout' reservation={selectedReservation} onCancel={this.hideCheckoutConfirmation} onSubmit={this.confirmCheckout} />
        </Portlet>
      </React.Fragment>

    )
  }

  render () {
    return (
      <React.Fragment>
        <SearchToolbar
          searchString={this.state.searchString}
          onChangeSearchString={this.onChangeSearchString}
          onClickRefreshButton={this.refreshPage}
          onClickSearch={this.onClickSearch}
        />
        {this.getReservationList()}
      </React.Fragment>
    )
  }
}

ActiveReservations.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
}

function mapStateToProps (state) {
  return {
    reservations: state.reservations.list,
    checkout: state.reservations.checkout
  }
}

export default compose(
  withStyles(styles)
)(connect(mapStateToProps)(ActiveReservations))

class Timer extends Component {
  state = {
    time: ''
  }

  componentDidMount () {
    this.updateTime()
    this.interval = setInterval(this.updateTime, 60 * 1000)
  }

  componentWillUnmount () {
    clearInterval(this.interval)
  }

  updateTime = () => {
    const timeDiff = parseInt((new Date().getTime() - this.props.time) / 1000)
    const time = `${timeDiff / 60 / 60 / 24 > 1 ? `${parseInt(timeDiff / 60 / 60 / 24) % 24}d: ` : ''} ${timeDiff / 60 / 60 > 1 ? `${parseInt(timeDiff / 60 / 60) % 60}h: ` : ''}${parseInt(timeDiff / 60) % 60}m`
    this.setState({ time })
  }

  render () {
    const { time } = this.state
    return <p>{ time }</p>
  }
}
