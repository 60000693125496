import React, { Component } from 'react'

import PropTypes from 'prop-types'
import { Grid, withStyles, CircularProgress } from '@material-ui/core'
import { Dashboard as DashboardLayout } from 'layouts'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { STATUS } from 'redux/constants/index'

import { Budget, Users, DevicesChart, Status, Accept, ShortCuts } from './components'
import { getAccountStatus } from 'redux/actions'
import style from './style'
import Confirmation from './components/Confirmation'

const defaultVerificationsTatus = {
  added_address: true,
  added_logo: true,
  delivery_options: true,
  email_verified: true,
  first_product: true,
  phone_verified: true,
  verified_customer_care: true
}
// Component styles

class Dashboard extends Component {
  componentDidMount = () => {
    this.props.dispatch(getAccountStatus())
  }

  render () {
    const { classes, analytics } = this.props
    if (analytics.status === STATUS.RUNNING) {
      return <DashboardLayout title='Dashboard'>
        <div className={classes.root}>
          <Grid
            container
            spacing={4}
          >
            <div className={classes.progressWrapper}>
              <CircularProgress />
            </div>
          </Grid>
        </div>
      </DashboardLayout>
    } else if (analytics.status === STATUS.READY) {
      return (
        <DashboardLayout title='Dashboard'>
          <div className={classes.root}>
            <Grid
              container
              spacing={4}
            >
              <Grid
                container
                className={classes.shortCutContainer}
                xs={12}
              >
                <ShortCuts details={analytics.data.details} status={analytics.data.verification_progress} />
              </Grid>
              <Grid
                item
                lg={3}
                sm={6}
                xl={3}
                xs={12}
              >
                { analytics.data.verification_progress ? <Confirmation className={classes.item} status={analytics.data.verification_progress || defaultVerificationsTatus} />
                  : <Accept details={analytics.data.details} /> }
              </Grid>
              <Grid
                item
                lg={3}
                sm={6}
                xl={3}
                xs={12}
              >
                <Status className={classes.item} status={analytics.data.status} />
              </Grid>
              <Grid
                item
                lg={3}
                sm={6}
                xl={3}
                xs={12}
              >
                <Budget heading={'Total Items'} count={analytics.data.items.total_items} className={classes.item} />
              </Grid>
              <Grid
                item
                lg={3}
                sm={6}
                xl={3}
                xs={12}
              >
                <Users heading={'Completed Orders'} count={analytics.data.orders.completed_orders} className={classes.item} />
              </Grid>
              {/* <Grid
                item
                lg={3}
                sm={6}
                xl={3}
                xs={12}
              >
                <Progress className={classes.item} status={analytics.data.status} />
              </Grid> */}
              {/* <Grid
                item
                lg={3}
                sm={6}
                xl={3}
                xs={12}
              >
                <Profit className={classes.item} />
              </Grid> */}
              { analytics.data.orders.completed_order || analytics.data.orders.total_open_orders ? <Grid
                item
                lg={4}
                md={6}
                xl={3}
                xs={12}
              >
                <DevicesChart className={classes.item} orders={analytics.data.orders} />
              </Grid> : null}
            </Grid>
          </div>
        </DashboardLayout>
      )
    } else {
      return <div className={classes.progressWrapper}>
        <CircularProgress />
      </div>
    }
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
}

function mapStateToProps (state) {
  return {
    analytics: state.analytics.accountStatus
  }
}

export default compose(
  withStyles(style)
)(connect(mapStateToProps)(Dashboard))
