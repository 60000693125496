export default theme => ({
  root: {
    padding: theme.spacing.unit * 3,
    paddingBottom: 0
  },
  container: {
    height: 'calc( 100vh - 154px )',
    overflow: 'auto',
    position: 'relative',
    width: 'calc(100% + 24px)',
    margin: -12
  },
  gridContainer: {
    width: '100%',
    margin: 0
  },
  content: {
    marginTop: theme.spacing.unit * 2
  },
  progressWrapper: {
    position: 'absolute',
    top: 300,
    left: 0,
    right: 0,
    paddingBottom: '24px',
    display: 'flex',
    justifyContent: 'center',
    zIndex: 10
  },
  pagination: {
    marginTop: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  filterContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
    width: '100%',
    '& > div': {
      width: 180,
      marginRight: 20
    }
  },
  spacer: {
    flexGrow: 1
  },
  firstButton: {
    marginRight: 20
  }
})
