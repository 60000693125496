import React, { Component } from 'react'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { connect } from 'react-redux'
import treeChanges from 'tree-changes'

import { Chart } from 'react-chartjs-2'
import { ThemeProvider } from '@material-ui/styles'
import { chartjs } from './helpers'
import theme from './theme'
import 'react-perfect-scrollbar/dist/css/styles.css'
import './assets/scss/index.scss'

import Routes from './Routes'
import MessageToast from './views/MessageToast'
import { hideAlert, refreshToken } from 'redux/actions'
// import MobileWarning from 'views/Common/MobileWarning'
import { STATUS } from 'redux/constants/index'

import './App.css'
import { CircularProgress } from '@material-ui/core'

const browserHistory = createBrowserHistory()

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw
})

const openUrls = ['/register-member', '/reservation']

class App extends Component {
  // state = {
  //   showStore: false,
  //   isAndroid: false
  // }
  componentDidMount () {
    const { dispatch } = this.props
    dispatch(refreshToken())
    this.interval = setInterval(() => dispatch(refreshToken()), 45 * 60 * 1000)
    if (openUrls.indexOf(window.location.pathname) > -1) {

    }
    // const android = navigator.userAgent.match(/Android/i)
    // const ios = navigator.userAgent.match(/iPhone|iPod/i)
    // if (android) {
    //   this.setState({ showStore: true, isAndroid: true })
    // } else if (ios) {
    //   this.setState({ showStore: true, isAndroid: false })
    // }
  }

  componentWillReceiveProps (nextProps) {
    const { dispatch } = this.props
    const { changedTo } = treeChanges(this.props, nextProps)

    /* istanbul ignore else */
    if (changedTo('user.isAuthenticated', true)) {
      this.interval = setInterval(() => dispatch(refreshToken()), 45 * 60 * 1000)
    }
  }

  onCloseWarning = () => {
    this.setState({ showStore: false, isAndroid: null })
  }

  onClose = () => {
    this.props.dispatch(hideAlert())
  }

  componentWillUnmount () {
    clearInterval(this.interval)
  }

  onLogout = () => {
    clearInterval(this.interval)
  };

  render () {
    // const { showStore, isAndroid } = this.state
    const { user, app } = this.props
    const { message, data, showAlert } = app
    const loadingElement = <div className='loading-page' >
      <CircularProgress />
    </div>
    return (
      <ThemeProvider theme={theme}>
        { !user.isAuthenticated && user.refreshStatus === STATUS.RUNNING ? loadingElement : <Router history={browserHistory}>
          <Routes />
        </Router>}
        { showAlert ? <MessageToast message={message} variant={data.variant} onClose={this.onClose} /> : null}
        {/* <MobileWarning isOpen={showStore} isAndroid={isAndroid} onCancel={this.onCloseWarning} /> */}
        {/* <MessageToast open={false} message={'You are too late ajesh'} variant={'warning'} onClose={() => {}} className='toast' /> */}
      </ThemeProvider>
    )
  }
}

function mapStateToProps (state) {
  return {
    app: state.app.alert,
    user: state.user
  }
}
export default connect(mapStateToProps)(App)
