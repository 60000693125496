import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles, Typography } from '@material-ui/core'
import Modal from 'views/Modal'

const styles = theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 100
  },
  textField: {
    margin: 0,
    width: 'calc( 100% - 80px)',
    marginLeft: 20
  },
  error: {
    color: 'red'
  },
  code: {
    width: 60,
    margin: 0
  }
})

class DeletePartner extends Component {
  render () {
    const { classes, onCancel, isOpen, user, onSubmit } = this.props
    const child = <div className={classes.container}>
      <Typography className={classes.warning} variant='p'>{`Do you want to delete ${user ? user.name : 'station'}?`}</Typography>
    </div>
    return (
      <Modal isOpen={isOpen} title={'Delete Member'} onCancel={onCancel} okText={'Confirm'} onSubmit={onSubmit} child={child} />
    )
  }
}

DeletePartner.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired
}

export default withStyles(styles)(DeletePartner)
