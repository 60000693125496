export default theme => ({
  tabs: {
    paddingBottom: 0,
    paddingTop: 0,
    marginBottom: 20
  },
  tab: {
    minHeight: 48,
    '& span': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around'
    }
  },
  progressWrapper: {
    paddingTop: '48px',
    paddingBottom: '24px',
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute'
  },
  root: {
    padding: theme.spacing.unit * 4
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative'
  },
  count: {
    position: 'absolute',
    right: 20,
    top: 20,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    color: 'red',
    fontWeight: 'bold'
  }
})
