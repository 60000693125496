/**
 * @module Sagas/User
 * @desc User
 */

import { all, put, call, takeLatest } from 'redux-saga/effects'

import { ActionTypes, APIEndpoints } from 'redux/constants/index'
import { request } from 'services/client'
import { getCookie } from 'services/cookie'

/**
 * Signup
 */
export function * addShopLogo ({ payload }) {
  try {
    const { url } = payload
    const shopId = getCookie('shop_id')
    yield call(request, APIEndpoints.ADD_SHOP_LOGO(shopId), {
      method: 'POST',
      payload: {
        logo_url: url
      }
    })
    yield put({
      type: ActionTypes.ADD_SHOP_LOGO_SUCCESS,
      payload: { url }
    })
    yield put({
      type: ActionTypes.SET_SHOP_LOGO,
      payload: url
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.ADD_SHOP_LOGO_FAILURE,
      payload: err
    })
  }
}

export function * getShopDetails () {
  try {
    const shopId = getCookie('shop_id')
    const response = yield call(request, APIEndpoints.GET_SHOP_DETAILS(shopId), {
      method: 'GET'
    })
    yield put({
      type: ActionTypes.GET_SHOP_DETAILS_SUCCESS,
      payload: response
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_SHOP_DETAILS_FAILURE,
      payload: err
    })
  }
}

export function * updateShopSettings ({ payload }) {
  try {
    const { requestObj } = payload
    const shopId = getCookie('shop_id')
    const response = yield call(request, APIEndpoints.UPDATE_SHOP_SETTINGS(shopId), {
      method: 'POST',
      payload: requestObj
    })
    yield put({
      type: ActionTypes.UPDATE_SHOP_SETTINGS_SUCCESS,
      payload: response
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.UPDATE_SHOP_SETTINGS_FAILURE,
      payload: err
    })
  }
}

export function * updateUserSettings ({ payload }) {
  try {
    const { requestObj } = payload
    const response = yield call(request, APIEndpoints.UPDATE_USER_SETTINGS, {
      method: 'POST',
      payload: requestObj
    })
    yield put({
      type: ActionTypes.UPDATE_USER_SETTINGS_SUCCESS,
      payload: response
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.UPDATE_USER_SETTINGS_FAILURE,
      payload: err
    })
  }
}

export function * editShopAddress ({ payload }) {
  try {
    const { requestObj } = payload
    const shopId = getCookie('shop_id')
    const response = yield call(request, APIEndpoints.EDIT_SHOP_ADDRESS(shopId), {
      method: 'POST',
      payload: requestObj
    })
    yield put({
      type: ActionTypes.EDIT_SHOP_ADDRESS_SUCCESS,
      payload: response.address
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.EDIT_SHOP_ADDRESS_FAILURE,
      payload: err
    })
  }
}

export function * getDeliveryDetails () {
  try {
    const shopId = getCookie('shop_id')
    const response = yield call(request, APIEndpoints.GET_DELIVERY_DETAILS(shopId), {
      method: 'GET'
    })
    yield put({
      type: ActionTypes.GET_DELIVERY_DETAILS_SUCCESS,
      payload: response
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_DELIVERY_DETAILS_FAILURE,
      payload: err
    })
  }
}

export function * editDeliverySettings ({ payload }) {
  try {
    const { requestObj } = payload
    const shopId = getCookie('shop_id')
    const response = yield call(request, APIEndpoints.UPDATE_DELIVERY_SETTINGS(shopId), {
      method: 'POST',
      payload: { delivery_option: requestObj }
    })
    yield put({
      type: ActionTypes.UPDATE_DELIVERY_SETTINGS_SUCCESS,
      payload: response.delivery_option
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.UPDATE_DELIVERY_SETTINGS_FAILURE,
      payload: err
    })
  }
}

export function * getPaymentDetails () {
  try {
    const shopId = getCookie('shop_id')
    const response = yield call(request, APIEndpoints.GET_PAYMENT_DETAILS(shopId), {
      method: 'GET'
    })
    yield put({
      type: ActionTypes.GET_PAYMENT_DETAILS_SUCCESS,
      payload: response
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_PAYMENT_DETAILS_FAILURE,
      payload: err
    })
  }
}

export function * editPaymentSettings ({ payload }) {
  try {
    const { requestObj } = payload
    const shopId = getCookie('shop_id')
    const response = yield call(request, APIEndpoints.UPDATE_PAYMENT_SETTINGS(shopId), {
      method: 'POST',
      payload: { payment_option: requestObj }
    })
    yield put({
      type: ActionTypes.UPDATE_PAYMENT_SETTINGS_SUCCESS,
      payload: response.payment_option
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.UPDATE_PAYMENT_SETTINGS_FAILURE,
      payload: err
    })
  }
}

export function * editAcceptStatus ({ payload }) {
  try {
    const { status } = payload
    const shopId = getCookie('shop_id')
    yield call(request, APIEndpoints.CHANGE_ACCEPT_STATUS(shopId), {
      method: 'POST',
      payload: { is_accepting_orders: status }
    })
    yield put({
      type: ActionTypes.CHANGE_ACCEPT_STATUS_SUCCESS,
      payload: { is_accepting_orders: status }
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.CHANGE_ACCEPT_STATUS_FAILURE,
      payload: err
    })
  }
}

export function * changePassword ({ payload }) {
  try {
    const { old, password } = payload
    yield call(request, APIEndpoints.CHANGE_PASSWORD, {
      method: 'POST',
      payload: {
        old_password: old,
        new_password: password
      }
    })
    yield put({
      type: ActionTypes.CHANGE_PASSWORD_SUCCESS
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.CHANGE_PASSWORD_FAILURE,
      payload: err
    })
  }
}

export function * getOrderReport ({ payload }) {
  try {
    const { from, to, status } = payload
    const shopId = getCookie('shop_id')
    const response = yield call(request, APIEndpoints.GET_ORDER_REPORT(shopId, from, to, status), {
      method: 'GET',
      payload: {
      }
    })
    yield put({
      type: ActionTypes.GET_ORDER_REPORT_SUCCESS,
      payload: response
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_ORDER_REPORT_FAILURE,
      payload: err
    })
  }
}

export function * sendPaymentReminder () {
  try {
    const shopId = getCookie('shop_id')
    const response = yield call(request, APIEndpoints.SEND_PAYMENT_REMINDER(shopId), {
      method: 'POST',
      payload: {
      }
    })
    yield put({
      type: ActionTypes.SEND_PAYMENT_REMINDER_SUCCESS,
      payload: response
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.SEND_PAYMENT_REMINDER_FAILURE,
      payload: err
    })
  }
}
/**
 * User Sagas
 */
export default function * root () {
  yield all([
    takeLatest(ActionTypes.ADD_SHOP_LOGO, addShopLogo),
    takeLatest(ActionTypes.GET_SHOP_DETAILS, getShopDetails),
    takeLatest(ActionTypes.UPDATE_SHOP_SETTINGS, updateShopSettings),
    takeLatest(ActionTypes.UPDATE_USER_SETTINGS, updateUserSettings),
    takeLatest(ActionTypes.EDIT_SHOP_ADDRESS, editShopAddress),
    takeLatest(ActionTypes.GET_DELIVERY_DETAILS, getDeliveryDetails),
    takeLatest(ActionTypes.UPDATE_DELIVERY_SETTINGS, editDeliverySettings),
    takeLatest(ActionTypes.GET_PAYMENT_DETAILS, getPaymentDetails),
    takeLatest(ActionTypes.UPDATE_PAYMENT_SETTINGS, editPaymentSettings),
    takeLatest(ActionTypes.CHANGE_ACCEPT_STATUS, editAcceptStatus),
    takeLatest(ActionTypes.CHANGE_PASSWORD, changePassword),
    takeLatest(ActionTypes.GET_ORDER_REPORT, getOrderReport),
    takeLatest(ActionTypes.SEND_PAYMENT_REMINDER, sendPaymentReminder)
  ])
}
