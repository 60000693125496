export default {
  base_distance: {
    presence: { allowEmpty: false, message: 'is required' }
  },
  base_distance_price: {
    presence: { allowEmpty: false, message: 'is required' }
  },
  price_per_km: {
    presence: { allowEmpty: false, message: 'is required' }
  },
  dispatch_time: {
    presence: { allowEmpty: false, message: 'is required' }
  },
  distance: {
    presence: { allowEmpty: false, message: 'is required' }
  },
  max_weight: {
    presence: { allowEmpty: true }
  },
  min_price: {
    presence: { allowEmpty: false, message: 'is required' }
  }
}
