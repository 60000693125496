/**
 * @module Sagas/User
 * @desc User
 */

import { all, put, call, takeLatest } from 'redux-saga/effects'

import { ActionTypes, APIEndpoints } from 'redux/constants/index'
import { request } from 'services/client'
import { getCookie } from 'services/cookie'

/**
 * Signup
 */

export function * getActiveReservations ({ payload }) {
  try {
    const { limit, skip, searchstring, status } = payload
    const shopId = getCookie('shop_id')
    const response = yield call(request, APIEndpoints.GET_ACTIVE_RESERVATIONS(shopId, limit, skip, status, searchstring), {
      method: 'GET'
    })
    yield put({
      type: ActionTypes.GET_ACTIVE_RESERVATIONS_SUCCESS,
      payload: response.reservations
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_ACTIVE_RESERVATIONS_FAILURE,
      payload: err
    })
  }
}

export function * getReservations ({ payload }) {
  try {
    const { limit, skip, searchstring, status, date } = payload
    const shopId = getCookie('shop_id')
    const response = yield call(request, APIEndpoints.GET_RESERVATIONS(date, shopId, limit, skip, status, searchstring), {
      method: 'GET'
    })
    yield put({
      type: ActionTypes.GET_RESERVATIONS_SUCCESS,
      payload: response.reservations
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_RESERVATIONS_FAILURE,
      payload: err
    })
  }
}

export function * checkoutReservation ({ payload }) {
  try {
    const { id } = payload
    const shopId = getCookie('shop_id')
    const response = yield call(request, APIEndpoints.CHECKOUT_RESERVATION(shopId), {
      method: 'POST',
      payload: {
        reservation_id: id
      }
    })
    yield put({
      type: ActionTypes.GET_RESERVATION_COUNT,
      payload: {}
    })
    yield put({
      type: ActionTypes.CHECKOUT_RESERVATION_SUCCESS,
      payload: response.reservation
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.CHECKOUT_RESERVATION_FAILURE,
      payload: err.message
    })
  }
}

export function * checkinReservation ({ payload }) {
  try {
    const { id } = payload
    const shopId = getCookie('shop_id')
    const response = yield call(request, APIEndpoints.CHECKIN_RESERVATION(shopId), {
      method: 'POST',
      payload: {
        reservation_id: id
      }
    })
    yield put({
      type: ActionTypes.GET_RESERVATION_COUNT,
      payload: {}
    })
    yield put({
      type: ActionTypes.CHECKIN_RESERVATION_SUCCESS,
      payload: response.reservation
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.CHECKIN_RESERVATION_FAILURE,
      payload: err.message
    })
  }
}

export function * getReservationCount ({ payload }) {
  try {
    const shopId = getCookie('shop_id')
    const response = yield call(request, APIEndpoints.GET_RESERVATION_COUNT(shopId), {
      method: 'GET',
      payload: {
      }
    })
    yield put({
      type: ActionTypes.GET_RESERVATION_COUNT_SUCCESS,
      payload: response.currently_in
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_RESERVATION_COUNT_FAILURE,
      payload: err.message
    })
  }
}

export function * getReservationDetails ({ payload }) {
  try {
    const { id, shopId } = payload
    const response = yield call(request, APIEndpoints.GET_RESERVATION_DETAILS(shopId, id), {
      method: 'GET',
      payload: {
      }
    })
    yield put({
      type: ActionTypes.GET_RESERVATION_DETAILS_SUCCESS,
      payload: response.reservation
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_RESERVATION_DETAILS_FAILURE,
      payload: err.message
    })
  }
}

/**
 * User Sagas
 */
export default function * root () {
  yield all([
    takeLatest(ActionTypes.GET_ACTIVE_RESERVATIONS, getActiveReservations),
    takeLatest(ActionTypes.GET_RESERVATIONS, getReservations),
    takeLatest(ActionTypes.CHECKIN_RESERVATION, checkinReservation),
    takeLatest(ActionTypes.CHECKOUT_RESERVATION, checkoutReservation),
    takeLatest(ActionTypes.GET_RESERVATION_COUNT, getReservationCount),
    takeLatest(ActionTypes.GET_RESERVATION_DETAILS, getReservationDetails)
  ])
}
