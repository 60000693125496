export default theme => ({
  root: {
    padding: theme.spacing.unit * 3,
    paddingTop: theme.spacing.unit
  },
  item: {
    height: '100%'
  },
  tab: {
    minHeight: 48,
    width: 120,
    '& span': {
      display: 'flex',
      flexDirection: 'row',
      '& svg': {
        marginRight: 10
      }
    }
  },
  progressWrapper: {
    paddingTop: '48px',
    paddingBottom: '24px',
    display: 'flex',
    justifyContent: 'center'
  }
})
