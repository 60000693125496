/**
 * @module Sagas/User
 * @desc User
 */

import { all, put, call, takeLatest } from 'redux-saga/effects'

import { ActionTypes, APIEndpoints } from 'redux/constants/index'
import { request } from 'services/client'
import { getCookie } from 'services/cookie'

/**
 * Signup
 */
export function * getCadets ({ payload }) {
  try {
    const { limit, skip, searchstring } = payload
    const shopId = getCookie('shop_id')
    const response = yield call(request, APIEndpoints.GET_CADETS(shopId, limit, skip, searchstring), {
      method: 'GET'
    })
    yield put({
      type: ActionTypes.GET_CADETS_SUCCESS,
      payload: response.police
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_CADETS_FAILURE,
      payload: err
    })
  }
}

export function * creatPartner ({ payload }) {
  try {
    const shopId = getCookie('shop_id')
    const response = yield call(request, APIEndpoints.CREATE_PARTNER(shopId), {
      method: 'POST',
      payload: { phone_with_code: `+91${payload.phone}`, member_id: payload.memberId, branch_id: payload.branchId }
    })
    if (response.status) {
      yield put({
        type: ActionTypes.CREATE_PARTNER_SUCCESS,
        payload: response
      })
    } else {
      yield put({
        type: ActionTypes.CREATE_PARTNER_FAILURE,
        payload: response
      })
    }
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.CREATE_PARTNER_FAILURE,
      payload: err
    })
  }
}

export function * editPartner ({ payload }) {
  try {
    const shopId = getCookie('shop_id')
    const response = yield call(request, APIEndpoints.EDIT_PARTNER(shopId, payload.id), {
      method: 'POST',
      payload: { phone_with_code: `+91${payload.phone}`, member_id: payload.memberId, branch_id: payload.branchId }
    })
    if (response.status) {
      yield put({
        type: ActionTypes.EDIT_PARTNER_SUCCESS,
        payload: response
      })
    } else {
      yield put({
        type: ActionTypes.EDIT_PARTNER_FAILURE,
        payload: response
      })
    }
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.EDIT_PARTNER_FAILURE,
      payload: err
    })
  }
}

export function * deletePartner ({ payload }) {
  try {
    const shopId = getCookie('shop_id')
    const response = yield call(request, APIEndpoints.DELETE_PARTNER(shopId), {
      method: 'DELETE',
      payload: { selected_user_id: payload.id }
    })
    yield put({
      type: ActionTypes.DELETE_PARTNER_SUCCESS,
      payload: response
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.DELETE_PARTNER_FAILURE,
      payload: err
    })
  }
}

export function * getStation ({ payload }) {
  try {
    const { limit, skip, searchstring } = payload
    const shopId = getCookie('shop_id')
    const response = yield call(request, APIEndpoints.GET_STATIONS(shopId, limit, skip, searchstring), {
      method: 'GET'
    })
    yield put({
      type: ActionTypes.GET_STATIONS_SUCCESS,
      payload: response.branches
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_STATIONS_FAILURE,
      payload: err
    })
  }
}

export function * creatStation ({ payload }) {
  try {
    const shopId = getCookie('shop_id')
    const response = yield call(request, APIEndpoints.CREATE_STATION(shopId), {
      method: 'POST',
      payload: { name: payload.name }
    })
    if (response.status) {
      yield put({
        type: ActionTypes.CREATE_STATION_SUCCESS,
        payload: response
      })
    } else {
      yield put({
        type: ActionTypes.CREATE_STATION_FAILURE,
        payload: response
      })
    }
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.CREATE_STATION_FAILURE,
      payload: err
    })
  }
}

export function * editStation ({ payload }) {
  try {
    const shopId = getCookie('shop_id')
    const response = yield call(request, APIEndpoints.EDIT_STATION(shopId, payload.id), {
      method: 'POST',
      payload: { name: payload.name }
    })
    if (response.status) {
      yield put({
        type: ActionTypes.EDIT_STATION_SUCCESS,
        payload: response
      })
    } else {
      yield put({
        type: ActionTypes.EDIT_STATION_FAILURE,
        payload: response
      })
    }
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.EDIT_STATION_FAILURE,
      payload: err
    })
  }
}

export function * deleteStation ({ payload }) {
  try {
    const shopId = getCookie('shop_id')
    const response = yield call(request, APIEndpoints.DELETE_STATION(shopId), {
      method: 'DELETE',
      payload: { branch_id: payload.id }
    })
    yield put({
      type: ActionTypes.DELETE_STATION_SUCCESS,
      payload: response
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.DELETE_STATION_FAILURE,
      payload: err
    })
  }
}

export function * getCadetCount ({ payload }) {
  try {
    const shopId = getCookie('shop_id')
    const response = yield call(request, APIEndpoints.GET_CADET_COUNT(shopId), {
      method: 'GET'
    })
    yield put({
      type: ActionTypes.GET_CADET_COUNT_SUCCESS,
      payload: response.police.count
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.GET_CADET_COUNT_FAILURE,
      payload: err
    })
  }
}

/**
 * User Sagas
 */
export default function * root () {
  yield all([
    takeLatest(ActionTypes.GET_CADETS, getCadets),
    takeLatest(ActionTypes.CREATE_PARTNER, creatPartner),
    takeLatest(ActionTypes.EDIT_PARTNER, editPartner),
    takeLatest(ActionTypes.DELETE_PARTNER, deletePartner),
    takeLatest(ActionTypes.GET_STATIONS, getStation),
    takeLatest(ActionTypes.CREATE_STATION, creatStation),
    takeLatest(ActionTypes.EDIT_STATION, editStation),
    takeLatest(ActionTypes.DELETE_STATION, deleteStation),
    takeLatest(ActionTypes.GET_CADET_COUNT, getCadetCount)
  ])
}
