import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles, TextField } from '@material-ui/core'
import Modal from 'views/Modal'
import { showAlert, changePassword } from 'redux/actions'
import { STATUS } from 'redux/constants'
import treeChanges from 'tree-changes'
import _ from 'lodash'
import { checkPasswordValidity } from 'services/utility'

const styles = theme => ({
  container: {

  },
  textField: {
    width: '100%'
  }
})

class ContactDetailsModal extends Component {
    state = {
      old: '',
      password: '',
      rePassword: ''
    }

    componentWillReceiveProps = newProps => {
      const { changePasswordStatus, onCancel } = _.cloneDeep(newProps)
      const { changedTo } = treeChanges(this.props, newProps)
      if (changedTo('changePasswordStatus.status', STATUS.ERROR)) {
        this.props.dispatch(showAlert(changePasswordStatus.message, { variant: 'error' }))
      } else if (changedTo('changePasswordStatus.status', STATUS.READY)) {
        onCancel()
        this.props.dispatch(showAlert('Updated Password successfully', { variant: 'success' }))
      }
    }

    handleChange = (field, value) => {
      this.setState({ [field]: value })
    }

    submit = () => {
      const { old, password, rePassword } = this.state
      const { dispatch } = this.props
      const isValidPassword = checkPasswordValidity(password)
      if (!isValidPassword) {
        const warning = 'Passwords must contain at least six characters, including uppercase, lowercase letters and numbers.'
        dispatch(showAlert(warning, { variant: 'error' }))
        return
      }
      if (old && password && rePassword) {
        if (password !== rePassword) {
          dispatch(showAlert('Confirm password does not match', { variant: 'error' }))
        } else if (old === password) {
          dispatch(showAlert('New Password should not be the old one', { variant: 'error' }))
        } else if (password.length < 6) {
          dispatch(showAlert('Password should contain atleast six characters', { variant: 'error' }))
        } else {
          dispatch(changePassword(old, password))
        }
      } else {
        dispatch(showAlert('All fields are mandatory', { variant: 'error' }))
      }
    }

    render () {
      const { classes, onCancel, isOpen } = this.props
      const { old, password, rePassword } = this.state
      const child = <div className={classes.container}>
        <TextField
          className={classes.textField}
          label={'Old Password'}
          margin='dense'
          type='password'
          onChange={(e) => this.handleChange('old', e.target.value)}
          value={old}
          variant='outlined'
        />
        <TextField
          className={classes.textField}
          label={'New Password'}
          margin='dense'
          type='password'
          onChange={(e) => this.handleChange('password', e.target.value)}
          value={password}
          variant='outlined'
        />
        <TextField
          className={classes.textField}
          label={'Confirm Password'}
          margin='dense'
          type='password'
          onChange={(e) => this.handleChange('rePassword', e.target.value)}
          value={rePassword}
          variant='outlined'
        />
      </div>
      return (
        <Modal isOpen={isOpen} title={'Change Password'} onCancel={onCancel} okText={'Done'} onSubmit={this.submit} child={child} />
      )
    }
}

ContactDetailsModal.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired
}

function mapStateToProps (state) {
  return {
    changePasswordStatus: state.settings.changePassword
  }
}

export default withStyles(styles)(connect(mapStateToProps)(ContactDetailsModal))
