import React, { Component } from 'react'

// Externals
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Typography, withStyles } from '@material-ui/core'
import { Assignment as AssignmentIcon } from '@material-ui/icons'
import { Paper } from 'components'
import styles from './styles'

class Status extends Component {
  render () {
    const { classes, className, status, ...rest } = this.props

    const rootClassName = classNames(classes.root, className)

    return (
      <Paper
        {...rest}
        className={rootClassName}
      >
        <div className={classes.content}>
          <div className={classes.details}>
            <Typography
              className={classes.title}
              variant='body2'
            >
              {status.status}
            </Typography>
            <Typography
              className={classes.value}
              variant='h5'
            >
              {status.status_details}
            </Typography>
          </div>
          <div className={classes.iconWrapper}>
            <AssignmentIcon className={classes.icon} />
          </div>
        </div>
      </Paper>
    )
  }
}

Status.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(Status)
