export default theme => ({
  root: {},
  field: {
    margin: theme.spacing.unit * 3,
    '& > div': {
      width: `calc( 50% - ${theme.spacing.unit * 1.5}px )`,
      '&:first-child': {
        marginRight: theme.spacing.unit * 3
      }
    }
  },
  textField: {
    width: `calc( 50% - ${theme.spacing.unit * 1.5}px )`,
    '&:first-child': {
      marginRight: theme.spacing.unit * 3
    }
  },
  portletFooter: {
    paddingLeft: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    display: 'flex'
  },
  footerError: {
    flex: '1',
    color: 'red',
    overflow: 'hidden',
    lineHeight: '36px',
    padding: '0px 20px'
  }
})
