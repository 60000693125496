import React, { Component } from 'react'

// Externals
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { Typography, withStyles } from '@material-ui/core'

import { Paper } from 'components'

// Component styles
import styles from './style'

class Confirmation extends Component {
  render () {
    const { classes, className, status, ...rest } = this.props

    const rootClassName = classNames(classes.root, className)

    return (
      <Paper
        {...rest}
        className={rootClassName}
      >
        <div className={classes.content}>
          <div className={classes.details}>
            <Typography
              className={classes.title}
              variant='body2'
            >
              Address Added
            </Typography>
            <img className={classes.statusIcon} src={status.added_address ? 'images/check.png' : 'images/cross.png'} alt='check-status' />
          </div>
          <div className={classes.details}>
            <Typography
              className={classes.title}
              variant='body2'
            >
              Shop Logo
            </Typography>
            <img className={classes.statusIcon} src={status.added_logo ? 'images/check.png' : 'images/cross.png'} alt='check-status' />
          </div>
          <div className={classes.details}>
            <Typography
              className={classes.title}
              variant='body2'
            >
              First Product
            </Typography>
            <img className={classes.statusIcon} src={status.first_product ? 'images/check.png' : 'images/cross.png'} alt='check-status' />
          </div>
          <div className={classes.details}>
            <Typography
              className={classes.title}
              variant='body2'
            >
              Delivery Options
            </Typography>
            <img className={classes.statusIcon} src={status.delivery_options ? 'images/check.png' : 'images/cross.png'} alt='check-status' />
          </div>
        </div>
      </Paper>
    )
  }
}

Confirmation.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(Confirmation)
