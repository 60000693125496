import React, { Component } from 'react'

// Externals
import classNames from 'classnames'
import PropTypes from 'prop-types'

import compose from 'recompose/compose'
import { connect } from 'react-redux'

import { Typography, withStyles, Switch } from '@material-ui/core'

import { Paper } from 'components'

// Component styles
import style from './style'
import treeChanges from 'tree-changes'
import { STATUS } from 'redux/constants'
import { showAlert, changeAcceptStatus } from 'redux/actions'

class Accept extends Component {
  componentWillReceiveProps = newProps => {
    const { changedTo } = treeChanges(this.props, newProps)
    if (changedTo('editAcceptStatus.status', STATUS.ERROR)) {
      this.props.dispatch(showAlert(newProps.editAcceptStatus.message, { variant: 'error' }))
    } else if (changedTo('editAcceptStatus.status', STATUS.READY)) {
      this.props.dispatch(showAlert('Changed Status successfully', { variant: 'success' }))
    }
  }

  handleAcceptStatusChange = event => {
    const { checked } = event.target
    this.props.dispatch(changeAcceptStatus(checked))
  }

  render () {
    const { classes, className, details, ...rest } = this.props

    const rootClassName = classNames(classes.root, className)

    return (
      <Paper
        {...rest}
        className={rootClassName}
      >
        <div className={classes.acceptStatus}>
          <Typography className={classes.statusTitle} variant='body1'>Accept Orders</Typography>
          <Switch
            className={classes.switch}
            checked={details.is_accepting_orders}
            onChange={this.handleAcceptStatusChange}
            value='checkedA'
            // disabled={isLoading}
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        </div>
      </Paper>
    )
  }
}

Accept.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
}

function mapStateToProps (state) {
  return {
    editAcceptStatus: state.settings.editAcceptStatus
  }
}

export default compose(
  withStyles(style)
)(connect(mapStateToProps)(Accept))
