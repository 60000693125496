// @flow
/**
 * @module Actions/App
 * @desc App Actions
 */

import { createActions } from 'redux-actions'

import { ActionTypes } from 'redux/constants/index'

export const { getCadets, createPartner, editPartner, deletePartner, getStations, createStation, editStation, deleteStation, getCadetCount } = createActions({
  [ActionTypes.GET_CADETS]: (skip, limit, searchstring) => ({ skip, limit, searchstring }),
  [ActionTypes.CREATE_PARTNER]: (phone, memberId, branchId) => ({ phone, memberId, branchId }),
  [ActionTypes.EDIT_PARTNER]: (phone, memberId, branchId, id) => ({ phone, memberId, id, branchId }),
  [ActionTypes.DELETE_PARTNER]: (id) => ({ id }),
  [ActionTypes.GET_STATIONS]: (skip, limit, searchstring) => ({ skip, limit, searchstring }),
  [ActionTypes.CREATE_STATION]: (name) => ({ name }),
  [ActionTypes.EDIT_STATION]: (name, id) => ({ name, id }),
  [ActionTypes.DELETE_STATION]: (id) => ({ id }),
  [ActionTypes.GET_CADET_COUNT]: (id) => ({ id })
})
