import React, { Component } from 'react'

// Externals
import classNames from 'classnames'
import PropTypes from 'prop-types'
import PerfectScrollbar from 'react-perfect-scrollbar'
import compose from 'recompose/compose'
import treeChanges from 'tree-changes'
import { connect } from 'react-redux'
import moment from 'moment'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  withStyles,
  Button,
  CircularProgress,
  Typography,
  TextField
} from '@material-ui/core'

import { getReservations, checkoutReservation, checkinReservation, showAlert } from 'redux/actions'
import { Portlet, PortletContent, SearchInput } from 'components'
import styles from './style'
import { ReservationConfirmation } from '..'
import { STATUS } from 'redux/constants'

const itemsPerPage = 20

class Reservations extends Component {
  state = {
    page: 0,
    date: new Date()
  }

  componentDidMount = () => {
    this.onClickSearch()
  }

  componentWillReceiveProps = newProps => {
    const { changedTo } = treeChanges(this.props, newProps)
    if (changedTo('reservations.status', STATUS.READY)) {
      this.setState({ isLoading: false })
    }
    if (changedTo('checkout.status', STATUS.READY)) {
      const successMsg = 'Successfully marked as checked out'
      this.props.dispatch(showAlert(successMsg, { variant: 'success' }))
      this.setState({ showConfirmation: false, selectedReservation: null, type: '' })
    } else if (changedTo('checkout.status', STATUS.ERROR)) {
      this.props.dispatch(showAlert(newProps.checkout.message, { variant: 'error' }))
    }
    if (changedTo('checkin.status', STATUS.READY)) {
      const successMsg = 'Successfully marked as checked in'
      this.props.dispatch(showAlert(successMsg, { variant: 'success' }))
      this.setState({ showConfirmation: false, selectedReservation: null, type: '' })
    } else if (changedTo('checkin.status', STATUS.ERROR)) {
      this.props.dispatch(showAlert(newProps.checkout.message, { variant: 'error' }))
    }
  }

  keyPressed = event => {
    if (event.key === 'Enter') {
      this.onClickSearch()
    }
  }

  handleDateChange = event => {
    const { value } = event.target
    this.setState({ date: new Date(value), page: 0 }, this.onClickSearch)
  }

  onChangeSearchString = event => {
    const { value } = event.target
    this.setState({ searchString: value })
  }

  refreshPage = () => {
    const { isLoading, page, searchString, date } = this.state
    const nextIndex = (page + 1) * itemsPerPage
    if (!isLoading) {
      const timeStamp = this.getUTCTime(date)
      this.setState({ isLoading: true }, () => {
        this.props.dispatch(getReservations(timeStamp, 0, nextIndex, searchString))
      })
    }
  }

  onClickSearch = () => {
    const { page, date, searchString } = this.state
    const timeStamp = this.getUTCTime(date)
    this.setState({ isLoading: true })
    this.props.dispatch(getReservations(timeStamp, page, itemsPerPage, searchString))
  }

  showCheckinConfirmation = item => {
    this.setState({ showConfirmation: true, selectedReservation: item, type: 'checkin' })
  }

  showCheckoutConfirmation = (item) => {
    this.setState({ showConfirmation: true, selectedReservation: item, type: 'checkout' })
  }

  hideCheckoutConfirmation = () => {
    this.setState({ showConfirmation: false, selectedReservation: null, type: '' })
  }

  confirmAction = () => {
    const { type } = this.state
    if (type === 'checkin') {
      this.props.dispatch(checkinReservation(this.state.selectedReservation.id))
    } else {
      this.props.dispatch(checkoutReservation(this.state.selectedReservation.id))
    }
  }

  onReachedBottom = () => {
    const { reservations } = this.props
    const { isLoading, page, searchString, date } = this.state
    const nextIndex = (page + 1) * itemsPerPage
    const timeStamp = this.getUTCTime(date)
    if (!isLoading && !reservations.reachedEnd) {
      this.setState({ page: page + 1, isLoading: true }, () => {
        this.props.dispatch(getReservations(timeStamp, nextIndex, itemsPerPage, searchString))
      })
    }
  }

  getUTCTime = date => {
    const timeStamp = moment(date).startOf('day').valueOf()
    return timeStamp
  }

  getReservationList = () => {
    const { reservations, classes, className } = this.props
    const { showConfirmation, selectedReservation, type } = this.state
    const rootClassName = classNames(classes.root, className)

    if (reservations.status === STATUS.RUNNING && reservations.data.length === 0) {
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress />
        </div>
      )
    }

    if (reservations.data.length === 0) {
      return (
        <Typography variant='h6'>No reservations found.</Typography>
      )
    }
    return (
      <React.Fragment>
        {reservations.status === STATUS.RUNNING ? <div className={classes.progressWrapper}>
          <CircularProgress />
        </div> : null}
        <Portlet className={rootClassName}>
          <PortletContent noPadding>
            <PerfectScrollbar onYReachEnd={this.onReachedBottom}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align='left'>Code</TableCell>
                    <TableCell align='left'>Name</TableCell>
                    <TableCell align='left'>Member ID</TableCell>
                    <TableCell align='left'>Date</TableCell>
                    <TableCell align='left'>Slot</TableCell>
                    <TableCell align='left' />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reservations.data
                    .map(reservation => (
                      <TableRow
                        className={classes.tableRow}
                        hover
                        key={reservation.id}
                      >
                        <TableCell className={classes.tableCell}>
                          {reservation.code}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {reservation.name}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {reservation.member_id}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {moment(reservation.start_ts).format('DD/MM/YYYY')}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {`${moment(reservation.start_ts).format('hh:mm A')} - ${moment(reservation.end_ts).format('hh:mm A')}`}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          {reservation.status === 'reserved' ? <Button color='primary' size='small' variant='contained' onClick={() => this.showCheckinConfirmation(reservation)} > Checkin </Button> : null }
                          {reservation.status === 'checked_in' ? <Button color='primary' size='small' variant='contained' onClick={() => this.showCheckoutConfirmation(reservation)} > Checkout </Button> : null }
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </PerfectScrollbar>
          </PortletContent>
          <ReservationConfirmation isOpen={showConfirmation} type={type} reservation={selectedReservation} onCancel={this.hideCheckoutConfirmation} onSubmit={this.confirmAction} />
        </Portlet>
      </React.Fragment>

    )
  }

  render () {
    const { classes } = this.props
    const { date, searchString } = this.state
    const formattedDate = moment(date).format('YYYY-MM-DD')
    console.log('formattedDate', formattedDate)
    return (
      <React.Fragment>
        <div className={classes.filterContainer} >
          <SearchInput
            className={classes.searchInput}
            placeholder='Search product'
            value={searchString}
            onKeyPress={this.keyPressed}
            onChange={this.onChangeSearchString}
          />
          <Button color='primary' size='small' variant='outlined' onClick={this.onClickSearch} >
            Search
          </Button>
          <Button color='primary' size='small' variant='outlined' className={classes.refreshButton} onClick={this.refreshPage} >
            Refresh
          </Button>
          <span className={classes.spacer} />
          <div className={classes.dateContainer} >
            <TextField
              id='date'
              label='Slot Date'
              type='date'
              value={formattedDate}
              onChange={this.handleDateChange}
              className={classes.textField}
              InputLabelProps={{
                shrink: true
              }}
            />
          </div>
        </div>
        {this.getReservationList()}
      </React.Fragment>
    )
  }
}

Reservations.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
}

function mapStateToProps (state) {
  return {
    reservations: state.reservations.reservations,
    checkout: state.reservations.checkout,
    checkin: state.reservations.checkin
  }
}

export default compose(
  withStyles(styles)
)(connect(mapStateToProps)(Reservations))
