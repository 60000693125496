import React, { Component } from 'react'

import PropTypes from 'prop-types'
import classNames from 'classnames'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import _ from 'lodash'

import { Typography, withStyles, Button } from '@material-ui/core'
import { Portlet, PortletContent, PortletFooter } from 'components'
import style from './style'
import { getCookie } from 'services/cookie'
import { APIEndpoints } from 'redux/constants'
import { showAlert, addShopLogo } from 'redux/actions'
import ChangeAddress from '../ChangeAddress'

class ShopAddress extends Component {
  state = {
    showAddressChange: false
  }

  handleImageChange = async (e) => {
    e.preventDefault()
    const file = e.target.files[0]
    let data = new FormData()
    data.append('image', file, file.name)
    const authToken = getCookie('auth_token')
    const token = `Bearer ${authToken}`
    const shopId = getCookie('shop_id')
    const url = APIEndpoints.UPLOAD_SHOP_LOGO(shopId)
    const params = {
      method: 'POST',
      body: data,
      headers: {
        'Authorization': token
      }
    }
    this.setState({ isUploadingImage: true })
    fetch(url, params).then(async response => {
      if (response.status > 299) {
        this.props.dispatch(showAlert('Image upload failed. Please try again', { variant: 'error' }))
        this.setState({ isUploadingImage: false })
      } else {
        const result = await response.json()
        const imageUrl = result.url
        this.props.dispatch(addShopLogo(imageUrl))
      }
    }, () => {
      this.setState({ isUploadingImage: false })
      this.props.dispatch(showAlert('Image upload failed. Please try again', { variant: 'error' }))
    })
  }

  onClickFileUpload = event => {
    event.stopPropagation()
    event.target.value = ''
  }

  showEditAddress = () => {
    this.setState({ showAddressChange: true })
  }

  onHideAddressChange = () => {
    this.setState({ showAddressChange: false })
  }

  render () {
    const { classes, className, shopDetails, ...rest } = this.props
    const { shop } = shopDetails
    const { showAddressChange } = this.state
    const rootClassName = classNames(classes.root, className)

    return (
      <Portlet
        {...rest}
        className={rootClassName}
      >
        <PortletContent>
          <div className={classes.details}>
            {shop.address ? <div className={classes.info}>
              <Typography variant='h2'>{shop.address.formatted_address}</Typography>
              <Typography
                className={classes.locationText}
                variant='body1'
              >
                {shop.address.address}
              </Typography>
              <Typography
                className={classes.dateText}
                variant='body1'
              >
                {shop.address.landmark}
              </Typography>
              <Typography
                className={classes.dateText}
                variant='body1'
              >
                {shop.address.locality}
              </Typography>
            </div> : null }
          </div>
        </PortletContent>
        <PortletFooter>
          <Button
            className={classes.uploadButton}
            color='primary'
            variant='outlined'
            onClick={this.showEditAddress}
          >
            Update Address
          </Button>
        </PortletFooter>
        {shop.address ? <ChangeAddress isOpen={showAddressChange} address={_.cloneDeep(shop.address)} onSubmit={this.onHideAddressChange} onCancel={this.onHideAddressChange} /> : null }
      </Portlet>
    )
  }
}

ShopAddress.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
}

function mapStateToProps (state) {
  return {
    shopDetails: state.settings.shopDetails
  }
}

export default compose(
  withStyles(style)
)(connect(mapStateToProps)(ShopAddress))
