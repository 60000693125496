import React, { Component } from 'react'

// Externals
import PropTypes from 'prop-types'
import treeChanges from 'tree-changes'
import { connect } from 'react-redux'
import _ from 'lodash'
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete'
import { CircularProgress, Typography, withStyles } from '@material-ui/core'

// Component styles
import Modal from 'views/Modal'
import { STATUS } from 'redux/constants'
import NewAddress from 'views/SignUp/components/NewAddress'
import schema from './schema'
import validate from 'validate.js'
import { getGeoLocation, editShopAddress, showAlert } from 'redux/actions/index'
import styles from './style'

class CreateProduct extends Component {
  constructor (props) {
    super(props)
    this.state = {
      values: props.address,
      errors: { },
      touched: {}
    }
  }

  componentWillReceiveProps = newProps => {
    const { changed, changedTo } = treeChanges(this.props, newProps)
    if (changed('address')) {
      this.setState({ value: newProps.address })
    }
    if (changedTo('editAddress.status', STATUS.READY)) {
      this.props.onSubmit()
    } else if (changedTo('editAddress.status', STATUS.ERROR)) {
      this.props.dispatch(showAlert('Failed to update shop address', { variant: 'error' }))
    }
  }

  getUpdatedCenter = value => {
    const location = `latlng=${value.lat()},${value.lng()}`
    let { values } = this.state
    values.coordinates.lat = value.lat()
    values.coordinates.long = value.lng()
    this.setState({ values })
    this.props.dispatch(getGeoLocation(location))
  }

  onSelectLocation = location => {
    geocodeByPlaceId(location.place_id).then(item => {
      if (item && item.length && item[0].geometry) {
        this.currentLocation = item[0]
        const latLongData = item[0].geometry.location
        const coordinates = {
          lat: latLongData.lat(),
          long: latLongData.lng()
        }
        this.handleFieldChange('coordinates', coordinates)
      }
    })
  }

  onClearCurrentLocation = () => {
    this.handleFieldChange('coordinates', { })
  }

  validateShopAddressForm = _.debounce(() => {
    const { address } = this.state

    const newState = { ...this.state }
    const errors = validate(address, schema)
    newState.errors = errors || {}
    newState.isShopAddressValid = !errors
    this.setState(newState)
  }, 300);

  handleFieldChange = (key, value) => {
    const newState = { ...this.state }
    newState.submitShopAddressError = null
    const field = key === 'level1' ? 'address' : key === 'level2' ? 'landmark' : key
    newState.values[field] = value
    newState.touched[field] = true

    this.setState(newState, this.validateShopAddressForm)
  }

  submitAddressDetails = () => {
    const { location } = this.props
    const { values } = this.state
    let lat = values.coordinates.lat
    let lng = values.coordinates.long
    let locality = values.locality
    if (location && location.data && location.data.geometry) {
      const { data } = location
      lat = data.geometry.location.lat
      lng = data.geometry.location.lng
      const selectedLocality = data.address_components.filter(item => item.types.find(type => type === 'locality'))
      if (selectedLocality && selectedLocality.length) {
        locality = selectedLocality[0].long_name || selectedLocality[0].short_name
      }
    } else if (this.currentLocation && this.currentLocation.geometry && this.currentLocation.geometry.location) {
      lat = this.currentLocation.geometry.location.lat()
      lng = this.currentLocation.geometry.location.lng()
      const selectedLocality = this.currentLocation.address_components.filter(item => item.types.find(type => type === 'locality'))
      if (selectedLocality && selectedLocality.length) {
        locality = selectedLocality[0].long_name || selectedLocality[0].short_name
      }
    }
    const address = {
      'address': values.address,
      'locality': locality,
      'landmark': values.landmark,
      'coordinates': {
        'lat': parseFloat(lat),
        'long': parseFloat(lng)
      }
    }
    const { dispatch } = this.props
    dispatch(editShopAddress({ address }))
  }

  render () {
    const { classes, isOpen, onCancel } = this.props
    const { isLoading, submitError, values } = this.state
    const { coordinates, address, landmark } = values
    const formattedCoordinates = {
      lat: coordinates.lat,
      lng: coordinates.long
    }
    const child = <div className={classes.container}>
      <div className={classes.fields}>
        {/* {coordinates && coordinates.lat ? <Button className={classes.clearLocationButton} variant='outline' color='primary' onClick={this.onClearCurrentLocation}>Clear Current Location</Button> : null } */}
        {!coordinates || !coordinates.lat ? (<GooglePlacesAutocomplete
          placeholder='Search Location'
          onSelect={this.onSelectLocation}
        />) : null}
        {/* {!coordinates || !coordinates.lat ? (
            <Button className={classes.locationButton} color='primary' onClick={this.onSelectCurrentLocation}>Use Current Location</Button>
          ) : null} */}
        {coordinates && coordinates.lat ? <NewAddress level1={address} level2={landmark} latlng={formattedCoordinates} handleFieldChange={this.handleFieldChange} getUpdatedCenter={this.getUpdatedCenter} /> : null}
      </div>
      {submitError && (
        <Typography className={classes.submitError} variant='body2' > {submitError} </Typography>
      )}
      {isLoading ? (
        <CircularProgress className={classes.progress} />
      ) : null}
    </div>

    return (
      <Modal isOpen={isOpen} title={'EDIT ADDRESS'} onCancel={onCancel} okText={'Change'} onSubmit={this.submitAddressDetails} child={child} width={700} />
    )
  }
}

CreateProduct.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  products: PropTypes.object.isRequired
}

function mapStateToProps (state) {
  return {
    location: state.address.location,
    editAddress: state.settings.editAddress
  }
}

export default withStyles(styles)(connect(mapStateToProps)(CreateProduct))
