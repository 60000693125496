/**
 * @module Sagas/User
 * @desc User
 */

import { all, put, call, takeLatest } from 'redux-saga/effects'

import { ActionTypes, APIEndpoints } from 'redux/constants/index'
import { request } from 'services/client'
import { getCookie } from 'services/cookie'
import _ from 'lodash'

/**
 * Signup
 */
export function * getProductList ({ payload }) {
  try {
    const { limit, skip, searchString, filter } = payload
    const shopId = getCookie('shop_id')
    const response = yield call(request, APIEndpoints.PRODUCT_LIST(shopId, limit, skip, searchString, filter), {
      method: 'GET'
    })
    yield put({
      type: ActionTypes.PRODUCT_GET_LIST_SUCCESS,
      payload: response.items
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.PRODUCT_GET_LIST_FAILURE,
      payload: err
    })
  }
}

export function * getProductDetails ({ payload }) {
  try {
    const { id } = payload
    const shopId = getCookie('shop_id')
    const response = yield call(request, APIEndpoints.PRODUCT_DETAILS(shopId, id), {
      method: 'GET'
    })
    yield put({
      type: ActionTypes.PRODUCT_GET_DETAILS_SUCCESS,
      payload: response
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.PRODUCT_GET_DETAILS_FAILURE,
      payload: err
    })
  }
}

export function * updateProduct ({ payload }) {
  try {
    const { product } = payload
    const shopId = getCookie('shop_id')
    const response = yield call(request, APIEndpoints.UPDATE_PRODUCT_URL(shopId, product.id), {
      method: 'POST',
      payload: { item: product }
    })
    if (response.error) {
      yield put({
        type: ActionTypes.UPDATE_PRODUCT_FAILURE,
        payload: { message: response.error }
      })
    } else {
      yield put({
        type: ActionTypes.UPDATE_PRODUCT_SUCCESS,
        payload: response.item
      })
    }
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.UPDATE_PRODUCT_FAILURE,
      payload: err
    })
  }
}

export function * createProduct ({ payload }) {
  try {
    const { product } = payload
    const shopId = getCookie('shop_id')
    const response = yield call(request, APIEndpoints.CREATE_PRODUCT_URL(shopId), {
      method: 'POST',
      payload: { item: product }
    })
    yield put({
      type: ActionTypes.CREATE_PRODUCT_SUCCESS,
      payload: response.item
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.CREATE_PRODUCT_FAILURE,
      payload: err
    })
  }
}

export function * searchProductList ({ payload }) {
  try {
    const { searchString } = payload
    if (searchString) {
      const response = yield call(request, APIEndpoints.SEARCH_PRODUCT_LIST_URL(searchString), {
        method: 'GET'
      })
      yield put({
        type: ActionTypes.PRODUCT_SEARCH_RESULT_SUCCESS,
        payload: response.master_items
      })
    } else {
      yield put({
        type: ActionTypes.PRODUCT_SEARCH_RESULT_SUCCESS,
        payload: []
      })
    }
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.PRODUCT_SEARCH_RESULT_FAILURE,
      payload: err
    })
  }
}

export function * changeProductStatus ({ payload }) {
  try {
    let { statusRequest } = _.cloneDeep(payload)
    const shopId = getCookie('shop_id')
    const { itemId } = statusRequest
    delete statusRequest.itemId
    const response = yield call(request, APIEndpoints.CHANGE_STOCK_STATUS(shopId, itemId), {
      method: 'POST',
      payload: { item: statusRequest }
    })
    yield put({
      type: ActionTypes.CHANGE_STOCK_STATUS_SUCCESS,
      payload: response.item
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.CHANGE_STOCK_STATUS_FAILURE,
      payload: err
    })
  }
}

export function * deleteProduct ({ payload }) {
  try {
    const { id } = payload
    const shopId = getCookie('shop_id')
    const response = yield call(request, APIEndpoints.DELETE_PRODUCT_URL(shopId, id), {
      method: 'DELETE',
      payload: { }
    })
    if (response.error) {
      yield put({
        type: ActionTypes.DELETE_PRODUCT_FAILURE,
        payload: { message: response.error }
      })
    } else {
      yield put({
        type: ActionTypes.DELETE_PRODUCT_SUCCESS,
        payload: response
      })
    }
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.DELETE_PRODUCT_FAILURE,
      payload: err
    })
  }
}

/**
 * User Sagas
 */
export default function * root () {
  yield all([
    takeLatest(ActionTypes.PRODUCT_GET_LIST, getProductList),
    takeLatest(ActionTypes.PRODUCT_GET_DETAILS, getProductDetails),
    takeLatest(ActionTypes.UPDATE_PRODUCT, updateProduct),
    takeLatest(ActionTypes.CREATE_PRODUCT, createProduct),
    takeLatest(ActionTypes.PRODUCT_SEARCH_RESULT, searchProductList),
    takeLatest(ActionTypes.CHANGE_STOCK_STATUS, changeProductStatus),
    takeLatest(ActionTypes.DELETE_PRODUCT, deleteProduct)
  ])
}
