import React, { Component } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Button, TextField, Typography, withStyles } from '@material-ui/core'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import treeChanges from 'tree-changes'
import validate from 'validate.js'
import _ from 'lodash'

import { updateUserSettings, showAlert } from 'redux/actions'

import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter
} from 'components'
import styles from './style'
import schema from './schema'
import { STATUS } from 'redux/constants'

class UserDetails extends Component {
  constructor (props) {
    super(props)
    const userDetails = props.userDetails
    this.state = {
      userDetails,
      touched: {
        name: false,
        email: false,
        phone: false
      },
      userError: {

      }
    }
  }

  componentWillReceiveProps = newProps => {
    const { userDetails, updateUser } = newProps
    const { changed, changedTo } = treeChanges(this.props, newProps)
    if (changed('userDetails') && updateUser.status !== STATUS.RUNNING) {
      this.setState({ userDetails })
    }
    if (changedTo('updateUser.status', STATUS.ERROR)) {
      this.props.dispatch(showAlert(updateUser.message, { variant: 'error' }))
    } else if (changedTo('updateUser.status', STATUS.READY)) {
      this.setState({ touched: {} })
      this.props.dispatch(showAlert('Updated User Settings successfully', { variant: 'success' }))
    }
  }

  getErrorMessage = () => {
    const { userError, touched } = this.state
    const showNameError =
      touched.name && userError.name ? userError.name[0] : false
    const showEmailError =
      touched.email && userError.email ? userError.email[0] : false
    const showPhoneError =
      touched.phone && userError.phone ? userError.phone[0] : false
    let errorItems = []
    if (showNameError) {
      errorItems.push('User Name')
    }
    if (showEmailError) {
      errorItems.push('Email')
    }
    if (showPhoneError) {
      errorItems.push('Phone Number')
    }
    if (errorItems.length > 1) {
      const lastItem = errorItems.splice(errorItems.length - 1, 1)[0]
      return `* ${errorItems.join(', ')} and ${lastItem} are not valid`
    } else {
      return `* ${errorItems[0]} is not valid`
    }
  }

  validateUserForm = _.debounce(() => {
    const { userDetails } = this.state
    const errors = validate(userDetails, schema)
    this.setState({ userError: errors || {} })
  }, 300);

  handleFieldChange = (field, value) => {
    let { userDetails, touched } = this.state
    userDetails[field] = value
    touched[field] = true
    this.setState({ userDetails, touched }, this.validateUserForm)
  }

  handleSaveAction = e => {
    this.props.dispatch(updateUserSettings(this.state.userDetails))
  }

  render () {
    const { classes, className, updateUser, ...rest } = this.props
    const { userDetails, userError, touched } = this.state
    const rootClassName = classNames(classes.root, className)
    const showNameError =
      touched.name && userError.name ? userError.name[0] : false
    const showEmailError =
      touched.email && userError.email ? userError.email[0] : false
    const showPhoneError =
      touched.phone && userError.phone ? userError.phone[0] : false
    const isEdited = touched.name || touched.email || touched.phone
    const isValid = !showNameError && !showEmailError && !showPhoneError

    return (
      <Portlet
        {...rest}
        className={rootClassName}
      >
        <PortletHeader>
          <PortletLabel
            title='Profile'
          />
        </PortletHeader>
        <PortletContent noPadding>
          <form
            autoComplete='off'
            noValidate
          >
            <div className={classes.field}>
              <TextField
                className={classes.textField}
                helperText='Please specify the user name'
                label='User Name'
                margin='dense'
                required
                value={userDetails.name}
                variant='outlined'
                onChange={(e) => this.handleFieldChange('name', e.target.value)}
              />
              <TextField
                className={classes.textField}
                label='Email Address'
                margin='dense'
                required
                value={userDetails.email}
                variant='outlined'
                onChange={(e) => this.handleFieldChange('email', e.target.value)}
              />
            </div>
            <div className={classes.field}>

              <TextField
                className={classes.textField}
                label='Contact Number'
                margin='dense'
                type='text'
                required
                value={userDetails.phone}
                variant='outlined'
                onChange={(e) => this.handleFieldChange('phone', e.target.value)}
              />
            </div>
          </form>
        </PortletContent>
        <PortletFooter className={classes.portletFooter}>
          <Button
            color='primary'
            variant='contained'
            disabled={!isValid || !isEdited || updateUser.status === STATUS.RUNNING}
            onClick={this.handleSaveAction}
          >
            Save details
          </Button>
          { !isValid && isEdited ? <Typography className={classes.footerError} variant='body1'>{this.getErrorMessage()}</Typography> : null}
        </PortletFooter>
      </Portlet>
    )
  }
}

UserDetails.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
}

function mapStateToProps (state) {
  return {
    userDetails: _.cloneDeep(state.settings.shopDetails.user),
    updateUser: state.settings.updateUser
  }
}

export default compose(
  withStyles(styles)
)(connect(mapStateToProps)(UserDetails))
