export default theme => ({
  cardContainer: {
    paddingBottom: 0,
    cursor: 'pointer'
  },
  details: {
    display: 'flex'
  },
  info: {
    width: '100%',
    position: 'relative'
  },
  orderHeading: {
    marginBottom: '10px'
  },
  orderCode: {
    float: 'right'
  },
  products: {
    minHeight: 108
  },
  productCell: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  cellItem: {
    marginBottom: '15px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&:first-child': {
      width: '90%'
    }
  },
  dateText: {
    color: theme.palette.text.secondary
  },
  productPrice: {
    marginTop: '0px',
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 'bold',
    '& >p:last-child': {
      fontWeight: 'bold',
      '&.confirmed': {
        color: 'blue'
      },
      '&.shop_confirmed': {
        color: '#00c4ff'
      },
      '&.shop_packed': {
        color: '#61a903'
      },
      '&.shop_delivery': {
        color: 'green'
      },
      '&.delivered': {
        color: 'green'
      },
      '&.customer_cancelled, &.shop_cancelled': {
        color: 'red'
      }
    },
    '&:last-child': {
      marginBottom: 20
    }
  },
  uploadButton: {
    // marginRight: theme.spacing.unit *
    display: 'flex',
    float: 'right'

  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  footerIcons: {
    width: 30,
    height: 30
  },
  dateContainer: {
    display: 'flex',
    width: '100%',
    marginBottom: 10
  },
  orderTime: {
    width: '50%',
    '& p': {
      fontWeight: 'bold',
      textAlign: 'left',
      '&:first-child': {
        fontSize: 11,
        fontWeight: 'normal',
        marginBottom: 0
      }
    }
  },
  deliveryTime: {
    width: '50%',
    '& p': {
      fontWeight: 'bold',
      textAlign: 'right',
      '&:first-child': {
        fontSize: 11,
        fontWeight: 'normal',
        marginBottom: 0
      }
    }
  },
  addressHeading: {
    marginBottom: 10,
    display: 'flex',
    flexDirection: 'column',
    height: 75
  },
  addressHeadingText: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    width: '100%'
  },
  new: {
    height: 12,
    width: 23,
    position: 'absolute',
    top: -10,
    right: -19,
    background: '#ff7a7a',
    borderRadius: 2,
    fontSize: 10,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    textAlign: 'center',
    lineHeight: '12px'
  }
})
