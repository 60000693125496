import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles, TextField } from '@material-ui/core'
import Modal from 'views/Modal'
import moment from 'moment'
import treeChanges from 'tree-changes'

const styles = theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 100
  },
  textField: {
    margin: 0,
    width: 'calc( 100% - 80px)',
    marginLeft: 20
  },
  error: {
    color: 'red'
  },
  code: {
    width: 60,
    margin: 0
  }
})

class AddPartner extends Component {
    state = {
      phone: '',
      code: '+91'
    }

    componentWillReceiveProps = nextProps => {
      const { changed } = treeChanges(this.props, nextProps)
      if (changed('delivery')) {
        this.setState({ time: moment(nextProps.delivery).format('YYYY-MM-DTHH:mm') })
      }
    }

    handleChange = (field, e) => {
      const { value } = e.target
      this.setState({ [field]: value })
    }

    onSubmit = () => {
      const { phone } = this.state
      const { onSubmit } = this.props
      this.setState({ phone: '' })
      onSubmit(phone)
    }

    render () {
      const { classes, onCancel, isOpen } = this.props
      const { phone, code } = this.state
      const child = <div className={classes.container}>
        <TextField
          className={classes.code}
          margin='dense'
          onChange={(e) => this.handleChange('code', e)}
          value={code}
          variant='outlined'
          disabled
        />
        <TextField
          className={classes.textField}
          label={'Phone Number'}
          margin='dense'
          onChange={(e) => this.handleChange('phone', e)}
          value={phone}
          variant='outlined'
        />
      </div>
      return (
        <Modal isOpen={isOpen} title={'Add Delivery Partner'} onCancel={onCancel} okText={'ADD'} onSubmit={this.onSubmit} child={child} />
      )
    }
}

AddPartner.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired
}

export default withStyles(styles)(AddPartner)
