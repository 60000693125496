import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'

// Externals
import PropTypes from 'prop-types'
import compose from 'recompose/compose'
import validate from 'validate.js'
import _ from 'underscore'
import treeChanges from 'tree-changes'
import { connect } from 'react-redux'

import { STATUS } from 'redux/constants/index'
import { forgotPassword, resetPassword, showAlert, clearForgot } from 'redux/actions/index'

import {
  Grid,
  Button,
  CircularProgress,
  TextField,
  Typography,
  withStyles
} from '@material-ui/core'

import styles from './style'

import schema from './schema'
import { checkPasswordValidity } from 'services/utility'

class Forgot extends Component {
  state = {
    stage: 1,
    values: {
      email: ''
    },
    touched: {},
    errors: {},
    isValid: false,
    isLoading: false,
    submitError: null
  }

  componentWillReceiveProps = nextProps => {
    const { dispatch } = this.props
    const { changedTo } = treeChanges(this.props, nextProps)
    if (changedTo('forgot.status', STATUS.READY)) {
      this.setState({ isLoading: false, stage: 2 })
      dispatch(clearForgot())
      dispatch(showAlert('We have sent an OTP to your mobile', { variant: 'success' }))
    } else if (changedTo('forgot.status', STATUS.ERROR)) {
      dispatch(showAlert(nextProps.forgot.message, { variant: 'error' }))
      dispatch(clearForgot())
      this.setState({ isLoading: false })
    }
    if (changedTo('reset.status', STATUS.READY)) {
      this.setState({ isLoading: false })
      dispatch(clearForgot())
      dispatch(showAlert('Successfully changed your password', { variant: 'success' }))
      this.props.history.push('/sign-in')
    } else if (changedTo('reset.status', STATUS.ERROR)) {
      dispatch(showAlert(nextProps.reset.message || 'Failed to reset password. Try again', { variant: 'error' }))
      dispatch(clearForgot())
      this.setState({ isLoading: false })
    }
  }

  validateForm = _.debounce(() => {
    const { values } = this.state

    const newState = { ...this.state }
    const errors = validate(values, schema)

    newState.errors = errors || {}
    newState.isValid = !errors

    this.setState(newState)
  }, 30);

  handleFieldChange = (field, value) => {
    const newState = { ...this.state }

    newState.submitError = null
    newState.touched[field] = true
    newState.values[field] = value

    this.setState(newState, this.validateForm)
  }

  handleResetFieldChange = (field, value) => {
    const newState = { ...this.state }

    newState.submitError = null
    newState.touched[field] = true
    newState.values[field] = value

    this.setState(newState)
  }

  handleForgot = async () => {
    try {
      const { values } = this.state
      this.setState({ isLoading: true })
      this.props.dispatch(forgotPassword(values.email, values.password))
    } catch (error) {
      this.setState({
        isLoading: false,
        serviceError: error
      })
    }
  }

  handleReset = async () => {
    const { dispatch } = this.props
    try {
      const { values } = this.state
      if (values.otp && values.rePassword && values.password) {
        if (values.rePassword !== values.password) {
          this.props.dispatch(showAlert('Passwords does not match', { variant: 'error' }))
          return
        }
        const isValidPassword = checkPasswordValidity(values.password)
        if (!isValidPassword) {
          const warning = 'Passwords must contain at least six characters, including uppercase, lowercase letters and numbers.'
          dispatch(showAlert(warning, { variant: 'error' }))
          return
        }
        this.setState({ isLoading: true })
        this.props.dispatch(resetPassword(values.email, values.otp, values.password))
      } else {
        this.props.dispatch(showAlert('Please fill the details', { variant: 'error' }))
        return
      }
    } catch (error) {
      this.setState({
        isLoading: false,
        serviceError: error
      })
    }
  }

  render () {
    const { classes } = this.props
    const {
      values,
      isValid,
      submitError,
      isLoading,
      touched,
      errors,
      stage
    } = this.state

    const showEmailError = touched.email && errors.email

    return (
      <div className={classes.root}>
        <Grid
          className={classes.grid}
          container
        >
          <Grid
            className={classes.quoteWrapper}
            item
            lg={5}
          >
            <div className={classes.quote}>
              <div className={classes.quoteInner}>
                {/* <div className={classes.person}>
                  <Typography
                    className={classes.name}
                    variant='body1'
                  >
                    Best online platform
                  </Typography>
                  <Typography
                    className={classes.bio}
                    variant='body2'
                  >
                    ShopsApp Team
                  </Typography>
                </div> */}
              </div>
            </div>
          </Grid>
          <Grid
            className={classes.content}
            item
            lg={7}
            xs={12}
          >
            <div className={classes.content}>
              <div className={classes.contentBody}>
                {stage === 1 ? <form className={classes.form}>
                  <Typography
                    className={classes.title}
                    variant='h3'
                  >
                    ShopsApp
                  </Typography>
                  <Typography
                    className={classes.title}
                    variant='body1'
                  >
                    in association with
                  </Typography>
                  <img src='images/cyberdome.png' alt='cyberdome_logo' />
                  <Typography
                    className={classes.title}
                    variant='h2'
                  >
                    Forgot Password
                  </Typography>
                  <div className={classes.fields}>
                    <TextField
                      className={classes.textField}
                      label='Email address'
                      name='email'
                      onChange={event =>
                        this.handleFieldChange('email', event.target.value)
                      }
                      type='text'
                      value={values.email}
                      variant='outlined'
                    />
                    {showEmailError && (
                      <Typography
                        className={classes.fieldError}
                        variant='body2'
                      >
                        {errors.email[0]}
                      </Typography>
                    )}
                  </div>
                  {submitError && (
                    <Typography
                      className={classes.submitError}
                      variant='body2'
                    >
                      {submitError}
                    </Typography>
                  )}
                  {isLoading ? (
                    <CircularProgress className={classes.progress} />
                  ) : (
                    <Button
                      className={classes.signInButton}
                      color='primary'
                      disabled={!isValid}
                      onClick={this.handleForgot}
                      size='large'
                      variant='contained'
                    >
                      Send OTP
                    </Button>
                  )}
                  <Typography
                    className={classes.signUp}
                    variant='body1'
                  >
                    Have an account?{' '}
                    <Link
                      className={classes.signUpUrl}
                      to='/sign-in'
                    >
                      Sign in
                    </Link>
                  </Typography>
                </form> : null}
                {stage === 2 ? <form className={classes.form} autoComplete='off' noValidate>
                  <Typography
                    className={classes.title}
                    variant='h3'
                  >
                    ShopsApp
                  </Typography>
                  <Typography
                    className={classes.title}
                    variant='body1'
                  >
                    in association with
                  </Typography>
                  <img src='images/cyberdome.png' alt='cyberdome_logo' />
                  <Typography
                    className={classes.title}
                    variant='h2'
                  >
                    Reset Password
                  </Typography>
                  <div className={classes.fields}>
                    <TextField
                      className={classes.textField}
                      label='Email address'
                      name='email'
                      type='text'
                      disabled
                      value={values.email}
                      variant='outlined'
                    />
                    <TextField
                      className={classes.textField}
                      label='OTP'
                      name='otp-test'
                      onChange={event =>
                        this.handleResetFieldChange('otp', event.target.value)
                      }
                      type='text'
                      value={values.otp}
                      variant='outlined'
                    />
                    <TextField
                      className={classes.textField}
                      label='New Password'
                      name='reset-password'
                      onChange={event =>
                        this.handleResetFieldChange('password', event.target.value)
                      }
                      type='password'
                      value={values.password}
                      variant='outlined'
                    />
                    <TextField
                      className={classes.textField}
                      label='Confirm New Password'
                      name='re-password'
                      onChange={event =>
                        this.handleResetFieldChange('rePassword', event.target.value)
                      }
                      type='password'
                      value={values.rePassword}
                      variant='outlined'
                    />
                  </div>

                  {isLoading ? (
                    <CircularProgress className={classes.progress} />
                  ) : (
                    <Button
                      className={classes.signInButton}
                      color='primary'
                      onClick={this.handleReset}
                      size='large'
                      variant='contained'
                    >
                      Reset
                    </Button>
                  )}
                  <Typography
                    className={classes.signUp}
                    variant='body1'
                  >
                    Have an account?{' '}
                    <Link
                      className={classes.signUpUrl}
                      to='/sign-in'
                    >
                      Sign in
                    </Link>
                  </Typography>
                </form> : null}

              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    )
  }
}

Forgot.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

/* istanbul ignore next */
function mapStateToProps (state) {
  return {
    forgot: state.user.forgot,
    reset: state.user.reset
  }
}

export default compose(
  withRouter,
  withStyles(styles)
)(connect(mapStateToProps)(Forgot))
