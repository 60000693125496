import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'

// Externals
import PropTypes from 'prop-types'
import compose from 'recompose/compose'
import validate from 'validate.js'
import _ from 'underscore'
import treeChanges from 'tree-changes'
import { connect } from 'react-redux'

import { STATUS } from 'redux/constants/index'
import { userLogin, getShopCategories, getShopTypes, addShopDetails, addShopAddress, setAuthenticated, refreshToken, showAlert } from 'redux/actions/index'

import {
  Grid,
  Button,
  CircularProgress,
  TextField,
  Typography,
  withStyles
} from '@material-ui/core'

import styles from './style'

import schema from './schema'
import shopSchema from 'views/SignUp/shopSchema'
import addressSchema from 'views/SignUp/addressSchema'
import { ShopDetails, ShopAddress } from 'views/SignUp/components'

class SignIn extends Component {
  state = {
    stage: 1,
    values: {
      email: '',
      password: ''
    },
    shop: {
      customerCare: '',
      shopName: '',
      type: '',
      category: []
    },
    address: {
      latlng: {},
      level1: '',
      level2: ''
    },
    touched: {
      email: false,
      password: false
    },
    errors: {
      email: null,
      password: null
    },
    isValid: false,
    isLoading: false,
    submitError: null
  }

  componentWillReceiveProps = nextProps => {
    const { dispatch } = this.props
    const { changedTo } = treeChanges(this.props, nextProps)

    /* istanbul ignore else */
    if (changedTo('user.isAuthenticated', true)) {
      this.setState({ isLoading: false })
      this.interval = setInterval(() => dispatch(refreshToken()), 45 * 60 * 1000)
    } else if (changedTo('user.status', STATUS.READY)) {
      this.setState({ isLoading: false })
      // dispatch(setAuthenticated())
      if (nextProps.user.userInfo.shop) {
        dispatch(setAuthenticated())
      } else {
        dispatch(getShopCategories())
        dispatch(getShopTypes())
        this.setState({ stage: 2 })
      }
    } else if (changedTo('user.status', STATUS.ERROR)) {
      // Handle error
      this.props.dispatch(showAlert('Login credential does not exist', { variant: 'error' }))
      this.setState({ isLoading: false })
    }
    if (changedTo('shopDetails.status', STATUS.READY)) {
      dispatch(setAuthenticated())
      // this.setState({ isShopLoading: false })
      // this.setState({ stage: 3 })
    } else if (changedTo('shopDetails.status', STATUS.ERROR)) {
      dispatch(showAlert(nextProps.shopDetails.message, { variant: 'error' }))
      this.setState({ isShopLoading: false })
    }
    if (changedTo('shopAddress.status', STATUS.READY)) {
      this.setState({ isShopAddressLoading: false })
      dispatch(setAuthenticated())
    } else if (changedTo('shopAddress.status', STATUS.ERROR)) {
      dispatch(showAlert(nextProps.shopAddress.message, { variant: 'error' }))
      this.setState({ isShopAddressLoading: false })
    }
  }

  componentWillUnmount = () => {
    clearInterval(this.interval)
  }

  validateShopForm = _.debounce(() => {
    const { shop } = this.state

    const newState = { ...this.state }
    const errors = validate(shop, shopSchema)
    newState.errors = errors || {}
    newState.isShopValid = !errors
    this.setState(newState)
  }, 30);

  validateShopAddressForm = _.debounce(() => {
    const { address } = this.state

    const newState = { ...this.state }
    const errors = validate(address, addressSchema)
    newState.errors = errors || {}
    newState.isShopAddressValid = !errors
    this.setState(newState)
  }, 30);

  validateForm = _.debounce(() => {
    const { values } = this.state

    const newState = { ...this.state }
    const errors = validate(values, schema)

    newState.errors = errors || {}
    newState.isValid = !errors

    this.setState(newState)
  }, 30);

  handleFieldChange = (field, value) => {
    const newState = { ...this.state }

    newState.submitError = null
    newState.touched[field] = true
    newState.values[field] = value

    this.setState(newState, this.validateForm)
  };
  handleShopFieldChange = (field, value) => {
    const newState = { ...this.state }
    newState.submitShopError = null
    newState.touched[field] = true
    newState.shop[field] = value

    this.setState(newState, this.validateShopForm)
  };

  handleShopAddressFieldChange = (field, value) => {
    const newState = { ...this.state }
    newState.submitShopAddressError = null
    newState.touched[field] = true
    newState.address[field] = value

    this.setState(newState, this.validateShopAddressForm)
  };

  handleSignIn = async () => {
    try {
      const { values } = this.state
      this.setState({ isLoading: true })
      this.props.dispatch(userLogin(values.email, values.password))
    } catch (error) {
      this.setState({
        isLoading: false,
        serviceError: error
      })
    }
  }

  submitShopDetails = () => {
    const { dispatch, user } = this.props
    const { shop } = this.state
    const request = {
      'name': shop.shopName,
      'email': user.requestedUser.email,
      // "email": 'ajesh.babu+2@diagnal.com',
      'phone': shop.customerCare,
      'customer_care': shop.customerCare,
      'shop_type': shop.type,
      'base_categories': shop.category
    }
    dispatch(addShopDetails(request))
    this.setState({ isShopLoading: true })
  }

  submitShopAddress = (request) => {
    const { dispatch } = this.props
    const isAdd = true
    dispatch(addShopAddress(request, isAdd))
    this.setState({ isShopAddressLoading: true })
  }

  render () {
    const { classes } = this.props
    const {
      stage,
      values,
      shop,
      address,
      touched,
      errors,
      isValid,
      isShopValid,
      isShopAddressValid,
      submitError,
      submitShopError,
      submitShopAddressError,
      isLoading,
      isShopLoading,
      isShopAddressLoading
    } = this.state

    const showEmailError = touched.email && errors.email
    const showPasswordError = touched.password && errors.password
    const showCategoryError =
      touched.category && errors.category ? errors.category[0] : false
    const showCustomerCareError =
      touched.customerCare && errors.customerCare ? errors.customerCare[0] : false
    const showShopNameError =
      touched.shopName && errors.shopName ? errors.shopName[0] : false

    return (
      <div className={classes.root}>
        <Grid
          className={classes.grid}
          container
        >
          <Grid
            className={classes.quoteWrapper}
            item
            lg={5}
          >
            <div className={classes.quote}>
              <div className={classes.quoteInner}>
                {/* <div className={classes.person}>
                  <Typography
                    className={classes.name}
                    variant='body1'
                  >
                    Best online platform
                  </Typography>
                  <Typography
                    className={classes.bio}
                    variant='body2'
                  >
                    ShopsApp Team
                  </Typography>
                </div> */}
              </div>
            </div>
          </Grid>
          <Grid
            className={classes.content}
            item
            lg={7}
            xs={12}
          >
            <div className={classes.content}>
              <div className={classes.contentBody}>
                {stage === 1 ? <form className={classes.form}>
                  <Typography
                    className={classes.title}
                    variant='h3'
                  >
                    ShopsApp
                  </Typography>
                  <Typography
                    className={classes.title}
                    variant='body1'
                  >
                    in association with
                  </Typography>
                  <img className={classes.img} src='images/cyberdome.png' alt='cyberdome_logo' />
                  <Typography
                    className={classes.title}
                    variant='h2'
                  >
                    Login
                  </Typography>
                  <div className={classes.fields}>
                    <TextField
                      className={classes.textField}
                      label='Email address'
                      name='email'
                      onChange={event =>
                        this.handleFieldChange('email', event.target.value)
                      }
                      type='text'
                      value={values.email}
                      variant='outlined'
                    />
                    {showEmailError && (
                      <Typography
                        className={classes.fieldError}
                        variant='body2'
                      >
                        {errors.email[0]}
                      </Typography>
                    )}
                    <TextField
                      className={classes.textField}
                      label='Password'
                      name='password'
                      onChange={event =>
                        this.handleFieldChange('password', event.target.value)
                      }
                      type='password'
                      value={values.password}
                      variant='outlined'
                    />
                    {showPasswordError && (
                      <Typography
                        className={classes.fieldError}
                        variant='body2'
                      >
                        {errors.password[0]}
                      </Typography>
                    )}
                  </div>
                  {submitError && (
                    <Typography
                      className={classes.submitError}
                      variant='body2'
                    >
                      {submitError}
                    </Typography>
                  )}
                  {isLoading ? (
                    <CircularProgress className={classes.progress} />
                  ) : (
                    <Button
                      className={classes.signInButton}
                      color='primary'
                      disabled={!isValid}
                      onClick={this.handleSignIn}
                      size='large'
                      variant='contained'
                    >
                      Sign in now
                    </Button>
                  )}
                  <Typography
                    className={classes.signUp}
                    variant='body1'
                  >
                    Don't have an account?{' '}
                    <Link
                      className={classes.signUpUrl}
                      to='/sign-up'
                    >
                      Sign up
                    </Link>
                  </Typography>
                  <Typography
                    className={classes.forgot}
                    variant='body1'
                  >
                    <Link
                      className={classes.signUpUrl}
                      to='/forgot'
                    >
                      Forgot your password?
                    </Link>
                  </Typography>
                </form> : null}
                {stage === 2 ? <ShopDetails
                  values={shop}
                  type='signin'
                  isValid={isShopValid}
                  errors={errors}
                  submitShopDetails={this.submitShopDetails}
                  handleFieldChange={this.handleShopFieldChange}
                  isLoading={isShopLoading}
                  submitError={submitShopError}
                  showShopNameError={showShopNameError}
                  showCustomerCareError={showCustomerCareError}
                  showCategoryError={showCategoryError}
                /> : null}
                {stage === 3 ? <ShopAddress
                  values={address}
                  type='signin'
                  isValid={isShopAddressValid}
                  errors={errors}
                  submitShopAddressDetails={this.submitShopAddress}
                  handleFieldChange={this.handleShopAddressFieldChange}
                  isLoading={isShopAddressLoading}
                  submitError={submitShopAddressError}
                  showCategoryError={showCategoryError}
                /> : null}
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    )
  }
}

SignIn.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

/* istanbul ignore next */
function mapStateToProps (state) {
  return {
    user: state.user,
    shopDetails: state.address.shopDetails,
    shopAddress: state.address.shopAddress
  }
}

export default compose(
  withRouter,
  withStyles(styles)
)(connect(mapStateToProps)(SignIn))
