export default theme => ({
  root: {
    padding: 0,
    paddingBottom: 0
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center'
  },
  item: {
    height: '100%'
  },
  progressWrapper: {
    width: '100%',
    top: 500,
    left: 0,
    right: 0,
    paddingBottom: '24px',
    display: 'flex',
    justifyContent: 'center'
  },
  details: {
    maxWidth: 'calc( 100% - 40px )',
    width: 500,
    margin: 20,
    padding: 20,
    border: '1px solid gray',
    borderRadius: 4,
    [theme.breakpoints.down('xs')]: {
      margin: -10,
      marginTop: 20,
      padding: 10
    }
  },
  signInButton: {
    width: '80%',
    marginLeft: '10%'
  },
  title: {
    width: '100%',
    textAlign: 'center'
  },
  pairs: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 20,
    marginBottom: 20
  },
  pair: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 15
  },
  key: {
    fontSize: 18
  },
  value: {
    textAlign: 'right',
    fontSize: 18,
    fontWeight: 'bold'
  }
})
