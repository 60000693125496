import React, { Component } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Button, withStyles, TextField, CircularProgress, Divider, Switch, Typography } from '@material-ui/core'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import treeChanges from 'tree-changes'
import validate from 'validate.js'
import _ from 'lodash'

import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter
} from 'components'
import styles from './style'
import schema from './schema'
// import Select from 'components/Select'
import { getDeliveryDetails, showAlert, updateDeliverySettings } from 'redux/actions'
import { STATUS } from 'redux/constants'

class DeliveryDetails extends Component {
  constructor (props) {
    super(props)
    const deliveryOptions = props.deliveryOptionsData.data || {}
    this.state = {
      deliveryOptions,
      touched: {

      },
      errors: {

      }
    }
  }

  componentDidMount = () => {
    const { dispatch } = this.props
    dispatch(getDeliveryDetails())
  }

  componentWillReceiveProps = newProps => {
    const { deliveryOptionsData, editDeliverySettings } = _.cloneDeep(newProps)
    const { changed, changedTo } = treeChanges(this.props, newProps)
    if (changed('deliveryOptionsData') && editDeliverySettings.status !== STATUS.RUNNING) {
      this.setState({ deliveryOptions: _.cloneDeep(deliveryOptionsData.data) })
    }
    if (changedTo('editDeliverySettings.status', STATUS.ERROR)) {
      this.props.dispatch(showAlert(editDeliverySettings.message, { variant: 'error' }))
    } else if (changedTo('editDeliverySettings.status', STATUS.READY)) {
      this.setState({ touched: {} })
      this.props.dispatch(showAlert('Updated Delivery Settings successfully', { variant: 'success' }))
    }
  }

  validateForm = _.debounce(() => {
    const { deliveryOptions } = this.state
    const request = {
      ...deliveryOptions
    }
    const errors = validate(request, schema)
    this.setState({ errors: errors || {} })
  }, 300);

  handleFieldChange = (field, value) => {
    let { deliveryOptions, touched } = this.state
    deliveryOptions[field] = parseFloat(value) || null
    touched[field] = true
    this.setState({ deliveryOptions, touched }, this.validateForm)
  }

  handleFreeDeliveryChange = (type, field, value) => {
    let { deliveryOptions, touched } = this.state
    deliveryOptions[type][field] = value
    touched['free_delivery'] = true
    this.setState({ deliveryOptions, touched }, this.validateForm)
  }

  handleSaveAction = e => {
    const { deliveryOptions } = this.state
    if (deliveryOptions.free_delivery && deliveryOptions.free_delivery.status && deliveryOptions.free_delivery.min_price <= 0) {
      this.props.dispatch(showAlert('Free delivery price should be valid', { variant: 'error' }))
      return
    }
    if (deliveryOptions.free_delivery && deliveryOptions.free_delivery.status && deliveryOptions.free_delivery.distance <= 0) {
      this.props.dispatch(showAlert('Free delivery Distance should be valid', { variant: 'error' }))
      return
    }
    if (deliveryOptions.free_bulk_delivery && deliveryOptions.free_bulk_delivery.status && deliveryOptions.free_bulk_delivery.min_price <= 0) {
      this.props.dispatch(showAlert('Free Bulk delivery price should be valid', { variant: 'error' }))
      return
    }
    const freeDelivery = {
      status: deliveryOptions.free_delivery.status || false,
      distance: parseFloat(deliveryOptions.free_delivery.distance) || 0,
      min_price: parseFloat(deliveryOptions.free_delivery.min_price) || 0
    }
    const freeBulkDelivery = {
      status: deliveryOptions.free_bulk_delivery.status || false,
      min_price: parseFloat(deliveryOptions.free_bulk_delivery.min_price) || 0
    }

    const request = {
      distance: deliveryOptions.distance || 0,
      min_price: deliveryOptions.min_price || 0,
      dispatch_time: deliveryOptions.dispatch_time || 0,
      max_weight: deliveryOptions.max_weight || 0,
      base_distance_price: deliveryOptions.base_distance_price || 0,
      base_distance: deliveryOptions.base_distance || 0,
      price_per_km: deliveryOptions.price_per_km || 0,
      free_bulk_delivery: freeBulkDelivery,
      free_delivery: freeDelivery
    }

    this.props.dispatch(updateDeliverySettings(request))
  }

  render () {
    const { classes, className, editDeliverySettings, deliveryOptionsData, ...rest } = this.props
    const { deliveryOptions, errors, touched } = this.state
    const rootClassName = classNames(classes.root, className)
    const showDistanceError =
      touched.distance && errors.distance ? errors.distance[0] : false
    const showMinimumPriceError =
      touched.min_price && errors.min_price ? errors.min_price[0] : false
    const showDispatchTimeError =
      touched.dispatch_time && errors.dispatch_time ? errors.dispatch_time[0] : false
    const showMaxWeightError =
      touched.max_weight && errors.max_weight ? errors.max_weight[0] : false
    const showBaseDistancePriceError =
      touched.base_distance_price && errors.base_distance_price ? errors.base_distance_price[0] : false
    const showBaseDistanceError =
      touched.base_distance && errors.base_distance ? errors.base_distance[0] : false
    const showPricePerKmError =
      touched.price_per_km && errors.price_per_km ? errors.price_per_km[0] : false
    const isEdited = touched.distance || touched.min_price || touched.dispatch_time || touched.max_weight || touched.base_distance_price | touched.base_distance || touched.price_per_km || touched.free_delivery
    const isValid = !showPricePerKmError && !showBaseDistanceError && !showBaseDistancePriceError && !showDistanceError && !showMinimumPriceError && !showDispatchTimeError && !showMaxWeightError

    return (
      <Portlet
        {...rest}
        className={rootClassName}
      >
        <PortletHeader>
          <PortletLabel
            title='Delivery Settings'
          />
        </PortletHeader>
        <PortletContent noPadding>
          { deliveryOptionsData.status === STATUS.READY ? <form
            autoComplete='off'
            noValidate
          >
            <div className={classes.field}>
              <TextField
                className={classes.textField}
                // helperText='Please specify the store name'
                label='Maximum Delivery Range(Km)'
                margin='dense'
                required
                value={deliveryOptions.distance}
                variant='outlined'
                onChange={(e) => this.handleFieldChange('distance', e.target.value)}
              />
              <TextField
                className={classes.textField}
                label='Minimum Cart Value(Rs)'
                margin='dense'
                required
                value={deliveryOptions.min_price}
                variant='outlined'
                onChange={(e) => this.handleFieldChange('min_price', e.target.value)}
              />
            </div>
            <div className={classes.field}>
              <TextField
                className={classes.textField}
                label='Usual Dispatch Time(Minutes)'
                margin='dense'
                type='number'
                required
                value={deliveryOptions.dispatch_time}
                variant='outlined'
                onChange={(e) => this.handleFieldChange('dispatch_time', e.target.value)}
              />
              <TextField
                className={classes.textField}
                label='Max Package Weight(kg)'
                margin='dense'
                type='number'
                value={deliveryOptions.max_weight}
                variant='outlined'
                onChange={(e) => this.handleFieldChange('max_weight', e.target.value)}
              />
            </div>
            <div className={classes.field}>
              <TextField
                className={classes.textField}
                label='Base Delivery Charge(Rs)'
                margin='dense'
                required
                type='number'
                value={deliveryOptions.base_distance_price}
                variant='outlined'
                onChange={(e) => this.handleFieldChange('base_distance_price', e.target.value)}
              />
              <TextField
                className={classes.textField}
                label='Distance Covered in Base Delivery Charge(Km)'
                margin='dense'
                type='number'
                required
                value={deliveryOptions.base_distance}
                variant='outlined'
                onChange={(e) => this.handleFieldChange('base_distance', e.target.value)}
              />
            </div>
            <div className={classes.field}>
              <TextField
                className={classes.textField}
                label='Per Km Charge(Rs)'
                margin='dense'
                type='number'
                required
                value={deliveryOptions.price_per_km}
                variant='outlined'
                onChange={(e) => this.handleFieldChange('price_per_km', e.target.value)}
              />
            </div>

            <Divider />
            <div className={classes.acceptStatus}>
              <Typography className={classes.statusTitle} variant='body1'>Free Delivery Options</Typography>
              <Switch
                className={classes.switch}
                checked={deliveryOptions.free_delivery.status}
                onChange={(e) => this.handleFreeDeliveryChange('free_delivery', 'status', e.target.checked)}
                value='checkedA'
                // disabled={isLoading}
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </div>
            {deliveryOptions.free_delivery.status ? <div className={`${classes.field} ${classes.deliveryTextField}`}>
              <TextField
                className={classes.textField}
                // helperText='Please specify the store name'
                label='Minimum Bill Price'
                margin='dense'
                required
                value={deliveryOptions.free_delivery.min_price}
                variant='outlined'
                onChange={(e) => this.handleFreeDeliveryChange('free_delivery', 'min_price', e.target.value)}
              />
              <TextField
                className={classes.textField}
                // helperText='Please specify the store name'
                label='Maximum Distance(Km)'
                margin='dense'
                required
                value={deliveryOptions.free_delivery.status.distance}
                variant='outlined'
                onChange={(e) => this.handleFreeDeliveryChange('free_delivery', 'distance', e.target.value)}
              />
            </div> : null}
            <Divider />
            <div className={classes.acceptStatus}>
              <Typography className={classes.statusTitle} variant='body1'>Free Bulk Delivery Options</Typography>
              <Switch
                className={classes.switch}
                checked={deliveryOptions.free_bulk_delivery.status}
                onChange={(e) => this.handleFreeDeliveryChange('free_bulk_delivery', 'status', e.target.checked)}
                value='checkedA'
                // disabled={isLoading}
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </div>
            {deliveryOptions.free_bulk_delivery.status ? <div className={`${classes.field} ${classes.deliveryTextField}`}>
              <TextField
                className={classes.textField}
                // helperText='Please specify the store name'
                label='Minimum Bill Price'
                margin='dense'
                required
                value={deliveryOptions.free_bulk_delivery.min_price}
                variant='outlined'
                onChange={(e) => this.handleFreeDeliveryChange('free_bulk_delivery', 'min_price', e.target.value)}
              />
            </div> : null}

          </form> : null }
          { deliveryOptionsData.status === STATUS.RUNNING ? <div className={classes.progressWrapper}>
            <CircularProgress />
          </div> : null}
        </PortletContent>
        <PortletFooter className={classes.portletFooter}>
          <Button
            color='primary'
            variant='contained'
            onClick={this.handleSaveAction}
            disabled={!isValid || !isEdited || editDeliverySettings.status === STATUS.RUNNING}
          >
            Save details
          </Button>
        </PortletFooter>
      </Portlet>
    )
  }
}

DeliveryDetails.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
}

function mapStateToProps (state) {
  return {
    deliveryOptionsData: _.cloneDeep(state.settings.deliveryDetails),
    editDeliverySettings: state.settings.editDeliverySettings
  }
}

export default compose(
  withStyles(styles)
)(connect(mapStateToProps)(DeliveryDetails))
