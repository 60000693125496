import { purple } from '@material-ui/core/colors'

export default theme => ({
  card: {
    width: '100%'
  },
  area: {
    padding: 20,
    display: 'flex',
    alignItems: 'flex-start',
    borderBottom: '1px solid #d0d0d0'
  },
  image: {
    width: 120
  },
  details: {
    width: 'calc( 100% - 120px)',
    paddingTop: 0,
    paddingBottom: 0,
    position: 'relative',
    height: 120
  },
  name: {
    maxHeight: 40,
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  manu: {
    maxHeight: 40,
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  price: {
    position: 'absolute',
    bottom: 0,
    left: 16,
    fontWeight: 'bold',
    fontSize: 17
  },
  oldPrice: {
    color: 'gray',
    marginLeft: 10,
    fontWeight: 500
  },
  footer: {
    position: 'relative'
  },
  editButton: {
    position: 'absolute',
    right: 16
  },
  deleteButton: {
    position: 'absolute',
    right: 90
  },
  stock: {
    fontWeight: 500,
    fontSize: 13,
    marginLeft: 10
  },
  switch: {
    color: purple[300],
    '&$checked': {
      color: purple[500]
    },
    '&$checked + $track': {
      backgroundColor: purple[500]
    }
  }
})
