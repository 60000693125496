export default {
  level1: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 40
    }
  },
  level2: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 68
    }
  }
}
// policy: {
//   presence: { allowEmpty: false, message: 'is required' },
//   checked: true
// }
