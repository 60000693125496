// @flow
/**
 * @module Actions/App
 * @desc App Actions
 */

import { createActions } from 'redux-actions'

import { ActionTypes } from 'redux/constants/index'

export const { getDeliveryPartners, createDeliveryPartner, deleteDeliveryPartner } = createActions({
  [ActionTypes.GET_DELIVERY_PARTNERS]: () => ({ }),
  [ActionTypes.CREATE_DELIVERY_PARTNER]: (phone) => ({ phone }),
  [ActionTypes.DELETE_DELIVERY_PARTNER]: (id) => ({ id })

})
