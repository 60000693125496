import { handleActions } from 'redux-actions'
import immutable from 'immutability-helper'

import { parseError } from 'services/client'

import { ActionTypes, STATUS } from 'redux/constants/index'

export const addressState = {
  location: {
    data: {},
    message: '',
    status: STATUS.IDLE
  },
  categories: {
    data: [],
    message: '',
    status: STATUS.IDLE
  },
  types: {
    data: [],
    message: '',
    status: STATUS.IDLE
  },
  shopDetails: {
    data: {},
    message: '',
    status: STATUS.IDLE
  },
  shopAddress: {
    data: {},
    message: '',
    status: STATUS.IDLE
  }
}

export default {
  address: handleActions(
    {
      [ActionTypes.GET_GEO_LOCATION]: (state, { payload }) =>
        immutable(state, {
          location: {
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.GET_GEO_LOCATION_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          location: {
            data: { $set: payload },
            status: { $set: STATUS.READY }
          }
        }),
      [ActionTypes.GET_GEO_LOCATION_FAILURE]: (state, { payload }) =>
        immutable(state, {
          location: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        }),

      [ActionTypes.GET_SHOP_CATEGORIES]: (state, { payload }) =>
        immutable(state, {
          categories: {
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.GET_SHOP_CATEGORIES_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          categories: {
            data: { $set: payload },
            status: { $set: STATUS.READY }
          }
        }),
      [ActionTypes.GET_SHOP_CATEGORIES_FAILURE]: (state, { payload }) =>
        immutable(state, {
          categories: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        }),

      [ActionTypes.GET_SHOP_TYPES]: (state, { payload }) =>
        immutable(state, {
          types: {
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.GET_SHOP_TYPES_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          types: {
            data: { $set: payload },
            status: { $set: STATUS.READY }
          }
        }),
      [ActionTypes.GET_SHOP_TYPES_FAILURE]: (state, { payload }) =>
        immutable(state, {
          types: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        }),

      [ActionTypes.ADD_SHOP_DETAILS]: (state, { payload }) =>
        immutable(state, {
          shopDetails: {
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.ADD_SHOP_DETAILS_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          shopDetails: {
            data: { $set: payload },
            status: { $set: STATUS.READY }
          }
        }),
      [ActionTypes.ADD_SHOP_DETAILS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          shopDetails: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        }),

      [ActionTypes.ADD_SHOP_ADDRESS]: (state, { payload }) =>
        immutable(state, {
          shopAddress: {
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.ADD_SHOP_ADDRESS_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          shopAddress: {
            data: { $set: payload },
            status: { $set: STATUS.READY }
          }
        }),
      [ActionTypes.ADD_SHOP_ADDRESS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          shopAddress: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        })
    },
    addressState
  )
}
