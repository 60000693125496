import React, { Component } from 'react'

import PropTypes from 'prop-types'
import { Grid, withStyles, CircularProgress, Typography } from '@material-ui/core'
import { Dashboard as DashboardLayout } from 'layouts'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import treeChanges from 'tree-changes'

import { STATUS } from 'redux/constants/index'

import { StationList, AddStation } from './components'
import { getStations, createStation, showAlert, deleteStation, editStation } from 'redux/actions'
import style from './style'
import SearchToolbar from 'views/Common/SearchToolbar'

const itemsPerPage = 20

// Component styles

class Stations extends Component {
  state = {
    searchString: '',
    page: 0,
    showAddDeliveryPerson: false
  }

  componentDidMount = () => {
    this.props.dispatch(getStations(0, itemsPerPage))
  }

  componentWillReceiveProps = newProps => {
    const { changedTo } = treeChanges(this.props, newProps)
    if (changedTo('addStation.status', STATUS.READY)) {
      const successMsg = 'Successfully Added New Police'
      this.props.dispatch(showAlert(successMsg, { variant: 'success' }))
      this.setState({ isLoading: true, page: 0 })
      this.props.dispatch(getStations(0, itemsPerPage))
    } else if (changedTo('addStation.status', STATUS.ERROR)) {
      this.props.dispatch(showAlert(newProps.addStation.message, { variant: 'error' }))
    }
    if (changedTo('editStation.status', STATUS.READY)) {
      const successMsg = 'Successfully Edited Police'
      this.props.dispatch(showAlert(successMsg, { variant: 'success' }))
      this.setState({ isLoading: true, page: 0 })
      this.props.dispatch(getStations(0, itemsPerPage))
    } else if (changedTo('editStation.status', STATUS.ERROR)) {
      this.props.dispatch(showAlert(newProps.editStation.message, { variant: 'error' }))
    }
    if (changedTo('deleteStation.status', STATUS.READY)) {
      const successMsg = 'Successfully Deleted the Police'
      this.props.dispatch(showAlert(successMsg, { variant: 'success' }))
      this.setState({ isLoading: true, page: 0 })
      this.props.dispatch(getStations(0, itemsPerPage))
    } else if (changedTo('deleteStation.status', STATUS.ERROR)) {
      this.props.dispatch(showAlert(newProps.deleteStation.message, { variant: 'error' }))
    }
  }

  showAddDeliveryPerson = () => {
    this.setState({ showAddDeliveryPerson: true })
  }

  hideAddDeliveryPerson = () => {
    this.setState({ showAddDeliveryPerson: false, selectedStations: undefined })
  }

  onChangeSearchString = event => {
    const { value } = event.target
    this.setState({ searchString: value })
  }

  onClickSearch = () => {
    const { searchString } = this.state
    this.setState({ isLoading: true, page: 0 })
    this.props.dispatch(getStations(0, itemsPerPage, searchString))
  }

  onSubmitStations = (name, id) => {
    this.setState({ showAddDeliveryPerson: false, selectedStations: undefined })
    if (id) {
      this.props.dispatch(editStation(name, id))
    } else {
      this.props.dispatch(createStation(name))
    }
  }

  deleteStations = (id) => {
    this.props.dispatch(deleteStation(id))
  }

  editStations = (stations) => {
    this.setState({ selectedStations: stations, showAddDeliveryPerson: true })
  }

  refreshPage = () => {
    const { isLoading, page, searchString } = this.state
    const nextIndex = (page + 1) * itemsPerPage
    if (!isLoading) {
      this.setState({ isLoading: true }, () => {
        this.props.dispatch(getStations(0, nextIndex, searchString))
      })
    }
  }

  fetchNextPage = () => {
    const { stations } = this.props
    const { isLoading, page, searchString } = this.state
    const nextIndex = (page + 1) * itemsPerPage
    if (!isLoading && !stations.reachedEnd) {
      this.setState({ page: page + 1, isLoading: true }, () => {
        this.props.dispatch(getStations(nextIndex, itemsPerPage, searchString))
      })
    }
  }

  getStationsList = () => {
    const { stations, classes } = this.props
    if (stations.status === STATUS.RUNNING && stations.data.length === 0) {
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress />
        </div>
      )
    }

    if (stations.data.length === 0) {
      return (
        <Typography variant='h6'>There are no stations.</Typography>
      )
    }
    return (
      <React.Fragment>
        {stations.status === STATUS.RUNNING ? <div className={classes.progressWrapper}>
          <CircularProgress />
        </div> : null}
        <StationList users={stations.data} deleteDeliveryPartner={this.deleteStations} fetchNextPage={this.fetchNextPage} editDeliveryPartner={this.editStations} />
      </React.Fragment>

    )
  }

  render () {
    const { searchString, showAddDeliveryPerson, selectedStations } = this.state
    const { classes } = this.props
    return (
      <DashboardLayout title='Stations'>
        <div className={classes.root}>
          <Grid
            container
            className={classes.container}
            spacing={4}
          >
            <SearchToolbar
              showCreate
              showCreateProduct={this.showAddDeliveryPerson}
              searchString={searchString}
              onChangeSearchString={this.onChangeSearchString}
              onClickSearch={this.onClickSearch}
              onClickRefreshButton={this.refreshPage}
            />
            {this.getStationsList()}
          </Grid>
        </div>
        <AddStation partner={selectedStations} isOpen={showAddDeliveryPerson} onCancel={this.hideAddDeliveryPerson} onSubmit={this.onSubmitStations} />
      </DashboardLayout>
    )
  }
}

Stations.propTypes = {
  classes: PropTypes.object.isRequired
}

function mapStateToProps (state) {
  return {
    stations: state.cadets.stations,
    addStation: state.cadets.addStation,
    editStation: state.cadets.editStation,
    deleteStation: state.cadets.deleteStation
  }
}

export default compose(
  withStyles(style)
)(connect(mapStateToProps)(Stations))
