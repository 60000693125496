import { handleActions } from 'redux-actions'
import immutable from 'immutability-helper'
import _ from 'lodash'
import { parseError } from 'services/client'

import { ActionTypes, STATUS } from 'redux/constants/index'

export const reservationState = {
  list: {
    message: '',
    data: [],
    status: STATUS.IDLE
  },
  reservations: {
    message: '',
    data: [],
    status: STATUS.IDLE
  },
  checkout: {
    message: '',
    data: {},
    status: STATUS.IDLE
  },
  checkin: {
    message: '',
    data: {},
    status: STATUS.IDLE
  },
  count: {
    message: '',
    data: 0,
    status: STATUS.IDLE
  },
  details: {
    data: {},
    message: '',
    status: STATUS.IDLE
  }
}

export default {
  reservations: handleActions(
    {
      [ActionTypes.GET_ACTIVE_RESERVATIONS]: (state, { payload }) =>
        immutable(state, {
          list: {
            status: { $set: STATUS.RUNNING },
            searchParam: { $set: payload },
            reachedEnd: { $set: false }
          }
        }),
      [ActionTypes.GET_ACTIVE_RESERVATIONS_SUCCESS]: (state, { payload }) => {
        let data = _.cloneDeep(state.list.data)
        if (state.list.searchParam && state.list.searchParam.skip === 0) {
          data = payload
        } else {
          data = [ ...data, ...payload ]
        }
        return immutable(state, {
          list: {
            data: { $set: data },
            status: { $set: STATUS.READY },
            reachedEnd: { $set: !payload.length || payload.length !== state.list.searchParam.limit }
          }
        })
      },
      [ActionTypes.GET_ACTIVE_RESERVATIONS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          list: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        }),

      [ActionTypes.GET_RESERVATIONS]: (state, { payload }) =>
        immutable(state, {
          reservations: {
            status: { $set: STATUS.RUNNING },
            searchParam: { $set: payload },
            reachedEnd: { $set: false }
          }
        }),
      [ActionTypes.GET_RESERVATIONS_SUCCESS]: (state, { payload }) => {
        let data = _.cloneDeep(state.reservations.data)
        if (state.reservations.searchParam && state.reservations.searchParam.skip === 0) {
          data = payload
        } else {
          data = [ ...data, ...payload ]
        }
        return immutable(state, {
          reservations: {
            data: { $set: data },
            status: { $set: STATUS.READY },
            reachedEnd: { $set: !payload.length || payload.length !== state.reservations.searchParam.limit }
          }
        })
      },
      [ActionTypes.GET_RESERVATIONS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          reservations: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        }),

      [ActionTypes.CHECKOUT_RESERVATION]: (state, { payload }) =>
        immutable(state, {
          checkout: {
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.CHECKOUT_RESERVATION_SUCCESS]: (state, { payload }) => {
        let listData = _.cloneDeep(state.list.data)
        const index = listData.findIndex(item => item.id === payload.id)
        if (index > -1) {
          listData.splice(index, 1)
        }
        let reservationData = _.cloneDeep(state.reservations.data)
        const listIndex = reservationData.findIndex(item => item.id === payload.id)
        if (listIndex > -1) {
          reservationData[index].status = 'checked_out'
        }
        let data = _.cloneDeep(state.details.data)
        if (data.id === payload.id) {
          data.status = 'checked_out'
        }
        return immutable(state, {
          details: {
            data: { $set: data }
          },
          checkout: {
            data: { $set: payload },
            status: { $set: STATUS.READY }
          },
          list: {
            data: { $set: listData },
            status: { $set: state.list.status }
          },
          reservations: {
            data: { $set: reservationData },
            status: { $set: state.reservations.status }
          }
        })
      },
      [ActionTypes.CHECKOUT_RESERVATION_FAILURE]: (state, { payload }) =>
        immutable(state, {
          checkout: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        }),

      [ActionTypes.CHECKIN_RESERVATION]: (state, { payload }) =>
        immutable(state, {
          checkin: {
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.CHECKIN_RESERVATION_SUCCESS]: (state, { payload }) => {
        let listData = _.cloneDeep(state.reservations.data)
        const index = listData.findIndex(item => item.id === payload.id)
        if (index > -1) {
          listData[index].status = 'checked_in'
        }
        let data = _.cloneDeep(state.details.data)
        if (data.id === payload.id) {
          data.status = 'checked_in'
        }
        return immutable(state, {
          details: {
            data: { $set: data }
          },
          checkin: {
            data: { $set: payload },
            status: { $set: STATUS.READY }
          },
          reservations: {
            data: { $set: listData },
            status: { $set: state.reservations.status }
          }
        })
      },
      [ActionTypes.CHECKIN_RESERVATION_FAILURE]: (state, { payload }) =>
        immutable(state, {
          checkout: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        }),

      [ActionTypes.GET_RESERVATION_COUNT]: (state, { payload }) =>
        immutable(state, {
          count: {
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.GET_RESERVATION_COUNT_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          count: {
            data: { $set: payload },
            status: { $set: STATUS.READY }
          }
        })
      },
      [ActionTypes.GET_RESERVATION_COUNT_FAILURE]: (state, { payload }) =>
        immutable(state, {
          count: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        }),

      [ActionTypes.GET_RESERVATION_DETAILS]: (state, { payload }) =>
        immutable(state, {
          details: {
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.GET_RESERVATION_DETAILS_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          details: {
            data: { $set: payload },
            status: { $set: STATUS.READY }
          }
        })
      },
      [ActionTypes.GET_RESERVATION_DETAILS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          details: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        })

    },
    reservationState
  )
}
