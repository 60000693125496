// @flow
/**
 * @module Actions/User
 * @desc User Actions
 */
import { createActions } from 'redux-actions'

import { ActionTypes } from 'redux/constants/index'

export const { addShopLogo, getShopDetails, updateShopSettings, updateUserSettings, editShopAddress, getDeliveryDetails, updateDeliverySettings, getPaymentDetails, updatePaymentSettings, changeAcceptStatus, changePassword, getOrderReport, sendPaymentReminder } = createActions({
  [ActionTypes.ADD_SHOP_LOGO]: (url) => ({ url }),
  [ActionTypes.GET_SHOP_DETAILS]: () => ({ }),
  [ActionTypes.UPDATE_SHOP_SETTINGS]: (requestObj) => ({ requestObj }),
  [ActionTypes.UPDATE_USER_SETTINGS]: (requestObj) => ({ requestObj }),
  [ActionTypes.EDIT_SHOP_ADDRESS]: (requestObj) => ({ requestObj }),
  [ActionTypes.GET_DELIVERY_DETAILS]: () => ({ }),
  [ActionTypes.UPDATE_DELIVERY_SETTINGS]: (requestObj) => ({ requestObj }),
  [ActionTypes.GET_PAYMENT_DETAILS]: () => ({ }),
  [ActionTypes.UPDATE_PAYMENT_SETTINGS]: (requestObj) => ({ requestObj }),
  [ActionTypes.CHANGE_ACCEPT_STATUS]: (status) => ({ status }),
  [ActionTypes.CHANGE_PASSWORD]: (old, password) => ({ old, password }),
  [ActionTypes.GET_ORDER_REPORT]: (from, to, status) => ({ from, to, status }),
  [ActionTypes.SEND_PAYMENT_REMINDER]: () => ({ })
})
