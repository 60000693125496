export default theme => ({
  root: {
    paddingTop: 20,
    '& .react-datetime-picker': {
      width: '100%',
      height: 35,
      marginTop: 10,
      '& > div': {
        borderRadius: '2px'
      }
    }
  },
  datePicker: {
    display: 'flex',
    flexDirection: 'column',
    width: '250px',
    padding: 20,
    marginRight: 20
  },
  selectContainer: {
    width: '250px',
    padding: 20
  },
  formContainer: {
    display: 'flex',
    marginBottom: 20
  },
  date: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
  },
  loaderContainer: {
    position: 'relative',
    padding: 0
  },
  progressWrapper: {
    position: 'absolute',
    width: '100%',
    top: '-50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  datetime: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
  }
})
