export default {
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64
    }
  }
}
// policy: {
//   presence: { allowEmpty: false, message: 'is required' },
//   checked: true
// }
