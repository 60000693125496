import React, { Component } from 'react'

// Externals
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Bar } from 'react-chartjs-2'

import { Button, withStyles } from '@material-ui/core'
import {
  ArrowDropDown as ArrowDropDownIcon,
  ArrowRight as ArrowRightIcon
} from '@material-ui/icons'

// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletToolbar,
  PortletContent,
  PortletFooter
} from 'components'

// Chart configuration
import { data, options } from './chart'

// Component styles
import styles from './styles'

class SalesChart extends Component {
  render () {
    const { classes, className, ...rest } = this.props

    const rootClassName = classNames(classes.root, className)

    return (
      <Portlet
        {...rest}
        className={rootClassName}
      >
        <PortletHeader noDivider>
          <PortletLabel title='Latest sales' />
          <PortletToolbar>
            <Button
              className={classes.dropdownButton}
              size='small'
              variant='text'
            >
              Last 7 days <ArrowDropDownIcon />
            </Button>
          </PortletToolbar>
        </PortletHeader>
        <PortletContent>
          <div className={classes.chartWrapper}>
            <Bar
              data={data}
              options={options}
            />
          </div>
        </PortletContent>
        <PortletFooter className={classes.portletFooter}>
          <Button
            color='primary'
            size='small'
            variant='text'
          >
            Overview <ArrowRightIcon />
          </Button>
        </PortletFooter>
      </Portlet>
    )
  }
}

SalesChart.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(SalesChart)
