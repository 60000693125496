export default theme => ({
  container: {
    width: '99vw',
    height: '583.5px',
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' %3E%3Cpath id='Path_2' data-name='Path 2' d='M1366,487.247s-350.477-82.457-686.591,38.5S0,525.743,0,525.743V-4H1366Z' transform='translate(0 4)' fill='%2332a05f'/%3E%3C/svg%3E%0A")`
  },
  heading: {
    fontFamily: 'Poppins',
    paddingTop: '100px',
    textAlign: 'center',
    color: 'white'
  },
  rectangleIcon: {
    paddingTop: '10px',
    display: 'block',
    margin: 'auto'
  },
  description: {
    fontFamily: 'Poppins',
    paddingTop: '20px',
    textAlign: 'center',
    color: 'white'
  },
  priceCardContainer: {
    [theme.breakpoints.down('lg')]: {
      padding: '50px 100px 0px'

    },
    [theme.breakpoints.down('md')]: {
      padding: '50px 10px 0px'

    }
  },
  customisationHeading: {
    fontFamily: 'Poppins',
    paddingTop: '100px',
    textAlign: 'center',
    color: 'black',
    opacity: '0.8'
  },
  customisationDescription: {
    fontFamily: 'Poppins',
    paddingTop: '20px',
    textAlign: 'center',
    color: '#545454',
    padding: '20px 25vw 0px'
  },
  customisationPrice: {
    fontFamily: 'Montserrat',
    padding: '50px 0px 50px',
    color: '#545454',
    lineHeight: '30px',
    fontWeight: '500',
    display: 'flex',
    paddingLeft: '5vw'
  },
  customerSupport: {
    width: '120vw',
    backgroundColor: '#C6FF9A'
  },
  customerCareIcon: {
    paddingTop: '50px'
  },
  customerSupportHeading: {
    textAlign: 'center',
    fontSize: '16px',
    fontFamily: 'Montserrat',
    fontWeight: '600',
    color: '#0F0F0F'
  },
  customerSupportSubTitle: {
    textAlign: 'center',
    fontFamily: 'Montserrat',
    fontWeight: '600',
    color: '#0F0F0F',
    lineHeight: '45px',
    [theme.breakpoints.down('lg')]: {
      padding: '30px 30vw 30px',
      fontSize: '35px'
    },
    [theme.breakpoints.down('md')]: {
      padding: '30px 20vw 30px',
      fontSize: '30px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '30px 5vw 30px'
    }
  },
  button: {
    textAlign: 'center',
    border: '3px solid #206D3F',
    borderRadius: '10px',
    color: '#206D3F',
    fontFamily: 'Montserrat',
    marginBottom: '50px',
    '&:hover': {
      boxShadow: '0 10px 17px rgba(0, 0, 0, 0.14)',
      bottom: '1px',
      right: '1px'
    }
  },
  faqContainer: {
    background: 'white',
    padding: '100px 0 100px'
  },
  faqCard: {
    background: '#F6F8FA !important',
    [theme.breakpoints.down('lg')]: {
      padding: '100px',
      width: '80vw'
    },
    [theme.breakpoints.down('md')]: {
      padding: '60px',
      width: '90vw'

    },
    [theme.breakpoints.down('sm')]: {
      padding: '50px 10px 50px',
      width: '100vw'
    }
  }
})
