import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Typography,
  CircularProgress,
  IconButton,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  withStyles,
  Button,
  Grid
} from '@material-ui/core'
import PersonPinCircleIcon from '@material-ui/icons/PersonPinCircle'
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate'
import PrintIcon from '@material-ui/icons/Print'
import UpdateIcon from '@material-ui/icons/Update'
import Modal from 'views/Modal'
import compose from 'recompose/compose'
import moment from 'moment'
import Barcode from 'react-barcode'
import { connect } from 'react-redux'
import ReactToPrint from 'react-to-print'
import TextField from '@material-ui/core/TextField'

import treeChanges from 'tree-changes'
import { STATUS } from 'redux/constants'
import { Progress } from 'components'
import { orderGetDetails, confirmOrder, confirmPacked, confirmDelivery, confirmDelivered, updateDelivery, cancelOrder, showAlert, changePaymentStatus, changeItemQuantity } from 'redux/actions/index'

import styles from './style'
import { ConfirmOrder, ChangePaymentStatus } from '..'

const buttonText = {
  'confirmed': 'Confirm',
  'shop_confirmed': 'Set as Packed',
  'shop_packed': 'Out for Delivery',
  'shop_delivery': 'Mark as delivered'
}

class OrderDetails extends Component {
  state = {
    showConfirmation: false,
    type: null,
    editCount: false,
    products: null
  }
  componentDidMount () {
    this.setState({ isLoading: true })
    if (this.props.orderId) {
      this.props.dispatch(orderGetDetails(this.props.orderId))
    }
  }

  componentWillReceiveProps = newProps => {
    const { changed, changedTo } = treeChanges(this.props, newProps)
    if (changed('orderId') && newProps.orderId) {
      this.setState({ isLoading: true })
      this.props.dispatch(orderGetDetails(newProps.orderId))
    }
    if (changedTo('details.status', STATUS.READY)) {
      this.setState({ isLoading: false })
    }
    if (changedTo('paymentStatus.status', STATUS.READY)) {
      this.setState({ showPayment: false })
      this.props.dispatch(showAlert('Successfully updated the payment', { variant: 'success' }))
    }
  }

  showConfirmation = () => {
    this.setState({ showConfirmation: true })
  }

  hideConfirmation = () => {
    this.setState({ showConfirmation: false, type: null })
  }

  showEditDeliveryTime = () => {
    this.setState({ showConfirmation: true, type: 'edit_delivery' })
  }

  showCancelOrderConfirmation = () => {
    this.setState({ showConfirmation: true, type: 'cancel' })
  }

  onSubmitConfirmation = (message, time, price) => {
    const { status, id } = this.props.details.data
    const { type } = this.state
    if (type) {
      if (type === 'edit_delivery') {
        this.updateDelivery(id, message, time)
      } else {
        this.cancelOrder(id, message)
      }
    } else if (status === 'confirmed') {
      this.confirmOrder(id, message, time, price)
    } else if (status === 'shop_confirmed') {
      this.confirmPacked(id, message)
    } else if (status === 'shop_packed') {
      this.confirmDelivery(id, message)
    } else if (status === 'shop_delivery') {
      this.confirmDelivered(id, message)
    }
    this.setState({ showConfirmation: false })
  }

  showChangePaymentStatus = () => {
    this.setState({ showPayment: true })
  }

  hidePaymentStatus = () => {
    this.setState({ showPayment: false })
  }

  onSubmitPaymentStatus = (remark, amount, transactionId, mode) => {
    const { id } = this.props.details.data
    const request = {
      payment_mode: mode,
      transaction_id: transactionId,
      remark,
      amount
    }
    this.props.dispatch(changePaymentStatus(id, request))
  }

  confirmOrder = (id, message, time, price) => {
    this.props.dispatch(confirmOrder(id, message, time, price))
  }

  confirmPacked = (id, message) => {
    this.props.dispatch(confirmPacked(id, message))
  }

  confirmDelivery = (id, message) => {
    this.props.dispatch(confirmDelivery(id, message))
  }

  confirmDelivered = (id, message) => {
    this.props.dispatch(confirmDelivered(id, message))
  }

  updateDelivery = (id, message, time) => {
    this.props.dispatch(updateDelivery(id, message, time))
  }

  cancelOrder = (id, message) => {
    this.props.dispatch(cancelOrder(id, message))
  }

  editCount = () => {
    if (this.state.editCount) {
      this.setState({ editCount: false })
      this.props.dispatch(orderGetDetails(this.props.orderId))
    } else {
      this.setState({ editCount: true })
      if (this.props.details.data && this.props.details.data.products) {
        this.setState({ products: this.props.details.data.products })
      }
    }
  }

  onUpdateProductCount = (id, count) => {
    const request = {
      item_id: id,
      count: parseInt(count),
      update_stock: false
    }
    this.props.dispatch(changeItemQuantity(this.props.userInfo.shop.id, this.props.orderId, request))
  }

  navigateToLocation = () => {
    const { details, userInfo } = this.props
    const address = details.data.delivery_address
    let shopDirection = `https://www.google.com/maps/dir/@${address.coordinates.lat},${address.coordinates.long},16z`
    if (userInfo.shop && userInfo.shop.address && userInfo.shop.address.coordinates) {
      const shopCoordinates = userInfo.shop.address.coordinates
      shopDirection = `https://www.google.com/maps/dir/${address.coordinates.lat},${address.coordinates.long}/${shopCoordinates.lat},${shopCoordinates.long}`
    }
    let anchor = document.createElement('a')
    anchor.href = shopDirection
    anchor.target = '_blank'
    document.body.appendChild(anchor)
    anchor.click()
  }

  copyPaymentLink = () => {
    const { details } = this.props
    const payment = details.data.payment_details
    const link = payment.payment_link
    let textField = document.createElement('textarea')
    textField.innerText = link
    document.body.appendChild(textField)
    textField.select()
    let success = document.execCommand('copy')
    textField.remove()
    success = success || document.execCommand('copy', false, link)
    if (success) {
      this.props.dispatch(showAlert('Copied payment link to clipboard!', { variant: 'success' }))
    } else {
      this.props.dispatch(showAlert('Failed to copy payment link to clipboard!', { variant: 'error' }))
    }
  }

  copyLocationLink = () => {
    const { details, userInfo } = this.props
    const address = details.data.delivery_address
    let shopDirection = `https://www.google.com/maps/dir/@${address.coordinates.lat},${address.coordinates.long},16z`
    if (userInfo.shop && userInfo.shop.address && userInfo.shop.address.coordinates) {
      const shopCoordinates = userInfo.shop.address.coordinates
      shopDirection = `https://www.google.com/maps/dir/${address.coordinates.lat},${address.coordinates.long}/${shopCoordinates.lat},${shopCoordinates.long}`
    }
    let textField = document.createElement('textarea')
    textField.innerText = shopDirection
    document.body.appendChild(textField)
    textField.select()
    let success = document.execCommand('copy')
    textField.remove()
    success = success || document.execCommand('copy', false, shopDirection)
    if (success) {
      this.props.dispatch(showAlert('Copied location url to clipboard!', { variant: 'success' }))
    } else {
      this.props.dispatch(showAlert('Failed to location url to clipboard!', { variant: 'error' }))
    }
  }

  getPrintingDetails = () => {
    const { classes, details, userInfo } = this.props
    return <div className={`bill ${classes.printBill}`} ref={el => (this.componentRef = el)}>
      <div className={classes.title} >
        <Typography className={classes.titleText} variant='h4' >Order: {details.data.code}</Typography>
        <Typography className={classes.titleText} variant='h4' >{moment(details.data.timestamp).format('D MMM YYYY HH:mm')}</Typography>
      </div>
      <div className={classes.billHeading} >
        <div className={classes.billLeftHeading} >
          <Typography className={classes.invoiceHeading} variant='h2'>Invoice</Typography>
          <Typography className={classes.billSubHeadingText} variant='h4' >{userInfo.shop.name}</Typography>
          <Typography className={classes.billSubHeadingText} variant='p' >{userInfo.shop.phone}</Typography>
        </div>
        <div className={classes.billRightHeading} >
          <Typography className={classes.billSubHeadingText} variant='h4' >{details.data.user_details.name}</Typography>
          <Typography className={classes.billSubHeadingText} variant='p' >{details.data.user_details.phone}</Typography>
          <Typography className={classes.billSubHeadingText} variant='p' >{moment(details.data.confirmed_ts).format('D MMM YYYY HH:mm')}</Typography>
          <Typography className={classes.billSubHeadingText} variant='p' >{moment(details.data.delivery_details.delivery_by).format('D MMM YYYY HH:mm')}</Typography>
          {details.data.user_details.member_id ? <Typography className={classes.billSubHeadingText} variant='p' >{details.data.user_details.member_id}</Typography> : null}
          {details.data.user_details.address.full_address ? <Typography className={classes.billSubHeadingText} variant='p' >{details.data.user_details.address.full_address}</Typography> : null}
          {details.data.user_details.address.landmark ? <Typography className={classes.billSubHeadingText} variant='p' >{details.data.user_details.address.landmark}</Typography> : null}
          {details.data.user_details.address.locality ? <Typography className={classes.billSubHeadingText} variant='p' >{details.data.user_details.address.locality}</Typography> : null}
        </div>
      </div>
      <div className={classes.billBody} >
        <div className={classes.billItemList} >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeaderCell} align='left'>Item Name</TableCell>
                <TableCell className={classes.tableHeaderCell} align='left'>Item Code</TableCell>
                <TableCell className={classes.tableHeaderCell} align='left'>Count</TableCell>
                <TableCell className={classes.tableHeaderCell} align='left'>Price Per Item</TableCell>
                <TableCell className={classes.tableHeaderCell} align='left'>Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {details.data && details.data.products && details.data.products.map(product => (
                <TableRow
                  className={classes.tableRow}
                >
                  <TableCell className={classes.tableCell}>{product.item.name}</TableCell>
                  <TableCell className={classes.tableCell}>{product.item.item_code ? <Barcode value={product.item.item_code} /> : '-'}</TableCell>
                  <TableCell className={classes.tableCell}>{product.count}</TableCell>
                  <TableCell className={classes.tableCell}>{product.item.price}</TableCell>
                  <TableCell className={classes.tableCell}>{parseFloat(product.count * product.item.price).toFixed(2)}</TableCell>
                </TableRow>
              ))
              }
            </TableBody>
          </Table>
        </div>
        <div className={classes.billCharges} >
          <div className={classes.billSubDetailsContainer} >
            <div className={classes.billDetailsCon} >
              <Typography className={classes.billSubHeading} variant='p' >Total Items</Typography>
              <Typography className={classes.billSubitem} variant='p' >{details.data.bill_details.total_products}</Typography>
            </div>
            <div className={classes.billDetailsCon} >
              <Typography className={classes.billSubHeading} variant='p' >Cart Value</Typography>
              <Typography className={classes.billSubitem} variant='p' > {`\u20B9  ${parseFloat(details.data.bill_details.product_price).toFixed(2)}`}</Typography>
            </div>
            <div className={classes.billDetailsCon} >
              <Typography className={classes.billSubHeading} variant='p' >Delivery Charge</Typography>
              <Typography className={classes.billSubitem} variant='p' > {`\u20B9  ${parseFloat(details.data.bill_details.delivery_price).toFixed(2)}`}</Typography>
            </div>
            <div className={classes.billDetailsCon} >
              <Typography className={classes.billSubHeading} variant='p' >Total</Typography>
              <Typography className={classes.billSubitem} variant='p' > {`\u20B9  ${parseFloat(details.data.bill_details.total_price).toFixed(2)}`}</Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  }

  render () {
    const { classes, onSubmit, isOpen, details } = this.props
    const { showConfirmation, type, showPayment } = this.state
    console.log(this.props.userInfo.shop.id)

    const child = <div className={classes.container}>
      {details.status === STATUS.RUNNING ? <div className={classes.progressWrapper}>
        <CircularProgress />
      </div> : null}
      {details.status === STATUS.READY ? <React.Fragment >
        <div className={classes.mainContainer} >
          <Progress status={details.data.status} />
          <div className={classes.detailsContainer} >
            <div className={classes.left}>
              <div className={classes.subDetailsContainer} >
                {details.data && details.data.code ? <div className={classes.deliveryBy} >
                  <Typography className={classes.subHeading} variant='p' >Order Code</Typography>
                  <Typography className={classes.item} variant='p' > {details.data.code}</Typography>
                </div> : null}
                {details.data.delivery_details && details.data.confirmed_ts ? <div className={classes.deliveryBy} >
                  <Typography className={classes.subHeading} variant='p' >Ordered Time</Typography>
                  <Typography className={classes.item} variant='p' > {moment(details.data.confirmed_ts).format('D MMM YYYY HH:mm')}</Typography>
                </div> : null}
                {details.data.delivery_details && details.data.delivery_details.delivery_by ? <div className={classes.deliveryBy} >
                  <Typography className={classes.subHeading} variant='p' >Expected Delivery</Typography>
                  <Typography className={classes.item} variant='p' > {moment(details.data.delivery_details.delivery_by).format('D MMM YYYY HH:mm')}</Typography>
                </div> : null}
                {details.data.delivery_details && details.data.delivery_details.distance ? <div className={classes.deliveryDistance} >
                  <Typography className={classes.subHeading} variant='p' >Distance</Typography>
                  <Typography className={classes.item} variant='p' > {`${parseFloat(details.data.delivery_details.distance / 1000).toFixed(2)} Km`}</Typography>
                </div> : null}
              </div>
              <div className={classes.subDetailsContainer} >
                <Typography className={classes.billTitle} variant='p' >Bill Details</Typography>
                <div className={classes.billDetails} >
                  <Typography className={classes.subHeading} variant='p' >Total Items</Typography>
                  <Typography className={classes.item} variant='p' >{parseFloat(details.data.bill_details.total_products)}</Typography>
                </div>
                <div className={classes.billDetails} >
                  <Typography className={classes.subHeading} variant='p' >Cart Value</Typography>
                  <Typography className={classes.item} variant='p' > {`\u20B9  ${details.data.bill_details.product_price ? parseFloat(details.data.bill_details.product_price).toFixed(2) : 0}`}</Typography>
                </div>
                <div className={classes.billDetails} >
                  <Typography className={classes.subHeading} variant='p' >Delivery Charge</Typography>
                  <Typography className={classes.item} variant='p' > {`\u20B9  ${details.data.bill_details.delivery_price ? parseFloat(details.data.bill_details.delivery_price).toFixed(2) : 0}`}</Typography>
                </div>
                <div className={classes.billDetails} >
                  <Typography className={classes.subHeading} variant='p' >Total</Typography>
                  <Typography className={classes.item} variant='p' > {`\u20B9  ${details.data.bill_details.total_price ? parseFloat(details.data.bill_details.total_price).toFixed(2) : 0}`}</Typography>
                </div>
                {details.data.payment_details && details.data.payment_details.show_status ? (<React.Fragment>
                  <div className={`${classes.billDetails} ${classes.paymentDetails} ${details.data.payment_details.status}`} >
                    <Typography className={classes.subHeading} variant='p' >Payment Status</Typography>
                    <Typography className={classes.item} variant='p' > {details.data.payment_details.status_text}</Typography>
                  </div>
                  <div className={classes.billDetails} >
                    <Typography className={classes.subHeading} variant='p' >Reference ID</Typography>
                    <Typography className={classes.item} variant='p' > {details.data.payment_details.reference_id || '-'}</Typography>
                  </div>
                  <div className={classes.billDetails} >
                    <Typography className={classes.subHeading} variant='p' >Transaction ID</Typography>
                    <Typography className={classes.item} variant='p' > {details.data.payment_details.transaction_id || '-'}</Typography>
                  </div>
                  <div className={classes.billDetails} >
                    <Typography className={classes.subHeading} variant='p' >Paid TS</Typography>
                    <Typography className={classes.item} variant='p' > {details.data.payment_details.paid_ts ? moment(details.data.payment_details.paid_ts).format('D MMM YYYY HH:mm') : '-'}</Typography>
                  </div>
                  <div className={classes.billDetails} >
                    <Typography className={classes.subHeading} variant='p' >PG Status</Typography>
                    <Typography className={classes.item} variant='p' > {details.data.payment_details.pg_status_text || '-'}</Typography>
                  </div>
                  <div className={classes.billDetails} >
                    <Typography className={classes.subHeading} variant='p' >Payment Mode</Typography>
                    <Typography className={classes.item} variant='p' > {details.data.payment_details.payment_mode_pg || '-'}</Typography>
                  </div>
                </React.Fragment>) : null}
                {details.data.payment_details && details.data.payment_details.payment_type === 'online' && details.data.payment_details.payment_link ? <Button color='primary' className={classes.paymentLinkButton} variant='contained' onClick={this.copyPaymentLink} > Copy Payment Link </Button> : null}
              </div>
            </div>
            <div className={classes.right}>
              <div className={classes.subDetailsContainer} >
                <Typography className={classes.customerHeading} variant='p' >Customer Details</Typography>
                <div className={classes.customerDetails} >
                  <Typography className={classes.subCustomerDetails} variant='p' >{details.data.user_details.name}</Typography>
                  <Typography className={classes.subCustomerDetails} variant='p' >{details.data.user_details.phone}</Typography>
                  {details.data.user_details.member_id ? <Typography className={classes.subCustomerDetails} variant='p' >{details.data.user_details.member_id}</Typography> : null}
                  <div className={classes.address} >
                    <Typography className={classes.subAddress} variant='p' >{details.data.delivery_address.name}</Typography>landmark
                    <Typography className={classes.subAddress} variant='p' >{details.data.delivery_address.full_address}</Typography>
                    <Typography className={classes.subAddress} variant='p' >{details.data.delivery_address.landmark}</Typography>
                    <Typography className={classes.subAddress} variant='p' >{details.data.delivery_address.locality}</Typography>
                  </div>
                </div>
              </div>
              <div className={classes.locationButtons} >
                <Button color='primary' className={classes.locationButton} variant='contained' onClick={this.navigateToLocation} > View Location </Button>
                <Button color='secondary' className={classes.locationButton} variant='contained' onClick={this.copyLocationLink} > Copy
                  <PersonPinCircleIcon className={classes.location} fontSize='large' />
                </Button>
              </div>
              {details.data.payment_details && details.data.payment_details.status === 'not_paid' ? <div className={classes.transactionButton} >
                <Button color='primary' variant='contained' onClick={this.showChangePaymentStatus} > Update Payment </Button>
              </div> : null}
            </div>
          </div>
        </div>

        <div className={classes.bill}>
          <Grid container justify='space-between'>
            <Grid xs={9}>
              <Typography className={classes.productHeading} variant='p' > Products </Typography>
            </Grid>
            <Grid xs={3}>
              {this.props.details.data.status === 'confirmed' ? <Button size='small' className={classes.editButton} onClick={this.editCount}>
                {this.state.editCount ? 'DONE' : 'EDIT'}
              </Button> : null}
            </Grid>
          </Grid>

          <div className={classes.products} >
            {details.data && details.data.products && details.data.products.map((product, key) => (
              <div className={classes.productCell}>
                <Typography className={classes.cellItem} variant='body1' > {product.item.name} </Typography>
                {!this.state.editCount
                  ? <Typography className={classes.cellItem} variant='body1' > {`X ${product.count}`}</Typography>
                  : <TextField
                    id='outlined-number'
                    type='number'
                    size='small'
                    style={{ width: '40px' }}
                    margin='none'
                    value={product.count}
                    onChange={(e) => this.onUpdateProductCount(product.item.id, e.target.value)}
                    InputLabelProps={{
                      shrink: true
                    }}
                    InputProps={{ inputProps: { min: 0 } }}
                  />
                }
              </div>
            ))}
          </div>
          <div className={classes.productPrice}>
            <Typography className={classes.subHeading} variant='body1' > Total Price </Typography>
            <Typography className={classes.item} variant='body1' >{`\u20B9 ${details.data.bill_details.total_price ? details.data.bill_details.total_price.toFixed(2) : 0}`}</Typography>
          </div>
        </div>

      </React.Fragment> : null
      }
      <ChangePaymentStatus isOpen={showPayment} onCancel={this.hidePaymentStatus} onSubmit={this.onSubmitPaymentStatus} />
      <ConfirmOrder isOpen={showConfirmation} price={details.data.bill_details ? details.data.bill_details.product_price : 0} delivery={details.data.delivery_details ? details.data.delivery_details.delivery_by : null} type={type || details.data.status} onCancel={this.hideConfirmation} onSubmit={this.onSubmitConfirmation} />
    </div >
    const isValidStatus = buttonText[details.data.status]
    const footer = <div className={classes.action} >
      {isValidStatus ? <Button color='primary' variant='outlined' onClick={this.showConfirmation} > {buttonText[details.data.status]} </Button> : null}
      <div className={classes.iconButtons} >
        <ReactToPrint
          trigger={() =>
            <Tooltip title='Print Bill'>
              <IconButton color='primary' aria-label='cancel' size='small'>
                <PrintIcon className={classes.footerIcons} style={{ color: '#66788A' }} />
              </IconButton>
            </Tooltip>
          }
          content={() => this.componentRef}
        />
        {isValidStatus ? <Tooltip title='Edit Delivery Time'>
          <IconButton color='primary' onClick={this.showEditDeliveryTime} aria-label='edit' size='small'>
            <UpdateIcon className={classes.footerIcons} style={{ color: '#66788A' }} />
          </IconButton>
        </Tooltip> : null}
        {isValidStatus ? <Tooltip title='Cancel Order'>
          <IconButton color='primary' onClick={this.showCancelOrderConfirmation} aria-label='cancel' size='small'>
            <AssignmentLateIcon className={classes.footerIcons} style={{ color: '#66788A' }} />
          </IconButton>
        </Tooltip> : null}
        <Button color='primary' className={classes.closeButton} variant='contained' onClick={onSubmit} > Close </Button>
      </div>
      {details.data && details.data.id ? this.getPrintingDetails() : null}
    </div>
    return (
      <Modal isOpen={isOpen} hideCancel width={1000} title={`Order Details`} okText={'Done'} footer={footer} onSubmit={onSubmit} child={child} />
    )
  }
}

OrderDetails.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired
}

function mapStateToProps (state) {
  return {
    details: state.orders.details,
    paymentStatus: state.orders.paymentStatus,
    userInfo: state.user.userInfo
  }
}

export default compose(
  withStyles(styles)
)(connect(mapStateToProps)(OrderDetails))
