import { handleActions } from 'redux-actions'
import immutable from 'immutability-helper'
import _ from 'lodash'
import { parseError } from 'services/client'

import { ActionTypes, STATUS } from 'redux/constants/index'

export const orderState = {
  list: {
    message: '',
    data: [],
    status: STATUS.IDLE
  },
  completedOrders: {
    message: '',
    data: [],
    status: STATUS.IDLE
  },
  details: {
    message: '',
    data: {},
    status: STATUS.IDLE
  },
  confirm: {
    message: '',
    data: [],
    status: STATUS.IDLE
  },
  packed: {
    message: '',
    data: [],
    status: STATUS.IDLE
  },
  delivery: {
    message: '',
    data: [],
    status: STATUS.IDLE
  },
  delivered: {
    message: '',
    data: [],
    status: STATUS.IDLE
  },
  cancelOrder: {
    message: '',
    data: [],
    status: STATUS.IDLE
  },
  changedDelivery: {
    message: '',
    data: [],
    status: STATUS.IDLE
  },
  locations: {
    message: '',
    data: [],
    status: STATUS.IDLE
  },
  paymentStatus: {
    message: '',
    data: [],
    status: STATUS.IDLE
  },
  itemUpdateStatus: {
    message: '',
    data: [],
    status: STATUS.IDLE
  }
}

export default {
  orders: handleActions(
    {
      [ActionTypes.ORDER_GET_LIST]: (state, { payload }) =>
        immutable(state, {
          list: {
            status: { $set: STATUS.RUNNING },
            searchParam: { $set: payload },
            reachedEnd: { $set: false }
          }
        }),
      [ActionTypes.ORDER_GET_LIST_SUCCESS]: (state, { payload }) => {
        let data = _.cloneDeep(state.list.data)
        if (state.list.searchParam && state.list.searchParam.skip === 0) {
          data = payload
        } else {
          data = [...data, ...payload]
        }
        return immutable(state, {
          list: {
            data: { $set: data },
            status: { $set: STATUS.READY },
            reachedEnd: { $set: !payload.length || payload.length !== state.list.searchParam.limit }
          }
        })
      },
      [ActionTypes.ORDER_GET_LIST_FAILURE]: (state, { payload }) =>
        immutable(state, {
          list: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        }),

      [ActionTypes.COMPLETED_ORDER_GET_LIST]: (state, { payload }) =>
        immutable(state, {
          completedOrders: {
            status: { $set: STATUS.RUNNING },
            searchParam: { $set: payload },
            reachedEnd: { $set: false }
          }
        }),
      [ActionTypes.COMPLETED_ORDER_GET_LIST_SUCCESS]: (state, { payload }) => {
        let data = _.cloneDeep(state.completedOrders.data)
        if (state.completedOrders.searchParam && state.completedOrders.searchParam.skip === 0) {
          data = payload
        } else {
          data = [...data, ...payload]
        }
        return immutable(state, {
          completedOrders: {
            data: { $set: data },
            status: { $set: STATUS.READY },
            reachedEnd: { $set: !payload.length || payload.length !== state.completedOrders.searchParam.limit }
          }
        })
      },
      [ActionTypes.COMPLETED_ORDER_GET_LIST_FAILURE]: (state, { payload }) =>
        immutable(state, {
          completedOrders: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        }),

      [ActionTypes.ORDER_GET_DETAILS]: (state, { payload }) =>
        immutable(state, {
          details: {
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.ORDER_GET_DETAILS_SUCCESS]: (state, { payload }) => {
        let data = _.cloneDeep(state.list.data)
        const index = data.findIndex(item => item.id === payload.id)
        if (index > -1 && data[index].is_new) {
          data[index].is_new = false
        }
        return immutable(state, {
          list: {
            data: { $set: data }
          },
          details: {
            data: { $set: payload },
            status: { $set: STATUS.READY }
          }
        })
      },
      [ActionTypes.ORDER_GET_DETAILS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          details: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        }),

      [ActionTypes.CONFIRM_ORDER]: (state, { payload }) =>
        immutable(state, {
          confirm: {
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.CONFIRM_ORDER_SUCCESS]: (state, { payload }) => {
        let listData = _.cloneDeep(state.list.data)
        const index = listData.findIndex(item => item.id === payload.id)
        if (index > -1) {
          listData.splice(index, 1, payload)
        }
        let orderData = _.cloneDeep(state.details.data)
        if (orderData.id === payload.id) {
          orderData = payload
        }
        return immutable(state, {
          list: {
            data: { $set: listData },
            status: { $set: STATUS.READY }
          },
          details: {
            data: { $set: orderData },
            status: { $set: state.details.status }
          },
          confirm: {
            data: { $set: payload },
            status: { $set: STATUS.READY }
          }
        })
      },
      [ActionTypes.CONFIRM_ORDER_FAILURE]: (state, { payload }) =>
        immutable(state, {
          confirm: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        }),

      [ActionTypes.CONFIRM_PACKED]: (state, { payload }) =>
        immutable(state, {
          packed: {
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.CONFIRM_PACKED_SUCCESS]: (state, { payload }) => {
        let listData = _.cloneDeep(state.list.data)
        const index = listData.findIndex(item => item.id === payload.id)
        if (index > -1) {
          listData.splice(index, 1, payload)
        }
        let orderData = _.cloneDeep(state.details.data)
        if (orderData.id === payload.id) {
          orderData = payload
        }
        return immutable(state, {
          list: {
            data: { $set: listData },
            status: { $set: STATUS.READY }
          },
          details: {
            data: { $set: orderData },
            status: { $set: state.details.status }
          },
          packed: {
            data: { $set: payload },
            status: { $set: STATUS.READY }
          }
        })
      },
      [ActionTypes.CONFIRM_PACKED_FAILURE]: (state, { payload }) =>
        immutable(state, {
          packed: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        }),

      [ActionTypes.CONFIRM_DELIVERY]: (state, { payload }) =>
        immutable(state, {
          delivery: {
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.CONFIRM_DELIVERY_SUCCESS]: (state, { payload }) => {
        let listData = _.cloneDeep(state.list.data)
        const index = listData.findIndex(item => item.id === payload.id)
        if (index > -1) {
          listData.splice(index, 1, payload)
        }
        let orderData = _.cloneDeep(state.details.data)
        if (orderData.id === payload.id) {
          orderData = payload
        }
        return immutable(state, {
          list: {
            data: { $set: listData },
            status: { $set: STATUS.READY }
          },
          details: {
            data: { $set: orderData },
            status: { $set: state.details.status }
          },
          delivery: {
            data: { $set: payload },
            status: { $set: STATUS.READY }
          }
        })
      },
      [ActionTypes.CONFIRM_DELIVERY_FAILURE]: (state, { payload }) =>
        immutable(state, {
          delivery: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        }),

      [ActionTypes.CONFIRM_DELIVERED]: (state, { payload }) =>
        immutable(state, {
          delivered: {
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.CONFIRM_DELIVERED_SUCCESS]: (state, { payload }) => {
        let listData = _.cloneDeep(state.list.data)
        const index = listData.findIndex(item => item.id === payload.id)
        if (index > -1) {
          listData.splice(index, 1)
        }
        let orderData = _.cloneDeep(state.details.data)
        if (orderData.id === payload.id) {
          orderData = payload
        }
        return immutable(state, {
          list: {
            data: { $set: listData },
            status: { $set: STATUS.READY }
          },
          details: {
            data: { $set: orderData },
            status: { $set: state.details.status }
          },
          delivered: {
            data: { $set: payload },
            status: { $set: STATUS.READY }
          }
        })
      },
      [ActionTypes.CONFIRM_DELIVERED_FAILURE]: (state, { payload }) =>
        immutable(state, {
          delivered: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        }),

      [ActionTypes.UPDATE_DELIVERY]: (state, { payload }) =>
        immutable(state, {
          changedDelivery: {
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.UPDATE_DELIVERY_SUCCESS]: (state, { payload }) => {
        let listData = _.cloneDeep(state.list.data)
        const index = listData.findIndex(item => item.id === payload.id)
        if (index > -1) {
          listData.splice(index, 1, payload)
        }
        let orderData = _.cloneDeep(state.details.data)
        if (orderData.id === payload.id) {
          orderData = payload
        }
        return immutable(state, {
          list: {
            data: { $set: listData },
            status: { $set: STATUS.READY }
          },
          details: {
            data: { $set: orderData },
            status: { $set: state.details.status }
          },
          changedDelivery: {
            data: { $set: payload },
            status: { $set: STATUS.READY }
          }
        })
      },
      [ActionTypes.UPDATE_DELIVERY_FAILURE]: (state, { payload }) =>
        immutable(state, {
          changedDelivery: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        }),

      [ActionTypes.CANCEL_ORDER]: (state, { payload }) =>
        immutable(state, {
          cancelOrder: {
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.CANCEL_ORDER_SUCCESS]: (state, { payload }) => {
        let listData = _.cloneDeep(state.list.data)
        const index = listData.findIndex(item => item.id === payload.id)
        if (index > -1) {
          listData.splice(index, 1)
        }
        let orderData = _.cloneDeep(state.details.data)
        if (orderData.id === payload.id) {
          orderData = payload
        }
        return immutable(state, {
          list: {
            data: { $set: listData },
            status: { $set: STATUS.READY }
          },
          details: {
            data: { $set: orderData },
            status: { $set: state.details.status }
          },
          cancelOrder: {
            data: { $set: payload },
            status: { $set: STATUS.READY }
          }
        })
      },
      [ActionTypes.CANCEL_ORDER_FAILURE]: (state, { payload }) =>
        immutable(state, {
          cancelOrder: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        }),

      [ActionTypes.GET_ORDER_LOCATION]: (state, { payload }) =>
        immutable(state, {
          locations: {
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.GET_ORDER_LOCATION_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          locations: {
            data: { $set: payload },
            status: { $set: STATUS.READY }
          }
        })
      },
      [ActionTypes.GET_ORDER_LOCATION_FAILURE]: (state, { payload }) =>
        immutable(state, {
          locations: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        }),

      [ActionTypes.CHANGE_PAYMENT_STATUS]: (state, { payload }) =>
        immutable(state, {
          paymentStatus: {
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.CHANGE_PAYMENT_STATUS_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          details: {
            data: { $set: payload },
            status: { $set: STATUS.READY }
          },
          paymentStatus: {
            data: { $set: payload },
            status: { $set: STATUS.READY }
          }
        })
      },
      [ActionTypes.CHANGE_PAYMENT_STATUS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          paymentStatus: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        }),

      [ActionTypes.CHANGE_ITEM_QUANTITY]: (state, { payload }) =>
        immutable(state, {
          itemUpdateStatus: {
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.CHANGE_ITEM_QUANTITY_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          details: {
            data: { $set: payload },
            status: { $set: STATUS.READY }
          },
          itemUpdateStatus: {
            data: { $set: payload },
            status: { $set: STATUS.READY }
          }
        })
      },
      [ActionTypes.CHANGE_ITEM_QUANTITY_FAILURE]: (state, { payload }) =>
        immutable(state, {
          itemUpdateStatus: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        })

    },
    orderState
  )
}
