import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles, IconButton } from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'

import { getAllCategories } from 'redux/actions/index'
import Select from 'components/Select'
import { STATUS } from 'redux/constants'

const styles = theme => ({
  container: {
    display: 'flex',
    justifyItems: 'center'
  },
  selectContainer: {
    padding: 15,
    width: '100%'
  },
  button: {
    padding: 10,
    paddingTop: 28
  }
})

class CategorySelect extends Component {
  componentWillMount = () => {
    if (this.props && this.props.allCategories && this.props.allCategories.status === STATUS.IDLE) {
      this.props.dispatch(getAllCategories())
    }
  }

  handleChange = (e, level) => {
    const { id, handleCategoryChange, allCategories, categoryData } = this.props
    const { value } = e.target
    let category
    if (level === 1) {
      const index = allCategories.data.findIndex(item => item.id === value)
      category = allCategories.data[index]
    } else if (level === 2) {
      const index = allCategories.data.findIndex(item => item.id === categoryData[1].id)
      if (index > -1) {
        const secondIndex = allCategories.data[index].sub_categories.findIndex(item => item.id === value)
        if (secondIndex > -1) {
          category = allCategories.data[index].sub_categories[secondIndex]
        }
      }
    } else {
      const index = allCategories.data.findIndex(item => item.id === categoryData[1].id)
      if (index > -1) {
        const secondIndex = allCategories.data[index].sub_categories.findIndex(item => item.id === categoryData[2].id)
        if (secondIndex > -1) {
          const thirdIndex = allCategories.data[index].sub_categories[secondIndex].sub_categories.findIndex(item => item.id === value)
          if (thirdIndex > -1) {
            category = allCategories.data[index].sub_categories[secondIndex].sub_categories[thirdIndex]
          }
        }
      }
    }
    handleCategoryChange(id, category, level)
  }

  getSelectedSecondLevelCategories = () => {
    const { allCategories, categoryData } = this.props
    if (categoryData[1]) {
      const index = allCategories.data.findIndex(item => item.id === categoryData[1].id)
      if (index > -1) {
        return allCategories.data[index].sub_categories
      }
    }
    return []
  }

  getSelectedThirdLevelCategories = () => {
    const { allCategories, categoryData } = this.props
    if (categoryData[1] && categoryData[2]) {
      const index = allCategories.data.findIndex(item => item.id === categoryData[1].id)
      if (index > -1) {
        const itemIndex = allCategories.data[index].sub_categories.findIndex(item => item.id === categoryData[2].id)
        if (itemIndex > -1) {
          return allCategories.data[index].sub_categories[itemIndex].sub_categories
        }
      }
    }
    return []
  }

  render () {
    const { classes, onCloseCategory, allCategories, categoryData } = this.props
    const secondLevelptions = this.getSelectedSecondLevelCategories()
    const thirdLevelptions = this.getSelectedThirdLevelCategories()
    return (
      <div className={classes.container}>
        <div className={classes.selectContainer} >
          <Select placeholder='Categroy Level 1' options={allCategories.data} valueParam='name' keyParam='id' selectedValue={categoryData[1] ? categoryData[1].id : undefined} handleChange={(e) => this.handleChange(e, 1)} />
        </div>
        <div className={classes.selectContainer} >
          <Select placeholder='Categroy Level 2' options={secondLevelptions} valueParam='name' keyParam='id' selectedValue={categoryData[2] ? categoryData[2].id : undefined} handleChange={(e) => this.handleChange(e, 2)} />
        </div>
        <div className={classes.selectContainer} >
          <Select placeholder='Categroy Level 3' options={thirdLevelptions} valueParam='name' keyParam='id' selectedValue={categoryData[3] ? categoryData[3].id : undefined} handleChange={(e) => this.handleChange(e, 3)} />
        </div>
        <div className={classes.button}>
          <IconButton aria-label='delete' onClick={onCloseCategory}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>
    )
  }
}

CategorySelect.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
}

function mapStateToProps (state) {
  return {
    allCategories: state.category.allCategories
  }
}

export default withStyles(styles)(connect(mapStateToProps)(CategorySelect))
