import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Modal from 'views/Modal'
import treeChanges from 'tree-changes'

const styles = theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  valueContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  textField: {
    margin: 0,
    width: 'calc( 100% - 80px)',
    marginLeft: 20
  },
  fullWidth: {
    width: '100%',
    marginTop: 30,
    marginLeft: 0
  },
  autocomplete: {
    width: '100%',
    marginTop: 30,
    marginLeft: 0
    // '& .MuiInputBase-root': {
    //   padding: 0,
    //   paddingLeft: 9
    // },
    // '& .MuiInputLabel-outlined': {
    //   transform: 'translate(14px, 10px) scale(1)'
    // }
  },
  error: {
    color: 'red'
  },
  code: {
    width: 60,
    margin: 0
  }
})

class AddPartner extends Component {
    state = {
      phone: '',
      code: '+91',
      memberId: '',
      branchId: ''
    }

    componentWillReceiveProps = nextProps => {
      const { changed } = treeChanges(this.props, nextProps)
      if (changed('partner')) {
        if (nextProps.partner) {
          this.setState({ phone: nextProps.partner.phone_with_code ? nextProps.partner.phone_with_code.replace('+91', '') : '', memberId: nextProps.partner.member_id, branchId: nextProps.partner.branch_id })
        } else {
          this.setState({ phone: '', memberId: null })
        }
      }
    }

    changedStation = (e, value) => {
      this.setState({ branchId: value ? value.id : '' })
    }

    handleChange = (field, e) => {
      const { value } = e.target
      this.setState({ [field]: value })
    }

    onSubmit = () => {
      const { phone, memberId, branchId } = this.state
      const { onSubmit, partner } = this.props
      this.setState({ phone: '', memberId: '', branchId: '' })
      onSubmit(phone, memberId, branchId, partner ? partner.id : undefined)
    }

    render () {
      const { classes, onCancel, isOpen, partner, stations } = this.props
      const { phone, code, memberId, branchId } = this.state
      let selectedStationName
      const index = (stations || []).findIndex(item => item.id === branchId)
      if (index > -1) {
        selectedStationName = stations[index]
      }
      const child = <React.Fragment>
        <div className={classes.container}>
          <TextField
            className={classes.code}
            margin='dense'
            onChange={(e) => this.handleChange('code', e)}
            value={code}
            variant='outlined'
            disabled
          />
          <TextField
            className={classes.textField}
            label={'Phone Number'}
            margin='dense'
            onChange={(e) => this.handleChange('phone', e)}
            value={phone}
            disabled={!!partner}
            variant='outlined'
          />
        </div>
        <div className={classes.valueContainer}>
          <TextField
            className={`${classes.textField} ${classes.fullWidth}`}
            label={'Mermber ID'}
            margin='dense'
            onChange={(e) => this.handleChange('memberId', e)}
            value={memberId}
            variant='outlined'
          />
          <Autocomplete
            className={classes.autocomplete}
            options={stations}
            value={selectedStationName}
            onChange={this.changedStation}
            noOptionsText={'No Stations found'}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => <TextField {...params} label='Station' variant='outlined' />}
          />
        </div>
      </React.Fragment>
      return (
        <Modal isOpen={isOpen} title={partner ? 'Edit Member' : 'Add Member'} onCancel={onCancel} okText={'ADD'} onSubmit={this.onSubmit} child={child} />
      )
    }
}

AddPartner.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired
}

export default withStyles(styles)(AddPartner)
