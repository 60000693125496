export default theme => ({
  root: {
    // paddingTop: 20
    '&> div': {
      height: 'calc( 100vh - 233px )',
      overflow: 'auto'
    }
  },
  tableRow: {
    height: '64px'
  },
  tableCell: {
    whiteSpace: 'nowrap'
  },
  tableCellInner: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    display: 'inline-flex',
    fontSize: '14px',
    fontWeight: 500,
    height: '36px',
    width: '36px'
  },
  nameText: {
    display: 'inline-block',
    marginLeft: theme.spacing.unit * 2,
    fontWeight: 500,
    cursor: 'pointer'
  },
  progressWrapper: {
    width: '100%',
    top: 200,
    left: 0,
    right: 0,
    paddingBottom: '24px',
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute'
  },
  refreshButton: {
    marginLeft: 20
  },
  dateContainer: {
    width: 300,
    // display: 'flex',
    '& .react-datetime-picker': {
      width: '70%',
      height: 35,
      '& > div': {
        borderRadius: '2px'
      }
    }
  },
  textField: {
    width: '70%',
    height: 35,
    marginTop: -20
  },
  filterContainer: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit * 2
  },
  spacer: {
    flexGrow: 1
  },
  searchInput: {
    marginRight: theme.spacing.unit
  },
  firstButton: {
    marginRight: 20
  }
})
