import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles, Grid, CircularProgress, Typography } from '@material-ui/core'
import compose from 'recompose/compose'
import { connect } from 'react-redux'

import { STATUS } from 'redux/constants/index'
import { shopGetList } from 'redux/actions/index'
import PerfectScrollbar from 'react-perfect-scrollbar'
import treeChanges from 'tree-changes'
import { ShopListCell } from '..'

const itemsPerPage = 20

const styles = theme => ({
  root: {
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit}px`,
    maxHeight: '50vh',
    overflowY: 'auto',
    paddingBottom: 0
  },
  progressWrapper: {
    position: 'absolute',
    top: '48px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    '&>div': {
      zIndex: 100
    }
  },
  innerContainer: {
    padding: 0,
    margin: 0,
    width: '100%'
  },
  rootcontainer: {
    marginTop: 20,
    position: 'relative',
    width: '100%'
  },
  filterContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    marginTop: 10,
    paddingLeft: 20,
    width: '100%',
    '& > div': {
      width: 180,
      marginRight: 20
    }
  }
})

class ShopList extends Component {
  state = { tabIndex: 0, page: 0, status: 'all', selectedShops: [] };

  componentDidMount () {
    this.signal = true
    this.setState({ isLoading: true })
    if (this.props.shopList.status === STATUS.IDLE) {
      this.props.dispatch(shopGetList(this.props.groupId, 0, itemsPerPage))
    }
  }

  componentWillReceiveProps = newProps => {
    const { changedTo } = treeChanges(this.props, newProps)
    if (changedTo('shopList.status', STATUS.READY)) {
      this.setState({ isLoading: false })
    }
  }

  handleFieldChange = (field, value) => {
    this.setState({ [field]: value })
  }

  onClickSearch = () => {
    const { isLoading, status } = this.state
    if (!isLoading) {
      this.setState({ page: 0, isLoading: true }, () => {
        this.props.dispatch(shopGetList(this.props.groupId, 0, itemsPerPage, status))
      })
    }
  }

  onReachedBottom = () => {
    const { shopList } = this.props
    const { isLoading, page, status } = this.state
    const nextIndex = (page + 1) * itemsPerPage
    if (!isLoading && !shopList.reachedEnd) {
      this.setState({ page: page + 1, isLoading: true }, () => {
        this.props.dispatch(shopGetList(this.props.groupId, nextIndex, itemsPerPage, status))
      })
    }
  }

  render () {
    const { classes, shopList, selectedShops } = this.props
    return (
      <div className={classes.rootcontainer} >
        {shopList.status === STATUS.RUNNING ? <div className={classes.progressWrapper}>
          <CircularProgress />
        </div> : null}
        {shopList.data.length === 0 && shopList.status === STATUS.READY
          ? <div className={classes.progressWrapper}>
            <Typography variant='h6'>There are no shops.</Typography>
          </div> : <Typography className={classes.selectTitle} variant='h4'>Select any shop</Typography>}
        {/* <div className={classes.filterContainer} >
          <Select placeholder='Status' options={statusList} valueParam='value' keyParam='id' selectedValue={status} handleChange={(event) => this.handleFieldChange('status', event.target.value)} />
          <Button className={classes.searchButton} color='primary' size='small' variant='outlined' onClick={this.onClickSearch} >
            Search
          </Button>
        </div> */}
        <div className={classes.root}>
          {shopList.data.length ? <PerfectScrollbar onYReachEnd={this.onReachedBottom}>
            <Grid
              container
              className={classes.innerContainer}
            >
              {shopList.data.map(shop => (
                <Grid
                  item
                  key={shop.id}
                  xs={12}
                >
                  <ShopListCell shop={shop} selectedShops={selectedShops} onSelectShop={this.props.onSelectShop} />
                </Grid>
              ))}
            </Grid>
          </PerfectScrollbar> : null}
        </div>
      </div>
    )
  }
}

ShopList.propTypes = {
  classes: PropTypes.object.isRequired
}

function mapStateToProps (state) {
  return {
    shopList: state.app.shopList
  }
}

export default compose(
  withStyles(styles)
)(connect(mapStateToProps)(ShopList))
