import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import InputIcon from '@material-ui/icons/Input'
import { withStyles, Typography, Tooltip, IconButton } from '@material-ui/core'
import Modal from 'views/Modal'
import { showAlert } from 'redux/actions'

const styles = theme => ({
  container: {
    '& button': {
      display: 'none'
    }
  },
  sub: {
    display: 'flex',
    justifyContent: 'space-between',
    // marginBottom: 20,
    padding: '10px 10px',
    // border: '1px solid gray',
    borderRadius: 2
  },
  title: {
    lineHeight: '28px'
  }

})

class ContactDetailsModal extends Component {
    copyContact = (type) => {
      let link = 'info@shopsapp.net'
      if (type === 'phone') {
        link = '+918089311548'
      }
      let textField = document.createElement('textarea')
      textField.innerText = link
      document.body.appendChild(textField)
      textField.select()
      document.execCommand('copy')
      textField.remove()
      document.execCommand('copy', false, link)
      this.props.dispatch(showAlert(`Copied ${type === 'phone' ? 'Phone Number' : 'Email Address'} Successfully`, { variant: 'success' }))
    }

    render () {
      const { classes, onCancel, isOpen } = this.props
      const child = <div className={classes.container}>
        <div className={classes.sub} >
          <Typography className={classes.title} variant='p'>Phone: +91 7736043121</Typography>
          <Tooltip title='Copy Phone Number'>
            <IconButton color='primary' aria-label='cancel' size='small'>
              <InputIcon className={classes.footerIcons} onClick={this.copyContact} style={{ color: '#66788A' }} />
            </IconButton>
          </Tooltip>
        </div>
        <div className={classes.sub} >
          <Typography className={classes.title} variant='p'>Email: info@shopsapp.net</Typography>
          <Tooltip title='Copy Email Address'>
            <IconButton color='primary' aria-label='cancel' size='small'>
              <InputIcon className={classes.footerIcons} onClick={() => this.copyContact('email')} style={{ color: '#66788A' }} />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      return (
        <Modal isOpen={isOpen} title={'Contact ShopsApp'} onCancel={onCancel} okText={'Done'} onSubmit={onCancel} child={child} />
      )
    }
}

ContactDetailsModal.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired
}

function mapStateToProps (state) {
  return {
  }
}

export default withStyles(styles)(connect(mapStateToProps)(ContactDetailsModal))
