import { handleActions } from 'redux-actions'
import immutable from 'immutability-helper'
import _ from 'lodash'
import { parseError } from 'services/client'

import { ActionTypes, STATUS } from 'redux/constants/index'

export const cadetsState = {
  partners: {
    data: [],
    message: '',
    status: STATUS.IDLE,
    searchParam: {}
  },
  addPartner: {
    data: {},
    message: '',
    status: STATUS.IDLE
  },
  editPartner: {
    data: {},
    message: '',
    status: STATUS.IDLE
  },
  deletePartner: {
    data: {},
    message: '',
    status: STATUS.IDLE
  },
  stations: {
    data: [],
    message: '',
    status: STATUS.IDLE,
    searchParam: {}
  },
  addStation: {
    data: {},
    message: '',
    status: STATUS.IDLE
  },
  editStation: {
    data: {},
    message: '',
    status: STATUS.IDLE
  },
  deleteStation: {
    data: {},
    message: '',
    status: STATUS.IDLE
  },
  totalCount: {
    data: 0,
    message: '',
    status: STATUS.IDLE
  }
}

export default {
  cadets: handleActions(
    {
      [ActionTypes.GET_CADETS]: (state, { payload }) =>
        immutable(state, {
          partners: {
            status: { $set: STATUS.RUNNING },
            searchParam: { $set: payload },
            reachedEnd: { $set: false }
          }
        }),
      [ActionTypes.GET_CADETS_SUCCESS]: (state, { payload }) => {
        let data = _.cloneDeep(state.partners.data)
        if (state.partners.searchParam && state.partners.searchParam.skip === 0) {
          data = payload
        } else {
          data = [ ...data, ...payload ]
        }
        return immutable(state, {
          partners: {
            data: { $set: data },
            status: { $set: STATUS.READY },
            reachedEnd: { $set: !payload.length || payload.length !== state.partners.searchParam.limit }
          }
        })
      },
      [ActionTypes.GET_CADETS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          partners: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        }),

      [ActionTypes.CREATE_PARTNER]: (state, { payload }) =>
        immutable(state, {
          addPartner: {
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.CREATE_PARTNER_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          addPartner: {
            data: { $set: payload },
            status: { $set: STATUS.READY }
          }
        })
      },
      [ActionTypes.CREATE_PARTNER_FAILURE]: (state, { payload }) =>
        immutable(state, {
          addPartner: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        }),

      [ActionTypes.EDIT_PARTNER]: (state, { payload }) =>
        immutable(state, {
          editPartner: {
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.EDIT_PARTNER_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          editPartner: {
            data: { $set: payload },
            status: { $set: STATUS.READY }
          }
        })
      },
      [ActionTypes.EDIT_PARTNER_FAILURE]: (state, { payload }) =>
        immutable(state, {
          editPartner: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        }),

      [ActionTypes.DELETE_PARTNER]: (state, { payload }) =>
        immutable(state, {
          deletePartner: {
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.DELETE_PARTNER_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          deletePartner: {
            data: { $set: payload },
            status: { $set: STATUS.READY }
          }
        })
      },
      [ActionTypes.DELETE_PARTNER_FAILURE]: (state, { payload }) =>
        immutable(state, {
          deletePartner: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        }),

      [ActionTypes.GET_STATIONS]: (state, { payload }) =>
        immutable(state, {
          stations: {
            status: { $set: STATUS.RUNNING },
            searchParam: { $set: payload },
            reachedEnd: { $set: false }
          }
        }),
      [ActionTypes.GET_STATIONS_SUCCESS]: (state, { payload }) => {
        let data = _.cloneDeep(state.stations.data)
        if (state.stations.searchParam && state.stations.searchParam.skip === 0) {
          data = payload
        } else {
          data = [ ...data, ...payload ]
        }
        return immutable(state, {
          stations: {
            data: { $set: data },
            status: { $set: STATUS.READY },
            reachedEnd: { $set: !payload.length || payload.length !== state.stations.searchParam.limit }
          }
        })
      },
      [ActionTypes.GET_STATIONS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          stations: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        }),

      [ActionTypes.CREATE_STATION]: (state, { payload }) =>
        immutable(state, {
          addStation: {
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.CREATE_STATION_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          addStation: {
            data: { $set: payload },
            status: { $set: STATUS.READY }
          }
        })
      },
      [ActionTypes.CREATE_STATION_FAILURE]: (state, { payload }) =>
        immutable(state, {
          addStation: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        }),

      [ActionTypes.EDIT_STATION]: (state, { payload }) =>
        immutable(state, {
          editStation: {
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.EDIT_STATION_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          editStation: {
            data: { $set: payload },
            status: { $set: STATUS.READY }
          }
        })
      },
      [ActionTypes.EDIT_STATION_FAILURE]: (state, { payload }) =>
        immutable(state, {
          editStation: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        }),

      [ActionTypes.DELETE_STATION]: (state, { payload }) =>
        immutable(state, {
          deleteStation: {
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.DELETE_STATION_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          deleteStation: {
            data: { $set: payload },
            status: { $set: STATUS.READY }
          }
        })
      },
      [ActionTypes.DELETE_STATION_FAILURE]: (state, { payload }) =>
        immutable(state, {
          deleteStation: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        }),

      [ActionTypes.GET_CADET_COUNT]: (state, { payload }) =>
        immutable(state, {
          totalCount: {
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.GET_CADET_COUNT_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          totalCount: {
            data: { $set: payload },
            status: { $set: STATUS.READY }
          }
        })
      },
      [ActionTypes.GET_CADET_COUNT_FAILURE]: (state, { payload }) =>
        immutable(state, {
          totalCount: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        })
    },
    cadetsState
  )
}
