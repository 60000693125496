import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import compose from 'recompose/compose'
import { withStyles } from '@material-ui/styles'
import { Grid, Typography, Button } from '@material-ui/core'

import { ContactNumbers } from './components/index'

const style = theme => ({
  container: {
    width: '100%',
    overflow: 'hidden',
    background: '#32A05F',
    minHeight: '100vh'
  },
  buttonContainerAdmin: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    marginTop: '20px'
  },
  cpcLogo: {
    paddingTop: '60px',
    width: '125px'
  },
  heading: {
    marginTop: '10px',
    fontSize: '25px',
    textAlign: 'center',
    fontWeight: '600',
    color: 'white',
    position: 'relative'
  },
  buttonContainer: {
    marginTop: '30px'
  },
  button: {
    backgroundColor: '#FFC068',
    borderRadius: '15px',
    marginRight: '10px',
    color: 'black',
    '&:hover': {
      backgroundColor: '#FFC068',
      color: 'white'
    }
  },
  storeContainer: {
    marginTop: '30px',
    '& img': {
      marginRight: '10px',
      cursor: 'pointer'
    }
  },
  youtubeVideoConatiner: {
    margin: '40px 0 50px',
    textAlign: 'center'
  },
  youtubeVideo: {
    marginTop: '50px',
    boxShadow: '0 12px 500px rgba(0, 0, 0, 0.49)',
    width: '50vw',
    height: '30vw'
  }
})
let userAgent = navigator.userAgent || navigator.vendor || window.opera

class Home extends Component {
  constructor (props) {
    super(props)
    this.state = {
      storeLink: 'https://play.google.com/store/apps/details?id=org.inventlabs.shopsapp'
    }
  }

  componentDidMount () {
    if (/android/i.test(userAgent)) {
      this.setState({ storeLink: 'https://play.google.com/store/apps/details?id=org.inventlabs.shopsapp' })
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      this.setState({ storeLink: 'https://apps.apple.com/in/app/shopsapp/id1505960267' })
    }
  }
  render () {
    const { classes } = this.props

    const gotoLogin = () => {
      window.open(this.state.storeLink, '_blank')
    }

    const gotoLoginAsAdmin = () => {
      this.props.history.push('/sign-in')
    }
    return (
      <div className={classes.container}>
        <Grid container
          direction='column'
          justify='center'
          alignItems='center'>
          <Grid xs={12} className={classes.buttonContainerAdmin}>
            <Button variant='contained' onClick={gotoLoginAsAdmin} className={classes.button}>CPC Login</Button>
          </Grid>
          <Grid xs={12}>
            <img className={classes.cpcLogo} src='/images/cpc_logo.png' alt='cpc' />
          </Grid>
          <Grid xs={12}>
            <Typography className={classes.heading}>Subsidiary Central Police Canteen</Typography>
          </Grid>
          <Grid className={classes.buttonContainer}>
            <Button variant='contained' onClick={gotoLogin} className={classes.button}>SCPC User Login</Button>
          </Grid>
          <Grid className={classes.storeContainer}>
            <img src='/images/play-store.png' onClick={() => window.open('https://play.google.com/store/apps/details?id=org.shopsapp.scpc', '_blank')} alt='playstore' width='143px' />
            <img src='/images/app-store-logo.png' onClick={() => window.open('https://apps.apple.com/in/app/shopsapp/id1505960267', '_blank')} alt='apple-store' width='130px' />

          </Grid>
          <Grid>
            <ContactNumbers />
          </Grid>
          <Grid className={classes.youtubeVideoConatiner}>
            <Typography className={classes.heading}>How to create a store?</Typography>
            <iframe className={classes.youtubeVideo} title='Youtube' src='https://www.youtube.com/embed/956QUM1XFWE' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowfullscreen />
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default compose(
  withStyles(style), withRouter
)((Home))
