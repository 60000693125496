// @flow
/**
 * @module Actions/App
 * @desc App Actions
 */

import { createActions } from 'redux-actions'

import { ActionTypes } from 'redux/constants/index'

export const {
  level1ListCategories: listFirstLevelCategories,
  level2ListCategories: listSecondLevelCategories,
  level3ListCategories: listThirdLevelCategories,
  createCategory,
  editCategory,
  clearLevelData,
  getAllCategories,
  deleteCategory
} = createActions({
  [ActionTypes.LEVEL1_LIST_CATEGORIES]: (level) => ({ level }),
  [ActionTypes.LEVEL2_LIST_CATEGORIES]: (level, parent) => ({ level, parent }),
  [ActionTypes.LEVEL3_LIST_CATEGORIES]: (level, parent) => ({ level, parent }),
  [ActionTypes.CREATE_CATEGORY]: (apiRequest) => ({ apiRequest }),
  [ActionTypes.EDIT_CATEGORY]: (apiRequest, id) => ({ apiRequest, id }),
  [ActionTypes.CLEAR_LEVEL_DATA]: (level) => ({ level }),
  [ActionTypes.GET_ALL_CATEGORIES]: () => ({ }),
  [ActionTypes.DELETE_CATEGORY]: (id, level) => ({ id, level })
})
