import { handleActions } from 'redux-actions'
import immutable from 'immutability-helper'

import { parseError } from 'services/client'

import { ActionTypes, STATUS } from 'redux/constants/index'

export const deliveryState = {
  partners: {
    data: [],
    message: '',
    showAlert: false
  },
  addPartner: {
    data: {},
    message: '',
    showAlert: false
  },
  deletePartner: {
    data: {},
    message: '',
    showAlert: false
  }
}

export default {
  delivery: handleActions(
    {
      [ActionTypes.GET_DELIVERY_PARTNERS]: (state, { payload }) =>
        immutable(state, {
          partners: {
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.GET_DELIVERY_PARTNERS_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          partners: {
            data: { $set: payload },
            status: { $set: STATUS.READY }
          }
        }),
      [ActionTypes.GET_DELIVERY_PARTNERS_FAILURE]: (state, { payload }) =>
        immutable(state, {
          partners: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        }),

      [ActionTypes.CREATE_DELIVERY_PARTNER]: (state, { payload }) =>
        immutable(state, {
          addPartner: {
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.CREATE_DELIVERY_PARTNER_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          addPartner: {
            data: { $set: payload },
            status: { $set: STATUS.READY }
          }
        })
      },
      [ActionTypes.CREATE_DELIVERY_PARTNER_FAILURE]: (state, { payload }) =>
        immutable(state, {
          addPartner: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        }),

      [ActionTypes.DELETE_DELIVERY_PARTNER]: (state, { payload }) =>
        immutable(state, {
          deletePartner: {
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.DELETE_DELIVERY_PARTNER_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          deletePartner: {
            data: { $set: payload },
            status: { $set: STATUS.READY }
          }
        })
      },
      [ActionTypes.DELETE_DELIVERY_PARTNER_FAILURE]: (state, { payload }) =>
        immutable(state, {
          deletePartner: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        })
    },
    deliveryState
  )
}
