import { handleActions } from 'redux-actions'
import immutable from 'immutability-helper'
import { REHYDRATE } from 'redux-persist/lib/constants'
import { parseError } from 'services/client'
import _ from 'lodash'
import { ActionTypes, STATUS } from 'redux/constants/index'

export const appState = {
  alert: {
    data: {},
    message: '',
    showAlert: false
  },
  shopList: {
    data: [],
    message: '',
    status: STATUS.IDLE
  },
  register: {
    data: [],
    message: '',
    status: STATUS.IDLE
  }
}

export default {
  app: handleActions(
    {
      [REHYDRATE]: state =>
        immutable(state, {
          alert: {
            data: { $set: {} },
            message: { $set: '' },
            showAlert: { $set: false }
          }
        }),
      [ActionTypes.HIDE_ALERT]: (state, { payload }) => {
        // const alerts = state.alerts.filter(d => d.id !== id);
        return immutable(state, {
          alert: {
            data: { $set: {} },
            message: { $set: '' },
            showAlert: { $set: false }
          }
        })
      },
      [ActionTypes.SHOW_ALERT]: (state, { payload }) =>
        immutable(state, {
          alert: {
            data: { $set: payload },
            message: { $set: payload.message },
            showAlert: { $set: true }
          }
        }),

      [ActionTypes.SHOP_GET_LIST]: (state, { payload }) =>
        immutable(state, {
          shopList: {
            searchParam: { $set: payload },
            status: { $set: STATUS.RUNNING },
            reachedEnd: { $set: false }
          }
        }),
      [ActionTypes.SHOP_GET_LIST_SUCCESS]: (state, { payload }) => {
        let data = _.cloneDeep(state.shopList.data)

        if (state.shopList.searchParam && state.shopList.searchParam.skip === 0) {
          data = payload
        } else {
          data = [ ...data, ...payload ]
        }
        return immutable(state, {
          shopList: {
            data: { $set: data },
            status: { $set: STATUS.READY },
            reachedEnd: { $set: !payload.length || payload.length !== state.shopList.searchParam.limit }
          }
        })
      },
      [ActionTypes.SHOP_GET_LIST_FAILURE]: (state, { payload }) =>
        immutable(state, {
          shopList: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        }),

      [ActionTypes.REGISTER_MEMBER]: (state, { payload }) =>
        immutable(state, {
          register: {
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.REGISTER_MEMBER_SUCCESS]: (state, { payload }) => {
        return immutable(state, {
          register: {
            data: { $set: payload },
            status: { $set: STATUS.READY }
          }
        })
      },
      [ActionTypes.REGISTER_MEMBER_FAILURE]: (state, { payload }) =>
        immutable(state, {
          register: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        })
    },
    appState
  )
}
