import React, { Component } from 'react'

// Externals
import classNames from 'classnames'
import PropTypes from 'prop-types'
import PerfectScrollbar from 'react-perfect-scrollbar'

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  withStyles,
  Button
} from '@material-ui/core'
import { Portlet, PortletContent } from 'components'
import styles from './style'
import { DeleteConfirmation } from '..'

class CadetList extends Component {
  state = {
    selectedUsers: [],
    rowsPerPage: 10,
    page: 0
  };

  handleSelectAll = event => {
    const { users, onSelect } = this.props

    let selectedUsers

    if (event.target.checked) {
      selectedUsers = users.map(user => user.id)
    } else {
      selectedUsers = []
    }

    this.setState({ selectedUsers })

    onSelect(selectedUsers)
  };

  handleSelectOne = (event, id) => {
    const { onSelect } = this.props
    const { selectedUsers } = this.state

    const selectedIndex = selectedUsers.indexOf(id)
    let newSelectedUsers = []

    if (selectedIndex === -1) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers, id)
    } else if (selectedIndex === 0) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers.slice(1))
    } else if (selectedIndex === selectedUsers.length - 1) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelectedUsers = newSelectedUsers.concat(
        selectedUsers.slice(0, selectedIndex),
        selectedUsers.slice(selectedIndex + 1)
      )
    }

    this.setState({ selectedUsers: newSelectedUsers })

    onSelect(newSelectedUsers)
  };

  handleChangePage = (event, page) => {
    this.setState({ page })
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value })
  }

  showDeleteProduct = (user) => {
    this.setState({ showDelete: true, selectedUser: user })
  }

  hideDeleteProduct = () => {
    this.setState({ showDelete: false, selectedUser: null })
  }

  onReachedBottom = () => {
    this.props.fetchNextPage()
  }

  confirmDelete = () => {
    this.props.deleteDeliveryPartner(this.state.selectedUser.id)
    this.setState({ showDelete: false, selectedUser: null })
  }

  render () {
    const { classes, className, users, editDeliveryPartner } = this.props
    const { activeTab, selectedUsers, showDelete, selectedUser } = this.state

    const rootClassName = classNames(classes.root, className)

    return (
      <Portlet className={rootClassName}>
        <PortletContent className={classes.container} noPadding>
          <PerfectScrollbar onYReachEnd={this.onReachedBottom}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align='left'>Phone Number</TableCell>
                  <TableCell align='left'>Name</TableCell>
                  <TableCell align='left'>Member ID</TableCell>
                  <TableCell align='left'>Station</TableCell>
                  <TableCell align='right' />
                </TableRow>
              </TableHead>
              <TableBody>
                {users
                  .filter(user => {
                    if (activeTab === 1) {
                      return !user.returning
                    }

                    if (activeTab === 2) {
                      return user.returning
                    }

                    return user
                  })
                  .map(user => (
                    <TableRow
                      className={classes.tableRow}
                      hover
                      key={user.id}
                      selected={selectedUsers.indexOf(user.id) !== -1}
                    >
                      <TableCell className={classes.tableCell}>
                        {user.phone_with_code}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {user.user_name || 'Not yet registered'}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {user.member_id || '-'}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {user.branch_name || '-'}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        <Button color='primary' size='small' variant='contained' onClick={() => editDeliveryPartner(user)} > Edit </Button>
                        <Button className={classes.deletebutton} color='primary' size='small' variant='contained' onClick={() => this.showDeleteProduct(user)} > Delete </Button>
                      </TableCell>
                      {/* <TableCell className={classes.tableCell}>
                        {user.user_id || 'Not yet registered'}
                      </TableCell> */}

                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <DeleteConfirmation isOpen={showDelete} user={selectedUser} onCancel={this.hideDeleteProduct} onSubmit={this.confirmDelete} />
          </PerfectScrollbar>
        </PortletContent>
      </Portlet>
    )
  }
}

CadetList.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  onSelect: PropTypes.func,
  onShowDetails: PropTypes.func,
  users: PropTypes.array.isRequired
}

CadetList.defaultProps = {
  users: [],
  onSelect: () => {},
  onShowDetails: () => {}
}

export default withStyles(styles)(CadetList)
