import React, { Component } from 'react'

import PropTypes from 'prop-types'
import { Grid, withStyles, CircularProgress, Typography, Button } from '@material-ui/core'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import treeChanges from 'tree-changes'
import moment from 'moment'

import { STATUS } from 'redux/constants/index'
import { getCookie } from 'services/cookie'

import { getReservationDetails, checkinReservation, checkoutReservation, showAlert } from 'redux/actions'
import style from './style'
// Component styles

const statusMap = {
  reserved: 'Reserved',
  checked_in: 'Checked In',
  checked_out: 'Checked Out'
}

// TESTING http://localhost:3000/reservation?id=5efb86673b0e181aa8523b8f&shop-id=5e135810c87acb054c831a78

class ReservationDetails extends Component {
  state = {
  }

  componentDidMount = () => {
    const { location } = this.props
    const query = new URLSearchParams(location.search)
    const id = query.get('id') // '5efb86673b0e181aa8523b8f'
    const shopId = query.get('shop-id') // '5e135810c87acb054c831a78'
    this.setState({ shopId, id })
    this.props.dispatch(getReservationDetails(shopId, id))
  }

  componentWillReceiveProps = newProps => {
    const { changedTo } = treeChanges(this.props, newProps)
    if (changedTo('checkin.status', STATUS.READY)) {
      const successMsg = 'Successfully checked in'
      this.props.dispatch(showAlert(successMsg, { variant: 'success' }))
    } else if (changedTo('checkin.status', STATUS.ERROR)) {
      this.props.dispatch(showAlert(newProps.addPartner.message, { variant: 'error' }))
    }
    if (changedTo('checkout.status', STATUS.READY)) {
      const successMsg = 'Successfully checked out'
      this.props.dispatch(showAlert(successMsg, { variant: 'success' }))
    } else if (changedTo('checkout.status', STATUS.ERROR)) {
      this.props.dispatch(showAlert(newProps.addPartner.message, { variant: 'error' }))
    }
  }

  confirmAction = () => {
    const { id } = this.state
    const { details, dispatch } = this.props
    if (details.data.status === 'reserved') {
      dispatch(checkinReservation(id))
    } else {
      dispatch(checkoutReservation(id))
    }
  }

  getReservationDetails = () => {
    const { details, checkin, checkout, classes, user } = this.props
    const shopId = getCookie('shop_id')
    const isAuthenticated = shopId === this.state.shopId && user.isAuthenticated
    if (details.status === STATUS.RUNNING) {
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress />
        </div>
      )
    }

    if (details.status === STATUS.ERROR) {
      return (
        <Typography variant='h6'>There is no data available for this reservation</Typography>

      )
    }
    return (
      <div className={classes.details}>
        <Typography className={classes.title} variant='h4' >Reservation Details</Typography>
        <div className={classes.pairs} >
          <div className={classes.pair}>
            <Typography className={classes.key} variant='body2' >Code</Typography>
            <Typography className={classes.value} variant='body2' >{details.data.code}</Typography>
          </div>
          <div className={classes.pair}>
            <Typography className={classes.key} variant='body2' >Customer Name</Typography>
            <Typography className={classes.value} variant='body2' >{details.data.name}</Typography>
          </div>
          <div className={classes.pair}>
            <Typography className={classes.key} variant='body2' >Memeber ID</Typography>
            <Typography className={classes.value} variant='body2' >{details.data.member_id}</Typography>
          </div>
          <div className={classes.pair}>
            <Typography className={classes.key} variant='body2' >Reserved Day</Typography>
            <Typography className={classes.value} variant='body2' >{moment(details.data.start_ts).format('DD/MM/YYYY')}</Typography>
          </div>
          <div className={classes.pair}>
            <Typography className={classes.key} variant='body2' >Reserved Slot</Typography>
            <Typography className={classes.value} variant='body2' >{`${moment(details.data.start_ts).format('hh:mm A')} - ${moment(details.data.end_ts).format('hh:mm A')}`}</Typography>
          </div>
          <div className={classes.pair}>
            <Typography className={classes.key} variant='body2' >Status</Typography>
            <Typography className={classes.value} variant='body2' >{statusMap[details.data.member_id] || details.data.member_id}</Typography>
          </div>
        </div>
        {checkin.status === STATUS.RUNNING || checkout.status === STATUS.RUNNING ? <CircularProgress className={classes.progress} />
          : isAuthenticated && (details.data.status === 'reserved' || details.data.status === 'checked_in') ? <Button
            className={classes.signInButton}
            color='primary'
            onClick={this.confirmAction}
            size='large'
            variant='contained'
          >
            {details.data.status === 'reserved' ? 'Checkin' : 'Checkout'}
          </Button> : null}
      </div>

    )
  }

  render () {
    const { classes } = this.props
    return (
      <div className={classes.root}>
        <Grid
          container
          className={classes.container}
        >
          {this.getReservationDetails()}
        </Grid>
      </div>
    )
  }
}

ReservationDetails.propTypes = {
  classes: PropTypes.object.isRequired
}

function mapStateToProps (state) {
  return {
    details: state.reservations.details,
    checkin: state.reservations.checkin,
    checkout: state.reservations.checkout,
    user: state.user
  }
}

export default compose(
  withStyles(style)
)(connect(mapStateToProps)(ReservationDetails))
