// @flow
/**
 * @module Actions/App
 * @desc App Actions
 */

import { createActions } from 'redux-actions'

import { ActionTypes } from 'redux/constants/index'

export const { getAccountStatus } = createActions({
  [ActionTypes.GET_ACCOUNT_STATUS]: () => ({ })
})
