import React, { Component } from 'react'

// Externals
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { Button, withStyles, Typography } from '@material-ui/core'
import { SearchInput } from 'components'

// Component styles
import styles from './style'

class SearchToolbar extends Component {
  keyPressed = event => {
    if (event.key === 'Enter') {
      this.props.onClickSearch()
    }
  }
  render () {
    const { classes, className, showCreateProduct, searchString, onChangeSearchString, onClickSearch, showCreate, onClickRefreshButton, showCount, totalCount, currentCount } = this.props

    const rootClassName = classNames(classes.root, className)

    return (
      <div className={rootClassName}>
        <div className={classes.row}>
          <SearchInput
            className={classes.searchInput}
            placeholder='Search product'
            value={searchString}
            onKeyPress={this.keyPressed}
            onChange={onChangeSearchString}
          />
          <Button color='primary' size='small' variant='outlined' onClick={onClickSearch} >
            Search
          </Button>
          <span className={classes.spacer} />
          {showCount ? <div className={classes.count} >
            <Typography variant='p' >{`${currentCount} / ${totalCount} Members`}</Typography>
          </div> : null }
          <Button color='primary' className={classes.firstButton} size='small' variant='outlined' onClick={onClickRefreshButton} >
            Refresh
          </Button>
          { showCreate ? <Button color='primary' size='small' variant='outlined' onClick={showCreateProduct} >
            Add
          </Button> : null }
        </div>
      </div>
    )
  }
}

SearchToolbar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(SearchToolbar)
