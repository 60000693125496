import React, { Component } from 'react'

import PropTypes from 'prop-types'
import { Grid, withStyles, CircularProgress, Typography } from '@material-ui/core'
import { Dashboard as DashboardLayout } from 'layouts'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import treeChanges from 'tree-changes'

import { STATUS } from 'redux/constants/index'

import { CadetList, AddPartner } from './components'
import { getCadets, createPartner, showAlert, deletePartner, editPartner, getStations, getCadetCount } from 'redux/actions'
import style from './style'
import SearchToolbar from 'views/Common/SearchToolbar'

const itemsPerPage = 20

// Component styles

class Cadets extends Component {
  state = {
    searchString: '',
    page: 0,
    showAddDeliveryPerson: false
  }

  componentDidMount = () => {
    this.props.dispatch(getCadets(0, itemsPerPage))
    this.props.dispatch(getCadetCount())
    this.props.dispatch(getStations(0, itemsPerPage * 3))
  }

  componentWillReceiveProps = newProps => {
    const { changedTo } = treeChanges(this.props, newProps)
    if (changedTo('cadets.status', STATUS.READY)) {
      this.setState({ isLoading: false })
    }
    if (changedTo('addPartner.status', STATUS.READY)) {
      const successMsg = 'Successfully Added New Police'
      this.props.dispatch(showAlert(successMsg, { variant: 'success' }))
      this.setState({ isLoading: true, page: 0 })
      this.props.dispatch(getCadets(0, itemsPerPage))
    } else if (changedTo('addPartner.status', STATUS.ERROR)) {
      this.props.dispatch(showAlert(newProps.addPartner.message, { variant: 'error' }))
    }
    if (changedTo('editPartner.status', STATUS.READY)) {
      const successMsg = 'Successfully Edited Police'
      this.props.dispatch(showAlert(successMsg, { variant: 'success' }))
      this.setState({ isLoading: true, page: 0 })
      this.props.dispatch(getCadets(0, itemsPerPage))
    } else if (changedTo('editPartner.status', STATUS.ERROR)) {
      this.props.dispatch(showAlert(newProps.editPartner.message, { variant: 'error' }))
    }
    if (changedTo('deletePartner.status', STATUS.READY)) {
      const successMsg = 'Successfully Deleted the Police'
      this.props.dispatch(showAlert(successMsg, { variant: 'success' }))
      this.setState({ isLoading: true, page: 0 })
      this.props.dispatch(getCadets(0, itemsPerPage))
    } else if (changedTo('deletePartner.status', STATUS.ERROR)) {
      this.props.dispatch(showAlert(newProps.deletePartner.message, { variant: 'error' }))
    }
  }

  showAddDeliveryPerson = () => {
    this.setState({ showAddDeliveryPerson: true })
  }

  hideAddDeliveryPerson = () => {
    this.setState({ showAddDeliveryPerson: false, selectedPartner: undefined })
  }

  onChangeSearchString = event => {
    const { value } = event.target
    this.setState({ searchString: value })
  }

  onClickSearch = () => {
    const { searchString } = this.state
    this.setState({ isLoading: true, page: 0 })
    this.props.dispatch(getCadets(0, itemsPerPage, searchString))
  }

  onSubmitPartner = (phone, memberId, branchId, id) => {
    this.setState({ showAddDeliveryPerson: false, selectedPartner: undefined })
    if (id) {
      this.props.dispatch(editPartner(phone, memberId, branchId, id))
    } else {
      this.props.dispatch(createPartner(phone, memberId, branchId))
    }
  }

  deleteDeliveryPartner = (id) => {
    this.props.dispatch(deletePartner(id))
  }

  editDeliveryPartner = (partner) => {
    this.setState({ selectedPartner: partner, showAddDeliveryPerson: true })
  }

  refreshPage = () => {
    const { isLoading, page, searchString } = this.state
    const nextIndex = (page + 1) * itemsPerPage
    if (!isLoading) {
      this.setState({ isLoading: true }, () => {
        this.props.dispatch(getCadets(0, nextIndex, searchString))
      })
    }
  }

  fetchNextPage = () => {
    const { cadets } = this.props
    const { isLoading, page, searchString } = this.state
    const nextIndex = (page + 1) * itemsPerPage
    if (!isLoading && !cadets.reachedEnd) {
      this.setState({ page: page + 1, isLoading: true }, () => {
        this.props.dispatch(getCadets(nextIndex, itemsPerPage, searchString))
      })
    }
  }

  getPartnerList = () => {
    const { cadets, classes } = this.props
    if (cadets.status === STATUS.RUNNING && cadets.data.length === 0) {
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress />
        </div>
      )
    }

    if (cadets.data.length === 0) {
      return (
        <Typography variant='h6'>No whitelisted members found.</Typography>
      )
    }
    return (
      <React.Fragment>
        {cadets.status === STATUS.RUNNING ? <div className={classes.progressWrapper}>
          <CircularProgress />
        </div> : null}
        <CadetList
          users={cadets.data}
          deleteDeliveryPartner={this.deleteDeliveryPartner}
          fetchNextPage={this.fetchNextPage}
          editDeliveryPartner={this.editDeliveryPartner}
        />
      </React.Fragment>

    )
  }

  render () {
    const { searchString, showAddDeliveryPerson, selectedPartner } = this.state
    const { classes, stations, cadets, totalCount } = this.props
    return (
      <DashboardLayout title='Police'>
        <div className={classes.root}>
          <Grid
            container
            className={classes.container}
            spacing={4}
          >
            <SearchToolbar
              showCreate
              showCreateProduct={this.showAddDeliveryPerson}
              searchString={searchString}
              showCount={totalCount.data}
              totalCount={totalCount.data}
              currentCount={cadets.data.length}
              onChangeSearchString={this.onChangeSearchString}
              onClickRefreshButton={this.refreshPage}
              onClickSearch={this.onClickSearch}
            />
            {this.getPartnerList()}
          </Grid>
        </div>
        <AddPartner partner={selectedPartner} stations={stations.data} isOpen={showAddDeliveryPerson} onCancel={this.hideAddDeliveryPerson} onSubmit={this.onSubmitPartner} />
      </DashboardLayout>
    )
  }
}

Cadets.propTypes = {
  classes: PropTypes.object.isRequired
}

function mapStateToProps (state) {
  return {
    cadets: state.cadets.partners,
    addPartner: state.cadets.addPartner,
    editPartner: state.cadets.editPartner,
    deletePartner: state.cadets.deletePartner,
    stations: state.cadets.stations,
    totalCount: state.cadets.totalCount
  }
}

export default compose(
  withStyles(style)
)(connect(mapStateToProps)(Cadets))
