import React from 'react'
import { compose } from 'recompose'
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow
} from 'react-google-maps'

const MapContainer = compose(withScriptjs, withGoogleMap)(props => {
  const defaultCenter = {
    lat: props.shops && props.shops.length ? props.shops[0].location.lat : 8.4998965,
    lng: props.shops && props.shops.length ? props.shops[0].location.lon : 76.8541258
  }
  return (
    <GoogleMap defaultZoom={13} defaultCenter={defaultCenter}>
      {props.shops.map(marker => {
        return (
          <Marker
            key={marker.id}
            onClick={() => props.onSelectShop(marker)}
            position={{ lat: marker.location.lat, lng: marker.location.lon }}
          >
            {props.selectedShop === marker &&
              <InfoWindow>
                <div>
                  {marker.code}
                </div>
              </InfoWindow>
            }
          </Marker>
        )
      })}
    </GoogleMap>
  )
})

export default MapContainer
