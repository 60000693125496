import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Grid, withStyles } from '@material-ui/core'

import { ShopProfile, ShopDetails, ShopActions, ShopAddress } from '..'
const styles = theme => ({
  root: {
    paddingTop: theme.spacing.unit * 4
  }
})

class ShopSettings extends Component {
  state = { tabIndex: 0 };

  render () {
    const { classes } = this.props

    return (
      <div className={classes.root}>
        <Grid
          container
          spacing={4}
        >
          <Grid
            container
            xs={12}
          >
            <ShopActions />
          </Grid>
          <Grid
            item
            lg={4}
            md={6}
            xl={4}
            xs={12}
          >
            <ShopProfile />
            <ShopAddress />
          </Grid>
          <Grid
            item
            lg={8}
            md={6}
            xl={8}
            xs={12}
          >
            <ShopDetails />
          </Grid>
          {/* <Grid
            item
            lg={4}
            md={6}
            xl={4}
            xs={12}
          >

          </Grid> */}
        </Grid>
      </div>
    )
  }
}

ShopSettings.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(ShopSettings)
