import React, { Component } from 'react'

// Externals
import PropTypes from 'prop-types'
import treeChanges from 'tree-changes'
import { connect } from 'react-redux'
import _ from 'lodash'
// Material helpers
import { withStyles, Grid, Button, IconButton, Typography, CircularProgress, Switch } from '@material-ui/core'
import { Close as CrossIcon } from '@material-ui/icons'

import TextField from '@material-ui/core/TextField'
import { showAlert, updateProduct, createProduct, productGetDetails } from 'redux/actions/index'

// Component styles
import Modal from 'views/Modal'
import { STATUS, APIEndpoints } from 'redux/constants'
import style from './style'
import { CategorySelect } from '..'
import { getCookie } from 'services/cookie'
import { getResizedImage } from 'services/utility'

const updateSuccessMsg = 'Updated the product item successfully'
const createSuccessMsg = 'Created the product item successfully'

const defaultDetails = {
  manufacturer: '',
  rank: 6
}

class EditProduct extends Component {
  constructor (props) {
    super(props)
    this.state = {
      productDetails: _.cloneDeep(props.product || defaultDetails),
      categories: [],
      isUploadingImage: false
    }
    if (props.id && props.isOpen) {
      props.dispatch(productGetDetails(props.id))
    }
  }

  componentWillReceiveProps = newProps => {
    const { changed, changedTo } = treeChanges(this.props, newProps)
    if (newProps.default && changed('isOpen') && newProps.isOpen) {
      this.setState({ productDetails: _.cloneDeep(newProps.default) })
    }
    if (newProps.id && changed('isOpen') && newProps.isOpen) {
      newProps.dispatch(productGetDetails(newProps.id))
    }
    if (newProps.product && newProps.product.data.id === newProps.id && changed('product') && changedTo('product.status', STATUS.READY)) {
      this.setState({ productDetails: _.cloneDeep(newProps.product.data) })
    }
    if (changedTo('updateProduct.status', STATUS.READY)) {
      const successMsg = this.props.product ? updateSuccessMsg : createSuccessMsg
      this.props.dispatch(showAlert(successMsg, { variant: 'success' }))
      this.props.onCancel()
    }
    if (changedTo('updateProduct.status', STATUS.ERROR)) {
      this.props.dispatch(showAlert(newProps.updateProduct.message, { variant: 'error' }))
    }
  }

  getMaxDimentions = (width, height) => {
    const max = 1000
    let maxWidth, maxHeight
    if (width > height) {
      maxWidth = max
      maxHeight = parseInt(max * height / width)
    } else {
      maxHeight = max
      maxWidth = parseInt(max * width / height)
    }
    return { maxWidth, maxHeight }
  }

  handleChange = (event, name) => {
    const { value } = event.target
    let { productDetails } = this.state
    productDetails[name] = value
    this.setState({ productDetails })
  }

  handleBooleanChange = (event, name) => {
    let { productDetails } = this.state
    if (event.target.checked) {
      productDetails[name] = event.target.checked
      this.setState({ productDetails })
    } else {
      productDetails[name] = event.target.checked
      this.setState({ productDetails })
    }
    event.preventDefault()
  }

  handleImageChange = (e) => {
    e.preventDefault()
    const fileName = e.target.files[0].name
    const initialFile = e.target.files[0]
    const reader = new FileReader()
    reader.readAsDataURL(e.target.files[0])
    reader.onload = event => {
      const img = new Image()
      img.src = event.target.result
      img.onload = (fileLoadEvent) => {
        const { width, height } = fileLoadEvent.target
        const { maxWidth, maxHeight } = this.getMaxDimentions(width, height)
        if (maxWidth > width) {
          this.handleImageUpload(initialFile)
          return
        }
        const elem = document.createElement('canvas')
        elem.width = maxWidth
        elem.height = maxHeight
        const ctx = elem.getContext('2d')
        // img.width and img.height will contain the original dimensions
        ctx.drawImage(img, 0, 0, maxWidth, maxHeight)
        ctx.canvas.toBlob((blob) => {
          const file = new File([blob], fileName, {
            type: 'image/jpeg',
            lastModified: Date.now()
          })
          if (file.size > initialFile.size) {
            this.handleImageUpload(initialFile)
            return
          }
          this.handleImageUpload(file)
        }, 'image/jpeg', 1)
      }
      reader.onerror = error => console.log(error)
    }
  }

  handleImageUpload = async (file) => {
    let { productDetails } = this.state
    let { images } = productDetails
    if (!images) {
      images = []
    }
    let data = new FormData()
    data.append('image', file, file.name)
    const authToken = getCookie('auth_token')
    const token = `Bearer ${authToken}`
    const shopId = getCookie('shop_id')
    const url = APIEndpoints.UPLOAD_PRODUCT_IMAGE_URL(shopId)
    const params = {
      method: 'POST',
      body: data,
      headers: {
        'Authorization': token
      }
    }
    this.setState({ isUploadingImage: true })
    fetch(url, params).then(async response => {
      if (response.status > 299) {
        this.props.dispatch(showAlert('Image upload failed. Please try again', { variant: 'error' }))
        this.setState({ isUploadingImage: false })
      } else {
        const result = await response.json()
        const imageUrl = result.url
        if (!images.length) {
          productDetails.image_url = imageUrl
        }
        images.push({ url: imageUrl })
        productDetails.images = images
        this.setState({ productDetails, isUploadingImage: false })
      }
    }, () => {
      this.setState({ isUploadingImage: false })
      this.props.dispatch(showAlert('Image upload failed. Please try again', { variant: 'error' }))
    })
  }

  onClickFileUpload = event => {
    event.stopPropagation()
    event.target.value = ''
  }

  showHoverOptions = url => {
    this.setState({ hoveredImage: url })
  }

  handleCategory = (index, value, level) => {
    let { productDetails } = this.state
    let { categories } = productDetails
    if (!categories[index][level] || (categories[index][level] && categories[index][level].id !== value.id)) {
      categories[index][level] = value
      if (level === 1) {
        categories[index][2] = null
        categories[index][3] = null
      } else if (level === 2) {
        categories[index][3] = null
      }
      productDetails.categories = categories
      this.setState({ productDetails })
    }
  }

  removeImage = index => {
    let { productDetails } = this.state
    let { images } = productDetails
    if (images[index].url === productDetails.image_url) {
      if (images.length > 1) {
        images.splice(index, 1)
        productDetails.image_url = images[0].url
      } else {
        images = []
        productDetails.image_url = null
      }
    } else {
      images.splice(index, 1)
    }
    productDetails.images = images
    this.setState({ productDetails })
  }

  setAsDefaultImage = url => {
    let { productDetails } = this.state
    productDetails.image_url = url
    this.setState({ productDetails })
  }

  onCloseCategory = index => {
    let { productDetails } = this.state
    let { categories } = productDetails
    categories.splice(index, 1)
    productDetails.categories = categories
    this.setState({ productDetails })
  }

  onAddCategory = () => {
    let { productDetails } = this.state
    let { categories } = productDetails
    if (!categories) {
      categories = []
    }
    const newCategory = {
      1: null,
      2: null,
      3: null
    }
    categories.push(_.cloneDeep(newCategory))
    productDetails.categories = categories
    this.setState({ productDetails })
  }

  onCancel = () => {
    this.setState({ productDetails: defaultDetails })
  }

  saveProduct = event => {
    const { productDetails } = this.state
    const { images } = productDetails
    if (productDetails.rank > 10 || productDetails.rank < 0) {
      this.props.dispatch(showAlert('Rank of the product should be in 0 - 10 range', { variant: 'error' }))
      return
    }
    if (!images || !images.length) {
      // this.props.dispatch(showAlert('Upload atleast one image for the product', { variant: 'error' }))
      // return
    }
    let categories = (productDetails.categories || []).map(item => {
      const category = {
        1: item[1] ? item[1].id : '',
        2: item[2] ? item[2].id : '',
        3: item[3] ? item[3].id : ''
      }
      return category
    })

    const manufacturer = productDetails.manufacturer || ''
    const request = {
      ...productDetails,
      price: parseFloat(productDetails.price),
      weight: parseFloat(productDetails.weight),
      actual_price: parseFloat(productDetails.actual_price),
      categories,
      item_code: productDetails.item_code,
      manufacturer,
      stock_value: parseInt(productDetails.stock_value) || 0,
      reorder_point: parseInt(productDetails.reorder_point || 0),
      rank: parseInt(productDetails.rank) || 6
    }
    if (this.props.id) {
      this.props.dispatch(updateProduct(request))
    } else {
      this.props.dispatch(createProduct(request))
    }
  }

  render () {
    const { classes, id, onCancel, isOpen, product, updateProduct } = this.props
    const { productDetails, isUploadingImage } = this.state
    const { name, manufacturer, model, price, details, images, rank, weight } = productDetails
    const actualPrice = productDetails.actual_price
    let child
    const isSaving = updateProduct.status === STATUS.RUNNING

    if (!product || product.status === STATUS.RUNNING) {
      child = <div className={classes.progressWrapper}>
        <CircularProgress />
      </div>
    } else {
      child = <div className={classes.container}>
        {isSaving ? <div className={classes.progressWrapper}>
          <CircularProgress />
        </div> : null}
        <form autoComplete='off' noValidate >
          <div className={classes.field}>
            <TextField
              className={classes.textField}
              label='Name'
              margin='dense'
              required
              onChange={(e) => this.handleChange(e, 'name')}
              value={name}
              variant='outlined'
            />
            <TextField
              className={classes.textField}
              label='Item Code(SKU)'
              margin='dense'
              onChange={(e) => this.handleChange(e, 'item_code')}
              value={productDetails.item_code}
              variant='outlined'
            />
          </div>
          <div className={classes.field}>
            <TextField
              className={classes.textField}
              label='Manufacturer'
              margin='dense'
              onChange={(e) => this.handleChange(e, 'manufacturer')}
              value={manufacturer}
              variant='outlined'
            />
            <TextField
              className={classes.textField}
              label='Model'
              margin='dense'
              onChange={(e) => this.handleChange(e, 'model')}
              value={model}
              variant='outlined'
            />
          </div>
          <div className={classes.field}>
            <TextField
              className={classes.textField}
              label='Description'
              margin='dense'
              value={details}
              onChange={(e) => this.handleChange(e, 'details')}
              variant='outlined'
              multiline
              rows={4}
              rowsMax={8}
            />
            <TextField
              className={classes.textField}
              label='Product Weight(gram)'
              margin='dense'
              type='number'
              value={weight}
              variant='outlined'
              onChange={(e) => this.handleChange(e, 'weight')}
            />
          </div>
          <div className={classes.field}>
            <TextField
              className={classes.textField}
              label='Actual Price'
              margin='dense'
              type='number'
              required
              value={actualPrice}
              variant='outlined'
              onChange={(e) => this.handleChange(e, 'actual_price')}
              InputProps={{ inputProps: { min: 0 } }}
            />
            <TextField
              className={classes.textField}
              label='Selling Price'
              margin='dense'
              required
              type='number'
              value={price}
              variant='outlined'
              onChange={(e) => this.handleChange(e, 'price')}
              InputProps={{ inputProps: { min: 0 } }}
            />
          </div>
          <div className={classes.field}>
            <div className={classes.check}>
              <Typography className={classes.checkHeading} variant='body2' color='textSecondary' component='p'>In Stock</Typography>
              <Switch
                className={classes.switch}
                checked={productDetails.in_stock}
                onChange={(e) => this.handleBooleanChange(e, 'in_stock')}
                value='checkedA'
                // disabled={isLoading}
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </div>
            <div className={classes.check}>

              <Typography className={classes.checkHeading} variant='body2' color='textSecondary' component='p'>Manage stock</Typography>
              <Switch
                className={classes.switch}
                checked={productDetails.manage_stock}
                onChange={(e) => this.handleBooleanChange(e, 'manage_stock')}
                value='checkedA'
                // disabled={isLoading}
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </div>
          </div>
          <div className={classes.field}>
            {productDetails.manage_stock ? <TextField
              className={classes.textField}
              label='Stock Unit'
              margin='dense'
              type='number'
              required
              value={productDetails.stock_value}
              variant='outlined'
              onChange={(e) => this.handleChange(e, 'stock_value')}
            /> : null}
            {productDetails.manage_stock ? <TextField
              className={classes.textField}
              label='Reorder Point'
              margin='dense'
              onChange={(e) => this.handleChange(e, 'reorder_point')}
              value={productDetails.reorder_point}
              variant='outlined'
            /> : null}
          </div>
        </form>
        <div className={classes.categories} >
          <Typography className={classes.heading} component='h3'>Images</Typography>
          <div className={classes.grid} >
            <Grid container spacing={3} >
              {(images || []).map((item, index) => {
                const resizedImage = getResizedImage(item.url || item.imagePreviewUrl, 150, 150)
                return <Grid item key={index} lg={2} >
                  <div className={classes.image} onMouseOver={() => this.showHoverOptions(resizedImage)} style={{ backgroundImage: `url(${resizedImage})` }}>
                    <div className={classes.imageHover} >
                      <IconButton aria-label='delete' onClick={() => this.removeImage(index)}>
                        <CrossIcon />
                      </IconButton>
                      {item.url !== productDetails.image_url ? <Button className={classes.defaultStatus} size='small' variant='contained' color='primary' onClick={() => this.setAsDefaultImage(item.url)} >Set Default</Button> : null}
                    </div>
                  </div>
                </Grid>
              }
              )}
              {isUploadingImage ? <Grid item key={'image-placeholder'} lg={2} >
                <div className={classes.image}>
                  <p className={`${classes.imageLoadingText} blinking`} >Uploading...</p>
                  <CircularProgress className={classes.imageloader} color='secondary' />
                </div>
              </Grid> : null}
            </Grid>
          </div>
          <div className={classes.upload} >
            <input
              accept='image/*'
              className={classes.input}
              style={{ display: 'none' }}
              id='raised-button-file'
              onClick={this.onClickFileUpload}
              onChange={(e) => this.handleImageChange(e)}
              type='file'
            />
            <label htmlFor='raised-button-file'>
              <Button variant='contained' component='span' className={classes.button} onClick={this.onClickFileUpload}>
                Upload Image
              </Button>
            </label>
          </div>
        </div>
        <div className={classes.rank}>
          <div className={classes.check}>
            <Typography className={classes.checkHeading} variant='body2' color='textSecondary' component='p'>Recommended</Typography>
            <Switch
              className={classes.switch}
              checked={productDetails.recommended}
              onChange={(e) => this.handleBooleanChange(e, 'recommended')}
              value='checkedA'
              // disabled={isLoading}
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </div>
          <TextField
            className={classes.textField}
            label='Rank(1 to 10)'
            margin='dense'
            required
            type='number'
            value={rank}
            variant='outlined'
            inputProps={{ min: '0', max: '10', step: '1' }}
            onChange={(e) => this.handleChange(e, 'rank')}
          />
        </div>
        <div className={classes.categories}>
          <Typography className={classes.heading} component='h3'>Categories</Typography>
          {(productDetails.categories || []).map((item, index) => <CategorySelect key={index} id={index} categoryData={_.cloneDeep(item)} handleCategoryChange={this.handleCategory} onCloseCategory={this.onCloseCategory} />)}
          <Button variant='contained' component='span' className={classes.button} onClick={this.onAddCategory}> Add Category</Button>
        </div>
      </div>
    }

    return (
      <Modal isOpen={isOpen} title={id ? 'EDIT PRODUCT' : 'ADD PRODUCT'} onCancel={onCancel} okText={'Save'} onSubmit={this.saveProduct} child={child} width={1000} />
    )
  }
}

EditProduct.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired
}

function mapStateToProps (state) {
  return {
    uploadingImage: state.products.productImage,
    updateProduct: state.products.updateProduct,
    product: state.products.details
  }
}

export default withStyles(style)(connect(mapStateToProps)(EditProduct))
