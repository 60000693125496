import React, { Component } from 'react'

// Externals
import PropTypes from 'prop-types'
import treeChanges from 'tree-changes'
import { connect } from 'react-redux'
import _ from 'lodash'
// Material helpers
import { CircularProgress, Grid, Typography, withStyles, TextField, Button } from '@material-ui/core'
import { productSearchResult } from 'redux/actions/index'
import { CommonProductCard } from '..'

// Component styles
import Modal from 'views/Modal'
import { STATUS } from 'redux/constants'

const styles = theme => ({
  progressWrapper: {
    width: '100%',
    marginTop: '40px',
    display: 'flex',
    justifyContent: 'center'
  },
  resultContainer: {
    height: '65vh',
    marginTop: 20,
    overflow: 'auto'
  },
  row: {
    display: 'flex',
    alignItems: 'center'
  },
  message: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '0px',
    marginBottom: '20px'
  },
  textField: {
    width: 'calc( 50% - 10px )',
    maxWidth: '100%',
    margin: 0,
    marginRight: 20
  },
  button: {
    height: 37
  },
  grid: {
    width: '100%',
    margin: 0
  }
})

class CreateProduct extends Component {
  constructor (props) {
    super(props)
    this.state = {
      searchString: '',
      selectedProduct: null
    }
  }

  componentWillReceiveProps = newProps => {
    const { changed, changedTo } = treeChanges(this.props, newProps)
    if (changed('searchResults') && changedTo('searchResults.status', STATUS.READY)) {
      this.setState({ selectedProduct: null })
    }
  }

  handleChange = (event) => {
    const { value } = event.target
    this.setState({ searchString: value })
    this.props.dispatch(productSearchResult(value))
  }

  onSelectProduct = id => {
    this.setState({ selectedProduct: id })
  }

  selectProduct = () => {
    const { onSubmit, searchResults } = this.props
    const { selectedProduct, searchString } = this.state
    let selectedDefaultProduct
    if (selectedProduct) {
      selectedDefaultProduct = searchResults.data.find(item => item.id === selectedProduct)
    }
    onSubmit(selectedDefaultProduct || searchString)
    this.setState({ searchString: '', selectedProduct: null })
    this.props.dispatch(productSearchResult(null))
  }

  onCancel = () => {
    this.setState({ searchString: '', selectedProduct: null })
    this.props.dispatch(productSearchResult(null))
    this.props.onCancel()
  }

  renderProducts = () => {
    const { searchResults, classes } = this.props
    const { selectedProduct } = this.state
    return <Grid container spacing={3} className={classes.grid} >
      {searchResults.data.map(product => (
        <Grid item key={product.id} lg={4} md={6} xs={12} >
          <CommonProductCard product={_.cloneDeep(product)} isSelected={selectedProduct === product.id} onSelectProduct={this.onSelectProduct} />
        </Grid>
      ))}
    </Grid>
  }

  render () {
    const { classes, isOpen, searchResults } = this.props
    const { searchString } = this.state
    const child = <div className={classes.container}>
      <div className={classes.row}>
        <TextField
          className={classes.textField}
          label='Product Name'
          margin='dense'
          onChange={this.handleChange}
          value={searchString}
          variant='outlined'
        />
        <Button className={classes.button} size='small' variant='contained' color='primary' onClick={this.selectProduct} > Next </Button>
      </div>
      <div className={classes.resultContainer} >
        {searchResults.status === STATUS.RUNNING ? <div className={classes.progressWrapper}>
          <CircularProgress />
        </div> : null}
        {searchResults.status === STATUS.READY && searchResults.data.length ? <div className={classes.message}>
          <Typography variant='h6'>If the product is listed below, select it and click next.</Typography>
        </div> : null}
        {searchResults.status === STATUS.READY && searchResults.data.length ? this.renderProducts() : null}
      </div>
    </div>

    return (
      <Modal isOpen={isOpen} title={'ADD PRODUCT'} onCancel={this.onCancel} okText={'Next'} onSubmit={this.selectProduct} child={child} width={1000} />
    )
  }
}

CreateProduct.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  products: PropTypes.object.isRequired
}

function mapStateToProps (state) {
  return {
    searchResults: state.products.searchResult
  }
}

export default withStyles(styles)(connect(mapStateToProps)(CreateProduct))
