export default theme => ({
  tabs: {
    padding: 20,
    paddingBottom: 0,
    paddingTop: 0
  },
  tab: {
    minHeight: 48,
    '& span': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around'
    }
  },
  progressWrapper: {
    paddingTop: '48px',
    paddingBottom: '24px',
    display: 'flex',
    justifyContent: 'center'
  }
})
