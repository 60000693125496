import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Grid, withStyles } from '@material-ui/core'
import PaymentDetails from '../PaymentDetails'

const styles = theme => ({
  root: {
    paddingTop: theme.spacing.unit * 4
  }
})

class PaymentSettings extends Component {
  state = { tabIndex: 0 };

  render () {
    const { classes } = this.props

    return (
      <div className={classes.root}>
        <Grid
          container
          spacing={4}
        >
          <Grid
            item
            xs={12}
          >
            <PaymentDetails />
          </Grid>
        </Grid>
      </div>
    )
  }
}

PaymentSettings.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(PaymentSettings)
