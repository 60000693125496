export default theme => ({
  container: {
    position: 'relative',
    '& svg': {
      position: 'absolute',
      top: 'calc( 50% - 12px)',
      left: 'calc( 50% - 12px)'
    }
  },
  mapContainer: {
    marginBottom: 20
  },
  textField: {
    width: '100%',
    '& + & ': {
      marginTop: theme.spacing.unit * 2
    }
  }
})
