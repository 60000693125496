import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles, TextField, Typography } from '@material-ui/core'
import Modal from 'views/Modal'

const styles = theme => ({
  container: {
    // display: 'flex',
    '& .react-datetime-picker': {
      width: '100%',
      height: 35,
      marginTop: 20,
      '& > div': {
        borderRadius: '2px'
      }
    }
  },

  textField: {
    width: '100%'
  },
  error: {
    color: 'red'
  }
})

class ChangePaymentStatus extends Component {
  state = {
    message: ''
  }

  handleChange = (value, key) => {
    this.setState({ [key]: value })
  }

  onSubmit = () => {
    const { message, amount, transactionId, mode } = this.state
    const { onSubmit } = this.props
    this.setState({ error: false })
    if (!amount) {
      this.setState({ error: true })
      return
    }
    onSubmit(message, amount, transactionId, mode)
  }

  render () {
    const { classes, onCancel, isOpen } = this.props
    const { message, mode, error, transactionId, amount } = this.state
    const child = <div className={classes.container}>
      {/* <Typography className={classes.warning} variant='p'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. of Lorem Ipsum.</Typography> */}
      <TextField
        className={classes.textField}
        label={'Payment Mode'}
        margin='dense'
        onChange={(e) => this.handleChange(e.target.value, 'mode')}
        value={mode}
        variant='outlined'
      />
      <TextField
        className={classes.textField}
        label={'Amount'}
        margin='dense'
        onChange={(e) => this.handleChange(e.target.value, 'amount')}
        value={amount}
        required
        variant='outlined'
      />
      <TextField
        className={classes.textField}
        label={'Transaction ID'}
        margin='dense'
        onChange={(e) => this.handleChange(e.target.value, 'transactionId')}
        value={transactionId}
        variant='outlined'
      />
      <TextField
        className={classes.textField}
        label={'Remark'}
        margin='dense'
        onChange={(e) => this.handleChange(e.target.value, 'message')}
        value={message}
        variant='outlined'
      />

      {error ? <Typography className={classes.error} variant='p'>Please Fill the amount</Typography> : null}
    </div>
    return (
      <Modal isOpen={isOpen} title={'Change Payment Status'} onCancel={onCancel} okText={'Submit'} onSubmit={this.onSubmit} child={child} />
    )
  }
}

ChangePaymentStatus.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired
}

export default withStyles(styles)(ChangePaymentStatus)
