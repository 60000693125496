import React, { Component } from 'react'

import PropTypes from 'prop-types'
import classNames from 'classnames'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
// import CameraAltIcon from '@material-ui/icons/CameraAlt'

import { Typography, withStyles } from '@material-ui/core'
import { Portlet, PortletContent } from 'components'
import style from './style'
import { getCookie } from 'services/cookie'
import { APIEndpoints } from 'redux/constants'
import { showAlert, addShopLogo } from 'redux/actions'

class UserProfile extends Component {
  handleImageChange = async (e) => {
    e.preventDefault()
    const file = e.target.files[0]
    let data = new FormData()
    data.append('image', file, file.name)
    const authToken = getCookie('auth_token')
    const token = `Bearer ${authToken}`
    const shopId = getCookie('shop_id')
    const url = APIEndpoints.UPLOAD_SHOP_LOGO(shopId)
    const params = {
      method: 'POST',
      body: data,
      headers: {
        'Authorization': token
      }
    }
    this.setState({ isUploadingImage: true })
    fetch(url, params).then(async response => {
      if (response.status > 299) {
        this.props.dispatch(showAlert('Image upload failed. Please try again', { variant: 'error' }))
        this.setState({ isUploadingImage: false })
      } else {
        const result = await response.json()
        const imageUrl = result.url
        this.props.dispatch(addShopLogo(imageUrl))
      }
    }, () => {
      this.setState({ isUploadingImage: false })
      this.props.dispatch(showAlert('Image upload failed. Please try again', { variant: 'error' }))
    })
  }

  onClickFileUpload = event => {
    event.stopPropagation()
    event.target.value = ''
  }

  render () {
    const { classes, className, userDetails, ...rest } = this.props
    const { user } = userDetails
    const rootClassName = classNames(classes.root, className)

    return (
      <Portlet
        {...rest}
        className={rootClassName}
      >
        <PortletContent>
          <div className={classes.details}>
            <div className={classes.info}>
              <Typography variant='h2'>{user.name}</Typography>
              <Typography
                className={classes.locationText}
                variant='body1'
              >
                {user.phone}
              </Typography>
              <Typography
                className={classes.dateText}
                variant='body1'
              >
                {user.email}
              </Typography>
            </div>
          </div>
          {/* <div className={classes.progressWrapper}>
            <Typography variant='body1'>Profile Completeness: 70%</Typography>
            <LinearProgress
              value={70}
              variant='determinate'
            />
          </div> */}
        </PortletContent>
        {/* <PortletFooter>
          <Button
            className={classes.uploadButton}
            color='primary'
            variant='text'
          >
            Upload picture
          </Button>
          <Button variant='text'>Remove picture</Button>
        </PortletFooter> */}
      </Portlet>
    )
  }
}

UserProfile.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
}

function mapStateToProps (state) {
  return {
    userDetails: state.settings.shopDetails
  }
}

export default compose(
  withStyles(style)
)(connect(mapStateToProps)(UserProfile))
