/**
 * @module Sagas/App
 * @desc App
 */
import { all, put, takeLatest, delay, call } from 'redux-saga/effects'

import { ActionTypes, APIEndpoints } from 'redux/constants/index'
import { request } from 'services/client'

/**
 * Switch Menu
 *
 * @param {Object} action
 *
 */

// function* delay(ms, result = ms) {
//   return new Promise(resolve => {
//     setTimeout(() => resolve(result), ms)
//   })
// }
export function * showAlert ({ payload }) {
  try {
    // yield call(delay, 5000)
    yield delay(5000)
    yield put({
      type: ActionTypes.HIDE_ALERT
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.EXCEPTION,
      payload: err
    })
  }
}

export function * getShopList ({ payload }) {
  try {
    const { id } = payload
    console.log('api call')
    const response = yield call(request, APIEndpoints.SHOP_GET_LIST(id), {
      method: 'GET'
    })
    yield put({
      type: ActionTypes.SHOP_GET_LIST_SUCCESS,
      payload: response.shops
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.SHOP_GET_LIST_FAILURE,
      payload: err
    })
  }
}

export function * registerMember ({ payload }) {
  try {
    const { requestObj, id } = payload
    const response = yield call(request, APIEndpoints.REGISTER_MEMBER(id), {
      method: 'POST',
      payload: requestObj
    })
    yield put({
      type: ActionTypes.REGISTER_MEMBER_SUCCESS,
      payload: response.shops
    })
  } catch (err) {
    /* istanbul ignore next */
    yield put({
      type: ActionTypes.REGISTER_MEMBER_FAILURE,
      payload: err
    })
  }
}

/**
 * App Sagas
 */
export default function * root () {
  yield all([
    takeLatest(ActionTypes.SHOW_ALERT, showAlert),
    takeLatest(ActionTypes.SHOP_GET_LIST, getShopList),
    takeLatest(ActionTypes.REGISTER_MEMBER, registerMember)]
  )
}
