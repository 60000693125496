import React, { Component } from 'react'

import PropTypes from 'prop-types'
import { Grid, withStyles, CircularProgress, Typography } from '@material-ui/core'
import { Dashboard as DashboardLayout } from 'layouts'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import treeChanges from 'tree-changes'

import { STATUS } from 'redux/constants/index'

import { PartnerList, AddPartner } from './components'
import { getDeliveryPartners, createDeliveryPartner, showAlert, deleteDeliveryPartner } from 'redux/actions'
import style from './style'
import SearchToolbar from 'views/Common/SearchToolbar'

// Component styles

class DeliveryPartners extends Component {
  state = {
    searchString: '',
    showAddDeliveryPerson: false,
    page: 0
  }

  componentDidMount = () => {
    this.props.dispatch(getDeliveryPartners())
  }

  componentWillReceiveProps = newProps => {
    const { changedTo } = treeChanges(this.props, newProps)
    if (changedTo('addPartner.status', STATUS.READY)) {
      const successMsg = 'Successfully Added New Volunteer'
      this.props.dispatch(showAlert(successMsg, { variant: 'success' }))
      this.props.dispatch(getDeliveryPartners())
    } else if (changedTo('addPartner.status', STATUS.ERROR)) {
      this.props.dispatch(showAlert(newProps.addPartner.message, { variant: 'error' }))
    }
    if (changedTo('deletePartner.status', STATUS.READY)) {
      const successMsg = 'Successfully Deleted the Volunteer'
      this.props.dispatch(showAlert(successMsg, { variant: 'success' }))
      this.props.dispatch(getDeliveryPartners())
    } else if (changedTo('deletePartner.status', STATUS.ERROR)) {
      this.props.dispatch(showAlert(newProps.deletePartner.message, { variant: 'error' }))
    }
  }

  showAddDeliveryPerson = () => {
    this.setState({ showAddDeliveryPerson: true })
  }

  hideAddDeliveryPerson = () => {
    this.setState({ showAddDeliveryPerson: false })
  }

  onChangeSearchString = event => {
    const { value } = event.target
    this.setState({ searchString: value })
  }

  onClickSearch = () => {
    const { searchString } = this.state
    this.setState({ isLoading: true })
    this.props.dispatch(getDeliveryPartners(searchString))
  }

  refreshPage = () => {
    const { searchString } = this.state
    this.setState({ isLoading: true })
    this.props.dispatch(getDeliveryPartners(searchString))
  }

  onSubmitPartner = phone => {
    this.setState({ showAddDeliveryPerson: false })
    this.props.dispatch(createDeliveryPartner(phone))
  }

  deleteDeliveryPartner = (id) => {
    this.props.dispatch(deleteDeliveryPartner(id))
  }

  getPartnerList = () => {
    const { partners, classes } = this.props
    if (partners.status === STATUS.RUNNING) {
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress />
        </div>
      )
    }

    if (partners.data.length === 0) {
      return (
        <Typography variant='h6'>There are no delivey partners.</Typography>
      )
    }
    return (
      <PartnerList users={partners.data} deleteDeliveryPartner={this.deleteDeliveryPartner} />
    )
  }

  render () {
    const { searchString, showAddDeliveryPerson } = this.state
    const { classes } = this.props
    return (
      <DashboardLayout title='Delivery Partners'>
        <div className={classes.root}>
          <Grid
            container
            className={classes.container}
            spacing={4}
          >
            <SearchToolbar
              showCreate
              showCreateProduct={this.showAddDeliveryPerson}
              searchString={searchString}
              onChangeSearchString={this.onChangeSearchString}
              onClickSearch={this.onClickSearch}
              onClickRefreshButton={this.refreshPage}
            />
            {this.getPartnerList()}
          </Grid>
        </div>
        <AddPartner isOpen={showAddDeliveryPerson} onCancel={this.hideAddDeliveryPerson} onSubmit={this.onSubmitPartner} />
      </DashboardLayout>
    )
  }
}

DeliveryPartners.propTypes = {
  classes: PropTypes.object.isRequired
}

function mapStateToProps (state) {
  return {
    partners: state.delivery.partners,
    addPartner: state.delivery.addPartner,
    deletePartner: state.delivery.deletePartner
  }
}

export default compose(
  withStyles(style)
)(connect(mapStateToProps)(DeliveryPartners))
