export default theme => ({
  root: {
    marginTop: 20
  },
  details: {
    display: 'flex'
  },
  info: {},
  locationText: {
    marginTop: theme.spacing.unit,
    color: theme.palette.text.primary,
    fontWeight: 'bold'
  },
  dateText: {
    color: theme.palette.text.secondary,
    fontWeight: 'bold'
  },
  avatar: {
    position: 'relative',
    marginLeft: 'auto',
    height: '110px',
    width: '110px',
    flexShrink: 0,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    borderRadius: '50%',
    flexGrow: 0,
    '&:hover > div': {
      display: 'flex'
    }
  },
  edit: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'none',
    borderRadius: '50%',
    zIndex: 5,
    background: 'black',
    opacity: 0.5,
    justifyContent: 'center',
    alignItems: 'center'
  },
  editIcon: {

  },
  progressWrapper: {
    marginTop: theme.spacing.unit * 2
  },
  uploadButton: {
    marginRight: theme.spacing.unit * 2
  }
})
