import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import { EditOutlined as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'

// Component styles
const styles = theme => ({
  wrapper: {
    width: '100%',
    height: 60,
    padding: 20,
    position: 'relative',
    borderBottom: '1px solid #ccc',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
  },
  selected: {
    background: '#ececec'
  },
  button: {
    position: 'absolute',
    right: 60,
    top: 20,
    '&:last-child': {
      right: 20
    }
  }
})

class CategoryListCard extends Component {
  render () {
    const { classes, item, isSelected, onClick, onEdit, onDelete } = this.props

    return (
      <div className={`${classes.wrapper} ${isSelected ? classes.selected : ''}`} onClick={onClick}>
        <p>{item.name}</p>
        <EditIcon className={classes.button} onClick={onEdit} />
        <DeleteIcon className={classes.button} onClick={onDelete} />
      </div>
    )
  }
}

CategoryListCard.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(CategoryListCard)
