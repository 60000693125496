// @flow
/**
 * @module Actions/User
 * @desc User Actions
 */
import { createActions } from 'redux-actions'

import { ActionTypes } from 'redux/constants/index'

export const {
  userLogin,
  userLogout: logOut,
  refreshToken,
  userSignup: signup,
  setAuthenticated,
  forgotPassword,
  resetPassword,
  clearForgot
} = createActions({
  [ActionTypes.USER_LOGIN]: (email, password) => ({ email, password }),
  [ActionTypes.USER_SIGNUP]: (requestObj) => ({ requestObj }),
  [ActionTypes.USER_LOGOUT]: () => ({}),
  [ActionTypes.REFRESH_TOKEN]: () => ({}),
  [ActionTypes.USER_SIGNUP]: requestObj => ({ requestObj }),
  [ActionTypes.SET_AUTHENTICATED]: () => ({}),
  [ActionTypes.CLEAR_FORGOT]: () => ({}),
  [ActionTypes.FORGOT_PASSWORD]: (email) => ({ email }),
  [ActionTypes.RESET_PASSWORD]: (email, otp, password) => ({ email, otp, password })
})
