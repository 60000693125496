import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

// Externals
import PropTypes from 'prop-types'
import compose from 'recompose/compose'
import _ from 'underscore'
import treeChanges from 'tree-changes'
import { connect } from 'react-redux'

import { STATUS } from 'redux/constants/index'
import { showAlert, registerMember } from 'redux/actions/index'

import {
  Grid,
  Button,
  CircularProgress,
  TextField,
  Typography,
  withStyles
} from '@material-ui/core'

import styles from './style'
import { RegisterSuccess } from './components'

const groupId = '5eec9d3671f58b10ad03416b'

class RegisterMember extends Component {
  state = {
    values: {
      name: '',
      phone: ''
    },
    touched: {
      email: false,
      password: false
    },
    errors: {
      email: null,
      password: null
    },
    isValid: false,
    isLoading: false,
    submitError: null,
    selectedShops: [],
    stage: 1
  }

  componentWillReceiveProps = nextProps => {
    const { dispatch } = this.props
    const { changedTo } = treeChanges(this.props, nextProps)

    /* istanbul ignore else */
    if (changedTo('registerDetails.status', STATUS.READY)) {
      this.setState({ isLoading: false, stage: 2 })
    } else if (changedTo('registerDetails.status', STATUS.ERROR)) {
      dispatch(showAlert(nextProps.registerDetails.message, { variant: 'error' }))
      this.setState({ isLoading: false })
    }
  }

  componentWillUnmount = () => {
    clearInterval(this.interval)
  }

  validateForm = _.debounce(() => {
    const { values } = this.state
    if (values.name && values.phone && values.member_id) {
      this.setState({ isValid: true })
    }
  }, 30);

  handleFieldChange = (field, value) => {
    const newState = { ...this.state }

    newState.submitError = null
    newState.touched[field] = true
    newState.values[field] = value

    this.setState(newState, this.validateForm)
  }

  onSelectShop = id => {
    let { selectedShops } = this.state
    const index = selectedShops.indexOf(id)
    if (index > -1) {
      selectedShops.splice(index, 1)
    } else {
      selectedShops.push(id)
    }
    this.setState({ selectedShops })
  }

  incrementStage = () => {
    this.setState({ stage: 2 })
  }

  decrementStage = () => {
    this.setState({ stage: 1 })
  }

  handleRegister = async () => {
    try {
      const { values } = this.state
      const request = {
        ...values
      }
      if (values.phone && isNaN(parseInt(values.phone))) {
        this.props.dispatch(showAlert('Please enter a valid number', { variant: 'error' }))
        return
      }
      request.phone_with_code = `+91${request.phone}`
      delete request.phone
      this.setState({ isLoading: true })
      this.props.dispatch(registerMember(request, groupId))
    } catch (error) {
      this.setState({
        isLoading: false,
        serviceError: error
      })
    }
  }

  render () {
    const { classes } = this.props
    const {
      stage,
      values,
      isValid,
      submitError,
      isLoading
    } = this.state

    return (
      <div className={classes.root}>
        <Grid
          className={classes.grid}
          container
        >
          <Grid
            className={classes.quoteWrapper}
            item
            lg={5}
          >
            <div className={classes.quote}>
              <div className={classes.quoteInner}>
                {/* <div className={classes.person}>
                  <Typography
                    className={classes.name}
                    variant='body1'
                  >
                    Best online platform
                  </Typography>
                  <Typography
                    className={classes.bio}
                    variant='body2'
                  >
                    ShopsApp Team
                  </Typography>
                </div> */}
              </div>
            </div>
          </Grid>
          <Grid
            className={classes.content}
            item
            lg={7}
            xs={12}
          >
            <div className={classes.content}>
              <div className={classes.contentBody}>
                <form className={classes.form}>
                  <img className={classes.img} src='images/cyberdome.png' alt='cyberdome_logo' />
                  { stage !== 2 ? <Typography
                    className={classes.title}
                    variant='h2'
                  >
                    Register CPC Membership
                  </Typography> : null}
                  {stage === 1 ? <div className={classes.fields}>
                    <TextField
                      className={classes.textField}
                      label='Name'
                      name='name'
                      onChange={event =>
                        this.handleFieldChange('name', event.target.value)
                      }
                      type='name'
                      value={values.name}
                      variant='outlined'
                    />
                    <div className={classes.phoneNumber} >
                      <div className={classes.code} > +91 </div>
                      <TextField
                        className={classes.textField}
                        label='Phone Number'
                        name='phone'
                        onChange={event =>
                          this.handleFieldChange('phone', event.target.value)
                        }
                        inputProps={{ maxlength: 10 }}
                        type='text'
                        value={values.phone}
                        variant='outlined'
                      />
                    </div>
                    <TextField
                      className={classes.textField}
                      label='Canteen Card Number'
                      name='member_id'
                      onChange={event =>
                        this.handleFieldChange('member_id', event.target.value)
                      }
                      type='text'
                      value={values.member_id}
                      variant='outlined'
                    />

                  </div> : null}
                  {/* { stage === 2 ? <ShopList groupId={groupId} selectedShops={[...selectedShops]} onSelectShop={this.onSelectShop} /> : null } */}
                  { stage === 2 ? <RegisterSuccess /> : null }
                  {submitError && (
                    <Typography
                      className={classes.submitError}
                      variant='body2'
                    >
                      {submitError}
                    </Typography>
                  )}
                  {/* { stage === 2 ? (
                    <Button
                      className={classes.signInButton}
                      color='secondary'
                      onClick={this.decrementStage}
                      size='large'
                      variant='contained'
                    >
                      Back
                    </Button>) : null} */}
                  {isLoading ? (
                    <CircularProgress className={classes.progress} />
                  ) : stage === 1 ? (
                    // <Button
                    //   className={classes.signInButton}
                    //   color='primary'
                    //   disabled={!isValid}
                    //   onClick={this.incrementStage}
                    //   size='large'
                    //   variant='contained'
                    // >
                    //   Next
                    // </Button>) : stage === 2
                    // ? (
                    <Button
                      className={classes.signInButton}
                      color='primary'
                      disabled={!isValid}
                      onClick={this.handleRegister}
                      size='large'
                      variant='contained'
                    >
                        Register Now
                    </Button>
                  ) : null}
                </form>

              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    )
  }
}

RegisterMember.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

/* istanbul ignore next */
function mapStateToProps (state) {
  return {
    registerDetails: state.app.register
  }
}

export default compose(
  withRouter,
  withStyles(styles)
)(connect(mapStateToProps)(RegisterMember))
