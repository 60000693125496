import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles, Typography, Button, IconButton, Tooltip, Card } from '@material-ui/core'
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate'
import UpdateIcon from '@material-ui/icons/Update'
import { Portlet, PortletContent, PortletFooter } from 'components'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { confirmOrder, confirmPacked, confirmDelivery, confirmDelivered, updateDelivery, cancelOrder } from 'redux/actions/index'

import styles from './style'
import ConfirmOrder from '../ConfirmOrder'
import moment from 'moment'

const buttonText = {
  'confirmed': 'Confirm',
  'shop_confirmed': 'Set as Packed',
  'shop_packed': 'Out for Delivery',
  'shop_delivery': 'Mark as delivered'
}

// const statusMap = {
//   'confirmed': 'New',
//   'shop_confirmed': 'Confirmed',
//   'shop_packed': 'Out for Delivery',
//   'shop_delivery': 'Delivered',
//   'cancelled': 'Customer Cancelled',
//   'shop_cancelled': 'Shop Cancelled'
// }

const statusMap = {
  'confirmed': 'NEW',
  'shop_confirmed': 'CONFIRMED',
  'shop_packed': 'PACKED',
  'shop_delivery': 'FOR DELIVERY',
  'delivered': 'DELIVERED',
  'customer_cancelled': 'CUSTOMER CANCELLED',
  'shop_cancelled': 'SHOP CANCELLED'
}

class OrderListCell extends Component {
  state = {
    showConfirmation: false
  }

  showConfirmation = () => {
    this.setState({ showConfirmation: true })
  }

  hideConfirmation = () => {
    this.setState({ showConfirmation: false, type: null })
  }

  showEditDeliveryTime = () => {
    this.setState({ showConfirmation: true, type: 'edit_delivery' })
  }

  showCancelOrderConfirmation = () => {
    this.setState({ showConfirmation: true, type: 'cancel' })
  }

  onSubmitConfirmation = (message, time, price) => {
    const { status, id } = this.props.order
    const { type } = this.state
    if (type) {
      if (type === 'edit_delivery') {
        this.updateDelivery(id, message, time)
      } else {
        this.cancelOrder(id, message)
      }
    } else if (status === 'confirmed') {
      this.confirmOrder(id, message, time, price)
    } else if (status === 'shop_confirmed') {
      this.confirmPacked(id, message)
    } else if (status === 'shop_packed') {
      this.confirmDelivery(id, message)
    } else if (status === 'shop_delivery') {
      this.confirmDelivered(id, message)
    }
    this.setState({ showConfirmation: false })
  }

  confirmOrder = (id, message, time, price) => {
    this.props.dispatch(confirmOrder(id, message, time, price))
  }

  confirmPacked = (id, message) => {
    this.props.dispatch(confirmPacked(id, message))
  }

  confirmDelivery = (id, message) => {
    this.props.dispatch(confirmDelivery(id, message))
  }

  confirmDelivered = (id, message) => {
    this.props.dispatch(confirmDelivered(id, message))
  }

  updateDelivery = (id, message, time) => {
    this.props.dispatch(updateDelivery(id, message, time))
  }

  cancelOrder = (id, message) => {
    this.props.dispatch(cancelOrder(id, message))
  }

  render () {
    const { classes, order, onSelectOrder, isComplete } = this.props
    const { showConfirmation, type } = this.state
    return (
      <Card>
        <Portlet className={classes.root} >
          <PortletContent className={classes.cardContainer} onClick={() => onSelectOrder(order.id)}>
            <div className={classes.details}>
              <div className={classes.info}>
                {order.is_new ? <div className={classes.new}>New</div> : null}
                <Typography className={classes.orderHeading} variant='h4'>Order: <span className={classes.orderCode} >{order.code}</span></Typography>
                <div className={classes.dateContainer} >
                  <div className={classes.orderTime}>
                    <Typography variant='body1' > Order Time </Typography>
                    <Typography variant='body1' >{moment(order.confirmed_ts).format('D MMM YYYY HH:mm')}</Typography>
                  </div>
                  <div className={classes.deliveryTime}>
                    <Typography variant='body1' > Delivery Time </Typography>
                    <Typography variant='body1' >{moment(order.delivery_details.delivery_by).format('D MMM YYYY HH:mm')}</Typography>
                  </div>
                </div>
                <div className={classes.addressHeading} >
                  {order.name ? <Typography className={classes.addressHeadingText} variant='p' >{order.name}</Typography> : null}
                  {order.delivery_address.full_address ? <Typography className={classes.addressHeadingText} variant='p' >{order.delivery_address.full_address}</Typography> : null}
                  {order.delivery_address.landmark ? <Typography className={classes.addressHeadingText} variant='p' >{order.delivery_address.landmark}</Typography> : null}
                  {order.delivery_address.locality ? <Typography className={classes.addressHeadingText} variant='p' >{order.delivery_address.locality}</Typography> : null}
                </div>
                <div className={`${classes.productPrice}`}>
                  <Typography variant='body1' > Status </Typography>
                  <Typography className={order.status} variant='body1' >{statusMap[order.status]}</Typography>
                </div>
                <div className={`${classes.productPrice}`}>
                  <Typography variant='body1' > Distance </Typography>
                  <Typography variant='body1' >{`${parseFloat(order.delivery_details.distance / 1000).toFixed(2)} Km`}</Typography>
                </div>
                <div className={`${classes.productPrice} ${isComplete ? 'completed' : ''}`}>
                  <Typography variant='body1' > {`Total Price (${order.products.length} ${order.products.length > 1 ? 'items' : 'item'})`} </Typography>
                  <Typography variant='body1' >{`${parseFloat(order.bill_details.product_price ? order.bill_details.product_price : 0)} + ${parseFloat(order.bill_details.delivery_price)} = \u20B9 ${parseFloat(order.bill_details.total_price ? order.bill_details.total_price : 0).toFixed(2)}`}</Typography>
                </div>
              </div>
            </div>
          </PortletContent>
          {!isComplete ? <PortletFooter className={classes.footer}>
            <Button color='primary' variant='outlined' onClick={this.showConfirmation} > {buttonText[order.status]} </Button>
            <div className={classes.iconButtons} >
              <Tooltip title='Edit Delivery Time'>
                <IconButton color='primary' onClick={this.showEditDeliveryTime} aria-label='edit' size='small'>
                  <UpdateIcon className={classes.footerIcons} style={{ color: '#66788A' }} />
                </IconButton>
              </Tooltip>
              <Tooltip title='Cancel Order'>
                <IconButton color='primary' onClick={this.showCancelOrderConfirmation} aria-label='cancel' size='small'>
                  <AssignmentLateIcon className={classes.footerIcons} style={{ color: '#66788A' }} />
                </IconButton>
              </Tooltip>
            </div>

          </PortletFooter> : null}
          <ConfirmOrder isOpen={showConfirmation} price={order.bill_details ? order.bill_details.product_price : 0} delivery={order.delivery_details ? order.delivery_details.delivery_by : null} type={type || order.status} onCancel={this.hideConfirmation} onSubmit={this.onSubmitConfirmation} />
        </Portlet>
      </Card>
    )
  }
}

OrderListCell.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
}

function mapStateToProps (state) {
  return {
    confirm: state.orders.confirm
  }
}

export default compose(
  withStyles(styles)
)(connect(mapStateToProps)(OrderListCell))
