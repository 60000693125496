import React, { Component } from 'react'
import { Grid, Typography, withStyles, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'

import style from './style'

class FaqCard extends Component {
  render () {
    const { classes } = this.props

    return (
      <Grid container>
        <ExpansionPanel square className={classes.panel}>
          <ExpansionPanelSummary aria-controls='panel1d-content' id='panel1d-header' >
            <ArrowRightIcon /> <Typography className={classes.heading}>Collapsible Group Item #1</Typography>
          </ExpansionPanelSummary >
          <ExpansionPanelDetails >
            <Typography className={classes.description}>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                            sit amet blandit leo lobortis eget. Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.
            </Typography>
          </ExpansionPanelDetails >
        </ExpansionPanel >
        <ExpansionPanel square className={classes.panel}>
          <ExpansionPanelSummary aria-controls='panel1d-content' id='panel1d-header'>
            <ArrowRightIcon /> <Typography className={classes.heading}>Collapsible Group Item #1</Typography>
          </ExpansionPanelSummary >
          <ExpansionPanelDetails >
            <Typography className={classes.description}>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                            sit amet blandit leo lobortis eget. Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.
            </Typography>
          </ExpansionPanelDetails >
        </ExpansionPanel >
        <ExpansionPanel square className={classes.panel}>
          <ExpansionPanelSummary aria-controls='panel1d-content' id='panel1d-header'>
            <ArrowRightIcon /> <Typography className={classes.heading}>Collapsible Group Item #1</Typography>
          </ExpansionPanelSummary >
          <ExpansionPanelDetails >
            <Typography className={classes.description}>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                            sit amet blandit leo lobortis eget. Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.
            </Typography>
          </ExpansionPanelDetails >
        </ExpansionPanel >
      </Grid >
    )
  }
}

export default withStyles(style)(FaqCard)
