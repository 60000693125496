import { handleActions } from 'redux-actions'
import immutable from 'immutability-helper'
import _ from 'lodash'
import { parseError } from 'services/client'

import { ActionTypes, STATUS } from 'redux/constants/index'

export const categoryState = {
  allCategories: {
    data: [],
    message: '',
    status: STATUS.IDLE
  },
  level1: {
    data: [],
    message: '',
    status: STATUS.IDLE
  },
  level2: {
    data: [],
    message: '',
    status: STATUS.IDLE
  },
  level3: {
    data: [],
    message: '',
    status: STATUS.IDLE
  },
  create: {
    message: '',
    req: {},
    status: STATUS.IDLE
  },
  edit: {
    message: '',
    req: {},
    status: STATUS.IDLE
  },
  delete: {
    message: '',
    req: {},
    status: STATUS.IDLE
  }
}

export default {
  category: handleActions(
    {
      [ActionTypes.CLEAR_LEVEL_DATA]: (state, { payload }) => {
        const { level } = payload
        return immutable(state, {
          [`level${level}`]: {
            data: { $set: [] },
            status: { $set: STATUS.IDLE }
          }
        })
      },

      [ActionTypes.LEVEL1_LIST_CATEGORIES]: (state, { payload }) =>
        immutable(state, {
          level1: {
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.LEVEL1_LIST_CATEGORIES_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          level1: {
            data: { $set: payload },
            status: { $set: STATUS.READY }
          }
        }),
      [ActionTypes.LEVEL1_LIST_CATEGORIES_FAILURE]: (state, { payload }) =>
        immutable(state, {
          level1: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        }),

      [ActionTypes.LEVEL2_LIST_CATEGORIES]: (state, { payload }) =>
        immutable(state, {
          level2: {
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.LEVEL2_LIST_CATEGORIES_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          level2: {
            data: { $set: payload },
            status: { $set: STATUS.READY }
          }
        }),
      [ActionTypes.LEVEL2_LIST_CATEGORIES_FAILURE]: (state, { payload }) =>
        immutable(state, {
          level2: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        }),

      [ActionTypes.LEVEL3_LIST_CATEGORIES]: (state, { payload }) =>
        immutable(state, {
          level3: {
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.LEVEL3_LIST_CATEGORIES_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          level3: {
            data: { $set: payload },
            status: { $set: STATUS.READY }
          }
        }),
      [ActionTypes.LEVEL3_LIST_CATEGORIES_FAILURE]: (state, { payload }) =>
        immutable(state, {
          level3: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        }),

      [ActionTypes.GET_ALL_CATEGORIES]: (state, { payload }) =>
        immutable(state, {
          allCategories: {
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.GET_ALL_CATEGORIES_SUCCESS]: (state, { payload }) =>
        immutable(state, {
          allCategories: {
            data: { $set: payload },
            status: { $set: STATUS.READY }
          }
        }),
      [ActionTypes.GET_ALL_CATEGORIES_FAILURE]: (state, { payload }) =>
        immutable(state, {
          allCategories: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        }),

      [ActionTypes.CREATE_CATEGORY]: (state, { payload }) =>
        immutable(state, {
          create: {
            req: { $set: payload.apiRequest.item_category },
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.CREATE_CATEGORY_SUCCESS]: (state, { payload }) => {
        const { create } = state
        const { level } = create.req
        const levelVar = `level${level}`
        let stateVar = _.cloneDeep(state[levelVar].data)
        stateVar.push(payload)
        return immutable(state, {
          create: {
            status: { $set: STATUS.READY }
          },
          [levelVar]: {
            data: { $set: stateVar },
            status: { $set: STATUS.READY }
          }
        })
      },
      [ActionTypes.CREATE_CATEGORY_FAILURE]: (state, { payload }) =>
        immutable(state, {
          create: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        }),

      [ActionTypes.EDIT_CATEGORY]: (state, { payload }) =>
        immutable(state, {
          edit: {
            req: { $set: payload.id },
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.EDIT_CATEGORY_SUCCESS]: (state, { payload }) => {
        const { edit } = state
        const id = edit.req
        const { level } = payload
        const levelVar = `level${level}`
        let stateVar = _.cloneDeep(state[levelVar].data)
        const index = stateVar.findIndex(item => item.id === id)
        if (index > -1) {
          stateVar.splice(index, 1, payload)
        }
        return immutable(state, {
          edit: {
            status: { $set: STATUS.READY }
          },
          [levelVar]: {
            data: { $set: stateVar },
            status: { $set: STATUS.READY }
          }
        })
      },
      [ActionTypes.EDIT_CATEGORY_FAILURE]: (state, { payload }) =>
        immutable(state, {
          edit: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        }),

      [ActionTypes.DELETE_CATEGORY]: (state, { payload }) =>
        immutable(state, {
          delete: {
            req: { $set: payload.id },
            level: { $set: payload.level },
            status: { $set: STATUS.RUNNING }
          }
        }),
      [ActionTypes.DELETE_CATEGORY_SUCCESS]: (state, { payload }) => {
        const deleteCategory = state.delete
        const id = deleteCategory.req
        const level = deleteCategory.level
        const levelVar = `level${level}`
        let stateVar = _.cloneDeep(state[levelVar].data)
        const index = stateVar.findIndex(item => item.id === id)
        if (index > -1) {
          stateVar.splice(index, 1)
        }
        return immutable(state, {
          delete: {
            status: { $set: STATUS.READY }
          },
          [levelVar]: {
            data: { $set: stateVar },
            status: { $set: STATUS.READY }
          }
        })
      },
      [ActionTypes.DELETE_CATEGORY_FAILURE]: (state, { payload }) =>
        immutable(state, {
          delete: {
            message: { $set: parseError(payload.message) },
            status: { $set: STATUS.ERROR }
          }
        })
    },
    categoryState
  )
}
