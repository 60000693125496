import React, { Component } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Button, TextField, Typography, withStyles } from '@material-ui/core'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import treeChanges from 'tree-changes'
import validate from 'validate.js'
import _ from 'lodash'

import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter
} from 'components'
import styles from './styles'
import shopSchema from './schema'
import Select from 'components/Select'
import { updateShopSettings, showAlert } from 'redux/actions'
import { STATUS } from 'redux/constants'

class ShopDetails extends Component {
  constructor (props) {
    super(props)
    const shopDetails = props.shopDetails
    this.state = {
      shopDetails,
      touched: {
        name: false,
        email: false,
        phone: false,
        base_categories: false,
        shop_type: null
      },
      shopError: {

      }
    }
  }

  componentWillReceiveProps = newProps => {
    const { shopDetails, updateShop } = _.cloneDeep(newProps)
    const { changed, changedTo } = treeChanges(this.props, newProps)
    if (changed('shopDetails') && updateShop.status !== STATUS.RUNNING) {
      this.setState({ shopDetails: _.cloneDeep(shopDetails) })
    }
    if (changedTo('updateShop.status', STATUS.ERROR)) {
      this.props.dispatch(showAlert(updateShop.message, { variant: 'error' }))
    } else if (changedTo('updateShop.status', STATUS.READY)) {
      this.setState({ touched: {} })
      this.props.dispatch(showAlert('Updated Shop Settings successfully', { variant: 'success' }))
    }
  }

  getErrorMessage = () => {
    const { shopError, touched } = this.state
    const showNameError =
      touched.name && shopError.name ? shopError.name[0] : false
    const showEmailError =
      touched.email && shopError.email ? shopError.email[0] : false
    const showPhoneError =
      touched.phone && shopError.phone ? shopError.phone[0] : false
    const showCustomerCareError =
      touched.customer_care && shopError.customer_care ? shopError.customer_care[0] : false
    const showTypeError =
      touched.shop_type && shopError.shop_type ? shopError.shop_type[0] : false
    const showCategoryError =
      touched.base_categories && shopError.base_categories ? shopError.base_categories[0] : false
    let errorItems = []
    if (showNameError) {
      errorItems.push('Store Name')
    }
    if (showEmailError) {
      errorItems.push('Email')
    }
    if (showPhoneError) {
      errorItems.push('Phone Number')
    }
    if (showCustomerCareError) {
      errorItems.push('Customer Care Number ')
    }
    if (showTypeError) {
      errorItems.push('Shop Type')
    }
    if (showCategoryError) {
      errorItems.push('Shop Category')
    }
    if (errorItems.length > 1) {
      const lastItem = errorItems.splice(errorItems.length - 1, 1)[0]
      return `* ${errorItems.join(', ')} and ${lastItem} are not valid`
    } else {
      return `* ${errorItems[0]} is not valid`
    }
  }

  validateShopForm = _.debounce(() => {
    const { shopDetails } = this.state
    const request = {
      ...shopDetails,
      email: shopDetails.contact.email,
      customer_care: shopDetails.contact.customer_care,
      phone: shopDetails.contact.phone,
      shop_type: shopDetails.shop_type ? shopDetails.shop_type.id : null,
      base_categories: (shopDetails.base_categories || []).map(item => item.id).join(',')
    }
    const errors = validate(request, shopSchema)
    this.setState({ shopError: errors || {} })
  }, 300);

  handleFieldChange = (field, value) => {
    let { shopDetails, touched } = this.state
    if (field === 'base_categories') {
      shopDetails[field] = value.map(id => ({ id }))
      touched[field] = true
    } else if (field === 'shop_type') {
      shopDetails[field].id = value
      touched[field] = true
    } else if (field === 'email' || field === 'phone' || field === 'customer_care') {
      shopDetails.contact[field] = value
      touched[field] = true
    } else {
      shopDetails[field] = value
      touched[field] = true
    }

    this.setState({ shopDetails, touched }, this.validateShopForm)
  }

  handleSaveAction = e => {
    const { shopDetails } = this.state
    const request = {
      phone: shopDetails.contact.phone,
      customer_care: shopDetails.contact.customer_care,
      email: shopDetails.contact.email,
      name: shopDetails.name
    }
    if (shopDetails.shop_type) {
      request.shop_type = shopDetails.shop_type.id
    }
    if (shopDetails.base_categories && shopDetails.base_categories.length) {
      request.base_categories = shopDetails.base_categories.map(item => item.id)
    }
    this.props.dispatch(updateShopSettings(request))
  }

  render () {
    const { classes, className, shopTypes, shopCategories, updateShop, ...rest } = this.props
    const { shopDetails, shopError, touched } = this.state
    const rootClassName = classNames(classes.root, className)
    const showNameError =
      touched.name && shopError.name ? shopError.name[0] : false
    const showEmailError =
      touched.email && shopError.email ? shopError.email[0] : false
    const showPhoneError =
      touched.phone && shopError.phone ? shopError.phone[0] : false
    const showCustomerCareError =
      touched.customer_care && shopError.customer_care ? shopError.customer_care[0] : false
    const showTypeError =
      touched.shop_type && shopError.shop_type ? shopError.shop_type[0] : false
    const showCategoryError =
      touched.base_categories && shopError.base_categories ? shopError.base_categories[0] : false
    const isEdited = touched.name || touched.email || touched.phone | touched.base_categories || touched.shop_type || touched.customer_care
    const isValid = !showNameError && !showEmailError && !showEmailError && !showTypeError && !showCategoryError && !showPhoneError && !showCustomerCareError
    return (
      <Portlet
        {...rest}
        className={rootClassName}
      >
        <PortletHeader>
          <PortletLabel
            title='Profile'
          />
        </PortletHeader>
        <PortletContent noPadding>
          <form
            autoComplete='off'
            noValidate
          >
            <div className={classes.field}>
              <TextField
                className={classes.textField}
                helperText='Please specify the store name'
                label='Store name'
                margin='dense'
                required
                value={shopDetails.name}
                variant='outlined'
                onChange={(e) => this.handleFieldChange('name', e.target.value)}
              />
              <TextField
                className={classes.textField}
                label='Email Address'
                margin='dense'
                required
                value={shopDetails.contact ? shopDetails.contact.email : ''}
                variant='outlined'
                onChange={(e) => this.handleFieldChange('email', e.target.value)}
              />
            </div>
            <div className={classes.field}>

              <TextField
                className={classes.textField}
                label='Contact Number'
                margin='dense'
                required
                type='number'
                value={shopDetails.contact ? shopDetails.contact.phone : ''}
                variant='outlined'
                onChange={(e) => this.handleFieldChange('phone', e.target.value)}
              />

              <TextField
                className={classes.textField}
                label='Customer Care Number'
                margin='dense'
                required
                type='number'
                value={shopDetails.contact ? shopDetails.contact.customer_care : ''}
                variant='outlined'
                onChange={(e) => this.handleFieldChange('customer_care', e.target.value)}
              />
            </div>
            <div className={classes.field}>

              <Select placeholder='Shop Type *' options={shopTypes.data} valueParam='type' keyParam='id' selectedValue={shopDetails.shop_type.id} handleChange={(event) => this.handleFieldChange('shop_type', event.target.value)} />
              <Select placeholder='Shop Categroy *' multiple options={shopCategories.data} valueParam='category' keyParam='id' selectedValue={(shopDetails.base_categories || []).map(item => item.id)} handleChange={(event) => this.handleFieldChange('base_categories', event.target.value)} />

            </div>
          </form>
        </PortletContent>
        <PortletFooter className={classes.portletFooter}>
          <Button
            color='primary'
            variant='contained'
            onClick={this.handleSaveAction}
            disabled={!isValid || !isEdited || updateShop.status === STATUS.RUNNING}
          >
            Save details
          </Button>
          { !isValid && isEdited ? <Typography className={classes.footerError} variant='body1'>{this.getErrorMessage()}</Typography> : null}
        </PortletFooter>
      </Portlet>
    )
  }
}

ShopDetails.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
}

function mapStateToProps (state) {
  return {
    shopDetails: _.cloneDeep(state.settings.shopDetails.shop),
    updateShop: state.settings.updateShop,
    shopCategories: state.address.categories,
    shopTypes: state.address.types
  }
}

export default compose(
  withStyles(styles)
)(connect(mapStateToProps)(ShopDetails))
