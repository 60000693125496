import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import { Dashboard as CategoriesLayout } from 'layouts'
import { CategoryList } from './components'

// Component styles
const styles = theme => ({
  root: {
    padding: 20,
    display: 'flex'
  }
})

class Categories extends Component {
  state = {
    selectedItems: {
      first: {},
      second: {}
    }
  }

  setSelectedCategory = (level, category) => {
    let { selectedItems } = this.state
    if (level === 1) {
      selectedItems.first = category
      selectedItems.second = {}
    } else {
      selectedItems.second = category
    }
    this.setState({ selectedItems })
  }

  render () {
    const { classes } = this.props
    const { selectedItems } = this.state
    return (
      <CategoriesLayout title='Categories'>
        <div className={classes.root} >
          <CategoryList key='first-level' heading={''} level={1} selected={selectedItems.first.id} setSelectedCategory={this.setSelectedCategory} />
          <CategoryList key='second-level' heading={selectedItems.first.name} parent={selectedItems.first} level={2} selected={selectedItems.second.id} setSelectedCategory={this.setSelectedCategory} />
          <CategoryList key='third-level' heading={selectedItems.second.name} parent={selectedItems.second} level={3} />
        </div>
      </CategoriesLayout>
    )
  }
}

Categories.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(Categories)
